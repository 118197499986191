import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import useZoomToExtent from "../../useZoomToExtent";
import { IBbox } from "../../../interfaces/IBbox";

interface IWaterCell {
    name: string;
    bounds: IBbox["bounds"];
    zoomOffset?: number;
}

export default function WaterwayCell({ name, bounds, zoomOffset }: IWaterCell) {
    const zoomToExtent = useZoomToExtent();
    return (
        <Button
            type="text"
            onClick={(event) => {
                event.stopPropagation();
                zoomToExtent({ bounds, zoomOffset });
            }}
        >
            <SearchOutlined /> {name}
        </Button>
    );
}
