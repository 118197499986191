import classNames from "classnames";
import React from "react";
import { IMapButton } from "../MapButton/MapButton";
import "./ButtonGroup.less";

export interface IButtonGroup {
    children: (React.ReactElement<IMapButton> | undefined | false)[];
    direction?: "vertical" | "horizontal";
    className?: string;
}

export default function ButtonGroup({ children, direction = "vertical", className }: IButtonGroup) {
    return <div className={classNames("button-group", direction, className)}>{children}</div>;
}
