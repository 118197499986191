import { Form, Input, InputRef } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { EditableContext } from "../../../AlertSettingsModale/AlertSettingsForm/AlertSettingsForm";
import { WaterwayQuotationsData } from "../../../../Shared/useWaterways";
import "./AlertSettingsEditableCell.less";

interface EditableCellProps {
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof WaterwayQuotationsData;
    record: WaterwayQuotationsData;
    handleSave: (fieldName: string, record: WaterwayQuotationsData) => void;
    handleError: (fieldName: string) => void;
}

export const AlertSettingsEditableCell: React.FC<EditableCellProps> = ({
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    handleError,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext);
    const fieldName = `${dataIndex}-${record?.key}`;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = async () => {
        setEditing(!editing);
        form?.setFieldsValue({ [fieldName]: record[dataIndex] });
    };
    const save = async () => {
        try {
            const values = await form?.validateFields();
            const newValue = Object.values(values)[0] as string;
            const oldValue = record[dataIndex];
            if (newValue == "") {
                // nothing provided, use null
                handleSave(fieldName, { ...record, ...{ [dataIndex]: null } });
            } else {
                // do change
                newValue !== oldValue && handleSave(fieldName, { ...record, ...{ [dataIndex]: newValue } });
            }
        } catch (err) {
            console.error(err);
            handleError(fieldName);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={fieldName}>
                <Input
                    type={dataIndex === "pkCorrected" ? undefined : "number"}
                    step=".1"
                    ref={inputRef}
                    onPressEnter={toggleEdit}
                    onBlur={toggleEdit}
                    onChange={save}
                />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};
