import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import classNames from "classnames";
import { Planning } from "../../../../Shared/components/Planning/Planning";
import { EPlanningTheme, IPlanning, IPlanningLegend } from "../../../../Shared/components/Planning/types";
import { LockFilled } from "@ant-design/icons";
import "./AnnualStrategySettings.less";

interface IAnnualStrategySettings {
    legend: Array<IPlanningLegend>;
    planning: IPlanning;
    isDraft: boolean;
    onChange: (newValue: IPlanning) => void;
    onSave: () => void;
    onDiscard: () => void;
    onClose: () => void;
}

const timespans = [
    {
        label: "1Y",
        value: 365,
        columnGap: 1,
    },
];
const FIXED_DAY_WIDTH = 60;
const DAY_TO_MS = 1000 * 60 * 60 * 24;
const monthsLabels = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];
const daysOfWeekLabels = ["D", "L", "M", "M", "J", "V", "S"];

export const AnnualStrategySettings = ({
    planning,
    legend,
    isDraft,
    onChange,
    onSave,
    onDiscard,
    onClose,
}: IAnnualStrategySettings) => {
    const [planningScrollLeft, setPlanningScrollLeft] = useState(0);

    useEffect(() => {
        const initialScrollY = window.scrollY;
        window.scrollTo(0, 0);
        document.body.style.overflow = "hidden";

        return () => {
            window.scrollTo(0, initialScrollY);
            document.body.style.overflow = "unset";
        };
    }, []);

    const header = useMemo(() => {
        const today = Math.floor(new Date().getTime() / DAY_TO_MS) * DAY_TO_MS;
        const average = Math.floor(timespans[0].value / 2);
        const allDays: Array<Date> = [];
        // Fill the array with a date object for each day
        // that will appear in the planning
        for (let i = -average; i < average + 1; i++) {
            allDays.push(new Date(today + DAY_TO_MS * i));
        }
        // The planning header displays the days grouped by months
        const months = allDays.reduce((acc: Array<{ month: string; length: number }>, currentDate) => {
            const prev = acc.pop();
            const currentMonth = monthsLabels[currentDate.getMonth()];
            if (!prev) {
                return [{ month: currentMonth, length: 1 }];
            } else if (currentMonth !== prev.month) {
                return [...acc, prev, { month: currentMonth, length: 1 }];
            } else {
                prev.length++;
            }

            return [...acc, prev];
        }, []);
        const days = allDays.map((date) => {
            const dayOfWeek = daysOfWeekLabels[date.getDay()];
            const dayNumber = date.getDate();
            return {
                weekend: ["S", "D"].includes(dayOfWeek),
                dayOfWeek: `${dayOfWeek} ${dayNumber}`,
            };
        });

        return (
            <div className="annual-strategy-settings-header">
                <div />
                <div>
                    <LockFilled />
                </div>
                <div>
                    <div className="header-months-wrapper">
                        {months.map(({ length, month }, index) => (
                            <div className="header-month" style={{ width: FIXED_DAY_WIDTH * length }} key={index}>
                                {month}
                            </div>
                        ))}
                    </div>
                    <div className="header-dayofweek-wrapper">
                        {days.map(({ weekend, dayOfWeek }, index) => (
                            <div
                                className={classNames("header-dayofweek", { weekend })}
                                style={{ width: FIXED_DAY_WIDTH }}
                                key={index}
                            >
                                {dayOfWeek}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }, []);

    return (
        <div className="annual-strategy-settings-wrapper">
            <div
                className="planning-scroller"
                onScroll={(event: any) => {
                    setPlanningScrollLeft(event.target.scrollLeft);
                }}
            >
                <Planning
                    legend={legend}
                    timespans={timespans}
                    value={planning}
                    showCursor={false}
                    onChange={onChange}
                    theme={EPlanningTheme.LIGHT}
                    blockWidth={FIXED_DAY_WIDTH}
                    foldableSections
                    header={header}
                    scrollLeft={planningScrollLeft}
                />
            </div>

            <div className="annual-strategy-settings-buttons">
                <Button onClick={onClose}>Fermer</Button>
                <Button onClick={onDiscard} disabled={!isDraft}>
                    Effacer le brouillon
                </Button>
                <Button type="primary" onClick={onSave} disabled={!isDraft}>
                    Sauvegarder et publier
                </Button>
            </div>
        </div>
    );
};
