import { Color } from "../../Shared/components/ColorStatus/ColorStatus";
import { WaterwayType } from "../../Shared/useWaterways";

export enum AlertSource {
    CM = "CM", // Cote Modèle
    CC = "CC", // Cote Corrigée
}

export enum QuotationSource {
    MODEL = "quotation_model",
    CORRECTED = "quotation_model_corrected",
    D1 = "quotation_1d",
    D30 = "quotation_30d",
    D100 = "quotation_100d",
}
export interface IAlertDTO {
    id: number;
    layer: WaterwayType;
    feature_name: string;
    date: string;
    source: AlertSource;
    value_quotation_source: number;
    value_quotation_target: number;
    field_quotation_source: QuotationSource;
    status: Color;
}

export interface IDredgingAlert {
    key: number;
    name: string;
    quotation: number;
    timestamp: string;
    status: Color;
}

export interface IAlertLog {
    d1: IDredgingAlert[];
    d30: IDredgingAlert[];
    d100: IDredgingAlert[];
    month: IDredgingAlert[];
}
