import React from "react";

export const MarkerIcon = () => {
    return (
        <svg width="36" height="50" viewBox="0 0 36 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.9936 1.5L17.9924 1.5C13.6316 1.50364 9.44937 3.2328 6.35926 6.3098C3.26914 9.3868 1.52221 13.5616 1.50002 17.9224H1.5V17.93C1.5 24.3593 5.08191 29.6759 7.49147 33.2524L7.53415 33.3157L7.5345 33.3162L7.97963 33.9776L7.98446 33.9847L7.98938 33.9919C10.4496 37.5575 13.0888 40.9962 15.8969 44.2948L15.8983 44.2965L16.8657 45.4293L18.0064 46.7651L19.1471 45.4293L20.1144 44.2965L20.116 44.2947C22.9236 40.9959 25.5628 37.5573 28.0233 33.992L28.032 33.9794L28.0404 33.9666L28.483 33.2964C28.5106 33.2553 28.5383 33.2139 28.5662 33.1723C30.9603 29.6008 34.5 24.3205 34.5 17.93L34.5 17.9224C34.4778 13.5592 32.7289 9.38226 29.6358 6.30486C26.5426 3.22747 22.3569 1.49994 17.9936 1.5ZM17.9936 23.1377C16.6389 23.1377 15.3146 22.736 14.1882 21.9833C13.0618 21.2307 12.1839 20.1609 11.6654 18.9093C11.147 17.6577 11.0114 16.2805 11.2757 14.9518C11.5399 13.6231 12.1923 12.4026 13.1502 11.4447C14.1082 10.4867 15.3287 9.83439 16.6573 9.57009C17.986 9.3058 19.3633 9.44144 20.6149 9.95988C21.8665 10.4783 22.9362 11.3562 23.6889 12.4826C24.4415 13.6091 24.8432 14.9334 24.8432 16.2881C24.8432 18.1047 24.1216 19.8469 22.837 21.1315C21.5525 22.416 19.8103 23.1377 17.9936 23.1377Z"
                fill="#1890FF"
                stroke="white"
                strokeWidth="3"
            />
            <path
                d="M17.9936 1.5L17.9924 1.5C13.6316 1.50364 9.44937 3.2328 6.35926 6.3098C3.26914 9.3868 1.52221 13.5616 1.50002 17.9224H1.5V17.93C1.5 24.3593 5.08191 29.6759 7.49147 33.2524L7.53415 33.3157L7.5345 33.3162L7.97963 33.9776L7.98446 33.9847L7.98938 33.9919C10.4496 37.5575 13.0888 40.9962 15.8969 44.2948L15.8983 44.2965L16.8657 45.4293L18.0064 46.7651L19.1471 45.4293L20.1144 44.2965L20.116 44.2947C22.9236 40.9959 25.5628 37.5573 28.0233 33.992L28.032 33.9794L28.0404 33.9666L28.483 33.2964C28.5106 33.2553 28.5383 33.2139 28.5662 33.1723C30.9603 29.6008 34.5 24.3205 34.5 17.93L34.5 17.9224C34.4778 13.5592 32.7289 9.38226 29.6358 6.30486C26.5426 3.22747 22.3569 1.49994 17.9936 1.5ZM17.9936 23.1377C16.6389 23.1377 15.3146 22.736 14.1882 21.9833C13.0618 21.2307 12.1839 20.1609 11.6654 18.9093C11.147 17.6577 11.0114 16.2805 11.2757 14.9518C11.5399 13.6231 12.1923 12.4026 13.1502 11.4447C14.1082 10.4867 15.3287 9.83439 16.6573 9.57009C17.986 9.3058 19.3633 9.44144 20.6149 9.95988C21.8665 10.4783 22.9362 11.3562 23.6889 12.4826C24.4415 13.6091 24.8432 14.9334 24.8432 16.2881C24.8432 18.1047 24.1216 19.8469 22.837 21.1315C21.5525 22.416 19.8103 23.1377 17.9936 23.1377Z"
                fill="#1890FF"
                stroke="white"
                strokeWidth="3"
            />
            <path
                d="M17.9937 22.5352C21.4441 22.5352 24.2412 19.738 24.2412 16.2876C24.2412 12.8372 21.4441 10.04 17.9937 10.04C14.5432 10.04 11.7461 12.8372 11.7461 16.2876C11.7461 19.738 14.5432 22.5352 17.9937 22.5352Z"
                fill="#242533"
                stroke="white"
                strokeWidth="3"
            />
        </svg>
    );
};
