import { Moment } from "moment";
import React from "react";
import { EProgrammedState } from "../../../Dredging/AnnualStrategy/types";

export interface IPlanning {
    sections: IPlanningData[];
    prefectoralDecrees: IPrefectoralDecree[];
}

export interface IPlanningLegend {
    color: string;
    borderColor: string;
    label: string;
    value: string;
}

export interface IPlanningActivity {
    color: string;
    label: string;
    value: string;
}

export enum EPlanningLineType {
    DEFAULT = "default",
    PREFECTURAL_DECREE = "prefectural_decree",
    COMMERCIAL_COMMITMENTS = "commercial_commitments",
    ALERT = "alert",
}

export interface IPlanningTimeRange {
    id: string;
    activity: string;
    from: string;
    to: string;
    text?: string;
    tooltip?: string | React.ReactNode;
}

export interface IPrefectoralDecreeTimeRange extends IPlanningTimeRange {
    prefectoralDecreeId: string;
    code: string;
    fairwayType: EFairwayType;
    additionalInformation?: {
        debitValue: {
            operator: EPrefectoralDecreeAdditionalInformationOperator | null;
            value: number | null;
            fairwayType: EFairwayType | null;
        };
        o2dissousValue: {
            operator: EPrefectoralDecreeAdditionalInformationOperator | null;
            value: number | null;
            fairwayType: EFairwayType | null;
        };
    };
}

export interface IPlanningLine {
    lineLabel: string;
    linePlanning: Array<IPlanningTimeRange | IPrefectoralDecreeTimeRange>;
    type: EPlanningLineType;
    editable: boolean;
    locked?: boolean;
}

export interface IPlanningData {
    sectionLabel: string;
    sectionData: Array<IPlanningLine>;
}

export enum EPlanningTheme {
    DEFAULT = "default",
    LIGHT = "light",
}

export enum EFairwayType {
    AVAL = "Aval",
    INTERMEDIAIRE = "Intermédiaire",
    AMONT = "Amont",
}

export enum EPrefectoralDecreeAdditionalInformationOperator {
    GREATER_THAN = ">",
    GREATER_OR_EQUAL_THAN = "≧",
    EQUAL = "=",
    LESS_THAN = "<",
    LESS_OR_EQUAL_THAN = "≤",
}

export interface IPrefectoralDecree {
    id: string;
    code: string;
    applicationDate: Moment | string;
    endDate: Moment | string;
    timeranges: Array<{
        from: string | null;
        to: string | null;
        fairwayType: EFairwayType | null;
        activity: EProgrammedState | null;
    }>;
    additionalInformation: {
        debitValue: {
            operator: EPrefectoralDecreeAdditionalInformationOperator | null;
            value: number | null;
            fairwayType: EFairwayType | null;
        };
        o2dissousValue: {
            operator: EPrefectoralDecreeAdditionalInformationOperator | null;
            value: number | null;
            fairwayType: EFairwayType | null;
        };
    };
}
