import { EProgrammedState } from "./types";

export const planningLegend = [
    {
        color: "#BAE7FF",
        borderColor: "#69C0FF",
        label: "Programmé",
        value: EProgrammedState.PROGRAMMED,
    },
    {
        color: "#EFDBFF",
        borderColor: "#D3ADF7",
        label: "Indisponible",
        value: EProgrammedState.UNAVAILABLE,
    },
    {
        color: "#FFA39E",
        borderColor: "#FF7875",
        label: "Interdit",
        value: EProgrammedState.FORBIDDEN,
    },
    {
        color: "#fcc58a",
        borderColor: "#FA8C16",
        label: "A éviter",
        value: EProgrammedState.TO_AVOID,
    },
    {
        color: "#D9F7BE",
        borderColor: "#B7EB8F",
        label: "Engagements commerciaux",
        value: EProgrammedState.COMMERCIAL_COMMITMENTS,
    },
];
