import { useDispatch, useSelector } from "react-redux";
import { zoomToExtent } from "qwc2/actions/map";
import { useCallback } from "react";
import { IBbox } from "../interfaces/IBbox";

export default function useZoomToExtent() {
    const dispatch = useDispatch();
    const { mapCrs } = useSelector((state: any) => ({
        mapCrs: state.map?.projection ?? "EPSG:2154",
    }));

    const zoomTo = useCallback(
        ({ bounds, crs, zoomOffset }: IBbox & { zoomOffset?: number }) => {
            dispatch(zoomToExtent(bounds, crs ?? mapCrs, zoomOffset));
        },
        [dispatch, mapCrs],
    );
    return zoomTo;
}
