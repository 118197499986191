import { getBaseHeaders } from "./utils/fetch";
import { queryBuilder } from "./utils/querybuilder";

export interface WMSApiServiceParams {
    baseUrl: string;
    request: {
        name: WMSRequest;
        options: any;
    };
}

export enum WMSRequest {
    GET_LEGEND_GRAPHIC = "GetLegendGraphics",
    GET_CAPABILITIES = "GetCapabilities",
    GET_MAP = "GetMap",
    GET_FEATURE_INFO = "GetFeatureInfo",
    DESCRIBE_LAYER = "DescribeLayer",
}

export default async function fetchWMS({ baseUrl, request }: WMSApiServiceParams) {
    const { name, options } = request;
    const query = queryBuilder(baseUrl, {
        SERVICE: "WMS",
        REQUEST: name,
        VERSION: "1.3.0",
        ...options,
    });
    const response = await fetch(query, { headers: getBaseHeaders() });

    return response.json();
}
