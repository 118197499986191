import React, { useState } from "react";
import { Alert, Tabs } from "antd";
import useCurrentModule from "../../Shared/useCurrentModule";
import { WaterwayType } from "../../Shared/useWaterways";
import { useWMSLayer } from "../../Shared/useWMSLayer";
import { LayerTreeWrapper } from "../../Shared/components/LayerTree/LayerTreeWrapper";
import WaterwayList from "../../Shared/components/WaterwayList/WaterwayList";
import classNames from "classnames";
import useOLResize from "../../Shared/useOLResize";
import { CloseOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { INavigabilityShipSelectForm, NavigabilityShipSelect } from "../NavigabilityShipSelect/NavigabilityShipSelect";
import useFairwayNavigabilityColumns from "../useFairwayNavigabilityColumns";
import useTerminalNavigabilityColumns from "../useTerminalNavigabilityColumns";
import { useEstimatedPassingTime } from "../useEstimatedPassingTime";
import { useNavigabilityIndicators } from "../useNavigabilityIndicators";
import "./NavigabilityLeftBar.less";

enum NavigabilityTabKeys {
    LAYERS = "layers",
    FAIRWAYS = "fairways",
    TERMINALS = "terminals",
}

export const NavigabilityLeftBar = () => {
    const WMSLayer = useWMSLayer();
    const [isLeftPanelOpen, setisLeftPanelOpen] = useState(true);
    const [userSelection, setUserSelection] = useState<INavigabilityShipSelectForm | null>(null);
    const [currentModule] = useCurrentModule();
    const resizeOLMap = useOLResize(200);
    const estimatedPassingTimes = useEstimatedPassingTime(userSelection);
    const {
        fairwaysWithIndicators,
        terminalsWithIndicators,
        isLoading: isLoadingNavigabilityIndicators,
        hasError,
    } = useNavigabilityIndicators(estimatedPassingTimes);
    const hasItinary = fairwaysWithIndicators.length > 0 || terminalsWithIndicators.length > 0;

    const fairwayColumns = useFairwayNavigabilityColumns();
    const terminalColumns = useTerminalNavigabilityColumns();

    return (
        <>
            <button
                className={classNames("left-bar-open-button", { hidden: isLeftPanelOpen })}
                onClick={() => {
                    setisLeftPanelOpen(true);
                    resizeOLMap();
                }}
            >
                <DoubleRightOutlined />
            </button>
            <div className={classNames("navigability-left-bar", { open: isLeftPanelOpen })}>
                <button
                    className="close-button"
                    onClick={() => {
                        setisLeftPanelOpen(false);
                        resizeOLMap();
                    }}
                >
                    <CloseOutlined />
                </button>
                <div className="description">
                    <img src={currentModule?.img} alt="drawer header" />
                    <h1>{currentModule?.description}</h1>
                </div>
                <NavigabilityShipSelect onChange={setUserSelection} />
                {hasError && (
                    <Alert
                        style={{ margin: "0 16px" }}
                        type="error"
                        message="Une erreur est survenue lors du calcul des indicateurs."
                        closable
                    />
                )}
                {hasItinary && (
                    <Alert
                        style={{ margin: "0 16px" }}
                        type="info"
                        message="A propos du calcul de l'itinéraire"
                        description="L'itinéraire calculé peut présenter des différences avec votre système. Le calcul de l'itinéraire est réalisé avec une vitesse et un coefficient de bloc constants tout au long du parcours."
                        showIcon
                        closable
                    />
                )}
                <Tabs
                    items={[
                        {
                            label: "Passes",
                            children: (
                                <WaterwayList
                                    waterways={fairwaysWithIndicators}
                                    columns={fairwayColumns}
                                    isLoading={isLoadingNavigabilityIndicators}
                                    className={WaterwayType.FAIRWAY}
                                />
                            ),
                            key: NavigabilityTabKeys.FAIRWAYS,
                        },
                        {
                            label: "Postes à quai",
                            children: (
                                <WaterwayList
                                    waterways={terminalsWithIndicators}
                                    columns={terminalColumns}
                                    isLoading={isLoadingNavigabilityIndicators}
                                    className={WaterwayType.TERMINAL}
                                />
                            ),
                            key: NavigabilityTabKeys.TERMINALS,
                        },
                        {
                            label: "Couches",
                            children: <LayerTreeWrapper WMSLayer={WMSLayer} />,
                            key: NavigabilityTabKeys.LAYERS,
                        },
                    ]}
                    defaultActiveKey={NavigabilityTabKeys.FAIRWAYS}
                />
            </div>
        </>
    );
};
