import React from "react";

export const CoordinateIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.21192 0V0.75H4.86744L3.13872 2.54856V3.28128H6.07464V2.53128H4.34568L6.07464 0.73272V0H3.21192ZM9.64776 0L7.24824 3.67176H9.04824V15.7279L7.77792 16.7863L7.66272 15.4958L6.74064 16.2638L6.85584 17.5548L5.14512 18.9802L5.02968 17.6897L4.1076 18.4579L4.22352 19.7484L2.43648 21.1884L1.28448 19.8055L0 24L4.35696 23.4929L3.20448 22.11L10.079 16.4244H20.3282V18.2244L24 15.8251L20.3282 13.4249V15.2249H17.1026V13.5449H15.9026V15.2249H13.6754V13.5449H12.4754V15.2249H10.248V12.9977H11.928V11.7977H10.248V9.57024H11.928V8.37024H10.248V6.14304H11.928V4.94304H10.248V3.672H12.048L9.648 0H9.64776ZM0 13.4198L1.46208 16.8271L1.43256 16.9176C1.38768 17.064 1.32432 17.1662 1.24224 17.2229C1.16208 17.2795 1.0332 17.3076 0.85536 17.3076H0.52728V17.9964H1.134C1.43664 17.9964 1.67976 17.923 1.86336 17.7763C2.04888 17.6318 2.21136 17.3767 2.34984 17.0114L3.72936 13.4198H2.68056L1.9308 15.6463L1.04904 13.4198H0ZM20.37 18.7987L21.5537 20.4014L20.3112 22.08H21.4217L22.151 21.0432L22.8895 22.08H24L22.7578 20.3952L23.9414 18.7987H22.831L22.1513 19.7714L21.4805 18.7987H20.37Z"
                fill="black"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </svg>
    );
};
