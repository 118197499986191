import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import CoordinatesUtils from "qwc2/utils/CoordinatesUtils";

export const InfoCoordinates = () => {
    const { mapCrs, mouseCrs, mouseCoordinates } = useSelector((state: any) => ({
        mapCrs: state.map?.projection ?? "EPSG:3857",
        mouseCrs: state.mousePosition?.crs,
        mouseCoordinates: state.mousePosition?.position?.coordinate ?? [],
    }));

    const [mouseX, mouseY] = mouseCoordinates ?? [];

    const mouseCoordinatesOnMap = useMemo(
        () =>
            (mouseX != null && mouseY != null
                ? CoordinatesUtils.reproject([mouseX, mouseY], mapCrs, mouseCrs ?? mapCrs)
                : null) ?? [],
        [mouseX, mouseY, mouseCrs, mapCrs],
    );

    const [x, y] = mouseCoordinatesOnMap;
    const coordinatesDisplayValue = !isNaN(x) && !isNaN(y) ? `${Math.round(x)} ${Math.round(y)}` : "";

    return (
        <label>
            Coordonnées<span className="hide-mobile"> (X Y)</span>:&nbsp;
            <input readOnly type="text" value={coordinatesDisplayValue} />
        </label>
    );
};
