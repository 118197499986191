import { Input, Tooltip } from "antd";
import projectNameGenerator from "project-name-generator";
import React, { useEffect, useState } from "react";
import dashify from "../../../utils/dashify";

const validateProjectNameRegex = new RegExp("^([a-zA-Z0-9]([a-zA-Z0-90_\\-\\.]+)?)$");

export default function GitLabProjectNameInput({
    value,
    onChange,
}: {
    value?: string;
    onChange?: (value: string) => void;
}) {
    const [suffix] = useState("-" + projectNameGenerator().dashed);
    const [name, setName] = useState("");
    const [error, setError] = useState(false);
    useEffect(() => {
        if (value) {
            const suffixRegExp = new RegExp(suffix + "$", "i");
            setName(value.replace(suffixRegExp, ""));
        }
    }, [suffix, value]);
    return (
        <Tooltip
            open={error}
            title="Le nom d'un projet ne peut contenir que des lettres, chiffres ou caractères tels que . - ou _ et ne doit pas commencer par un caractère spécial"
        >
            <Input
                onChange={(event) => {
                    const projectName = dashify(event.target.value ?? "");
                    if (projectName && !validateProjectNameRegex.test(projectName)) {
                        setError(true);
                    } else {
                        setError(false);
                        setName(projectName);
                        onChange?.(projectName + suffix);
                    }
                }}
                value={name}
                addonAfter={suffix}
                status={error ? "error" : undefined}
                placeholder="nom-de-l-etude"
            />
        </Tooltip>
    );
}
