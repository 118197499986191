import { useEffect, useState } from "react";
import { EVigieSipEndpoints, fetchVigieSip } from "../Shared/utils/fetch";
import { getDateFromVigieSipFormat, DAY_TO_MS } from "../Shared/utils/date";

export interface IMouvementsAnnouncementDTO {
    ANNONCE: number;
    IMO: string;
    INFOS_NAVIRE: {
        LARGEUR: number;
        LONGUEUR: number;
        MMSI: string;
        NAVIRE: string;
        PAVILLON: string;
        PROPULSEUR_AV: boolean;
        TE_ETE: number;
        TYPE_NAVIRE: string;
    };
    MOUVEMENTS: [
        {
            AGENT: string;
            BAQ: string;
            BPM: boolean;
            COMMENTAIRE_CAPIT: string;
            COMMENTAIRE_PILOT: string;
            DATEMAJ: string;
            DESTINATION: string;
            DESTINATION_NAVIRE: string;
            DIFFUSION_PUBLIQUE: boolean;
            ETA: string;
            ETAT: string;
            ETD: string;
            IDMVT: string;
            INTERNATIONAL: boolean;
            MDP: boolean;
            MOUILLAGE: boolean;
            MVTREEL: boolean;
            NUMERO_RANG: number;
            ORIGINE: string;
            PAB: string;
            PASSE: string;
            PILOTAGE: boolean;
            PILOTEABORD: string;
            PROVENANCE: string;
            REMORQUEUREXT: number;
            REMORQUEURMN: number;
            REMORQUEURPORT: number;
            SECTEUR_DESTINATION: string;
            SECTEUR_ORIGINE: string;
            TE: number;
            TYPE: string;
            etaEtd: string;
        },
    ];
}

// Filter ship mouvements in the past
const filterShipMouvements = (shipMouvements: IMouvementsAnnouncementDTO[]): IMouvementsAnnouncementDTO[] => {
    const todayTs = Math.floor(Date.now() / DAY_TO_MS) * DAY_TO_MS;

    return shipMouvements
        .map(({ MOUVEMENTS, ...annoucement }) => {
            return {
                ...annoucement,
                MOUVEMENTS: MOUVEMENTS.filter(({ ETA, ETD, TYPE }) => {
                    const eta = ETA && getDateFromVigieSipFormat(ETA);
                    const etd = ETD && getDateFromVigieSipFormat(ETD);

                    const etaTs = new Date(eta).getTime();
                    const etdTs = new Date(etd).getTime();

                    if (TYPE === "DPQ") {
                        return etaTs >= todayTs;
                    } else if (TYPE === "DS") {
                        return etdTs >= todayTs;
                    } else {
                        return etaTs >= todayTs || etdTs >= todayTs;
                    }
                }),
            } as IMouvementsAnnouncementDTO;
        })
        .filter(({ MOUVEMENTS }) => MOUVEMENTS.length > 0);
};

export const useShipMouvements = () => {
    const [shipMouvements, setShipMouvements] = useState<IMouvementsAnnouncementDTO[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            try {
                const shipMouvements: IMouvementsAnnouncementDTO[] = await fetchVigieSip(
                    EVigieSipEndpoints.EXPORT_DEMANDES_FACTURATION,
                );
                const filteredShipMouvements = filterShipMouvements(shipMouvements);
                setShipMouvements(filteredShipMouvements);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        init();
    }, []);

    return {
        shipMouvements,
        isLoading,
    };
};
