export enum EShipDirection {
    ASC = "asc",
    DESC = "desc",
}

type TFairwayEdge = {
    fairway: string;
    length: number;
};

class TerminalNode {
    public edges = new Map<TerminalNode, TFairwayEdge[]>();
    constructor(public terminals: number[], public fairway: string) {}

    public addEdge(node: TerminalNode, edge: TFairwayEdge[]) {
        if (!this.edges.has(node)) {
            this.edges.set(node, edge);
        }
    }
}

export const terminalNodes = {
    bacalanEvitageTerminals: new TerminalNode(
        [120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138],
        "NO_NAME_1",
    ),
    bacalanTerminals: new TerminalNode([335, 336, 337, 338], "BACALAN PASSE"),
    bacalanBassinTerminals: new TerminalNode(
        [201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219],
        "NO_NAME_2",
    ),
    bassensAmontTerminals: new TerminalNode([401, 402, 413, 414, 415, 416, 417, 418, 429], "BASSENS AMONT"),
    bassensAvalTerminals: new TerminalNode([430, 431, 432, 433, 434, 435, 436, 449], "BASSENS AVAL"),
    grattequinaTerminals: new TerminalNode([261], "GRATTEQUINA"),
    belleriveTerminals: new TerminalNode([501, 511, 512], "BELLERIVE"),
    becAmontTerminals: new TerminalNode([515], "BEC AMONT"),
    ambesTerminals: new TerminalNode([519, 518, 517], "PETROLIERS"),
    plassacTerminals: new TerminalNode([600, 601, 602, 610], "PLASSAC ROQUE DE THAU"),
    pauillacTerminals: new TerminalNode([700, 710], "TROMPELOUP PASSE"),
    leVerdonTerminals: new TerminalNode([805, 806], "CHAMBRETTE ACCES 805/806"),
    endNode: new TerminalNode([1000], "NO_NAME_3"),
};

class TerminalGraph {
    public nodes: Set<TerminalNode> = new Set();

    public addEdge(source: TerminalNode, destination: TerminalNode, edge: TFairwayEdge[]) {
        if (!this.nodes.has(source)) {
            this.nodes.add(source);
        }
        if (!this.nodes.has(destination)) {
            this.nodes.add(destination);
        }
        source.addEdge(destination, edge);
        destination.addEdge(source, edge);

        return this;
    }

    // A partir de l'exploration du graphe, formatte le parcours pour éviter les doublons
    // (une même passe peut être parcourue en plusieurs sections)
    public getFairwayItinery(from: number, to: number, direction: EShipDirection): TFairwayEdge[] | null {
        const arrayNodes = Array.from(this.nodes);
        const fromNode = arrayNodes.find(({ terminals }) => terminals.includes(from));
        const toNode = arrayNodes.find(({ terminals }) => terminals.includes(to));
        if (!fromNode || !toNode) {
            return null;
        } else if (fromNode && toNode && fromNode === toNode) {
            return [
                {
                    fairway: fromNode.fairway,
                    length: 1,
                },
            ];
        }
        const fairways = this.dfs(fromNode, toNode, direction, [], new Set());
        const itinery = fairways?.reduce((acc, cur) => {
            if (acc.has(cur.fairway)) {
                const length = acc.get(cur.fairway) as number;
                acc.set(cur.fairway, length + cur.length);
            }
            acc.set(cur.fairway, cur.length);

            return acc;
        }, new Map<string | null, number>());

        return itinery
            ? (Array.from(itinery.entries()).map((entry) => {
                  const [fairway, length] = entry;
                  return {
                      fairway,
                      length,
                  };
              }) as TFairwayEdge[])
            : null;
    }

    // Permet d'explorer le graphe et renvoie les passes à parcourir pour aller d'un point
    // A vers un point B
    private dfs(
        from: TerminalNode,
        to: TerminalNode,
        direction: EShipDirection,
        path: TFairwayEdge[],
        visitedNodes: Set<TerminalNode>,
    ): TFairwayEdge[] | null {
        if (from === to) {
            return path;
        }
        visitedNodes.add(from);
        const fromEdges = Array.from(from.edges.entries());
        for (let i = 0; i < fromEdges.length; i++) {
            const [toNode, fairways] = fromEdges[i];
            if (visitedNodes.has(toNode)) {
                continue;
            }
            const itineryPart = [...fairways];
            if (direction === EShipDirection.ASC) {
                itineryPart.reverse();
            }
            const result = this.dfs(toNode, to, direction, [...path, ...itineryPart], visitedNodes);
            if (result != null) {
                return result;
            }
        }

        return null;
    }
}

const initGraph = () => {
    const {
        bacalanEvitageTerminals,
        bacalanTerminals,
        bacalanBassinTerminals,
        bassensAmontTerminals,
        bassensAvalTerminals,
        grattequinaTerminals,
        belleriveTerminals,
        becAmontTerminals,
        ambesTerminals,
        plassacTerminals,
        pauillacTerminals,
        leVerdonTerminals,
        endNode,
    } = terminalNodes;

    return new TerminalGraph()
        .addEdge(bacalanEvitageTerminals, bacalanTerminals, [
            { fairway: "NO_NAME_1", length: 1.7 },
            { fairway: "BACALAN EVITAGE", length: 0.1 },
            { fairway: "BACALAN PASSE", length: 1.2 },
        ])
        .addEdge(bacalanTerminals, bacalanBassinTerminals, [{ fairway: "NO_NAME_2", length: 1 }])
        .addEdge(bacalanTerminals, bassensAmontTerminals, [
            { fairway: "BACALAN PASSE", length: 0.5 },
            { fairway: "NO_NAME_3", length: 2 },
            { fairway: "BASSENS AMONT", length: 1.5 },
        ])
        .addEdge(bassensAmontTerminals, bassensAvalTerminals, [
            { fairway: "BASSENS AMONT", length: 1.5 },
            { fairway: "BASSENS AVAL", length: 0.5 },
        ])
        .addEdge(bassensAvalTerminals, grattequinaTerminals, [
            { fairway: "BASSENS AVAL", length: 2.5 },
            { fairway: "GRATTEQUINA", length: 2 },
        ])
        .addEdge(grattequinaTerminals, belleriveTerminals, [
            { fairway: "GRATTEQUINA", length: 2 },
            { fairway: "CAILLOU", length: 3 },
            { fairway: "PACHAN", length: 2 },
            { fairway: "BELLERIVE", length: 2 },
        ])
        .addEdge(belleriveTerminals, becAmontTerminals, [
            { fairway: "BELLERIVE", length: 2 },
            { fairway: "BEC AMONT", length: 0.1 },
        ])
        .addEdge(becAmontTerminals, pauillacTerminals, [
            { fairway: "BEC AMONT", length: 3 },
            { fairway: "BEC AVAL", length: 3 },
            { fairway: "ILE DU NORD", length: 1.7 },
            { fairway: "ILE VERTE", length: 3.4 },
            { fairway: "ILE VERTE AVAL", length: 1.9 },
            { fairway: "CUSSAC", length: 3.5 },
            { fairway: "BEYCHEVELLE", length: 3 },
            { fairway: "SAINT JULIEN", length: 2.5 },
            { fairway: "PAUILLAC", length: 5 },
            { fairway: "TROMPELOUP PASSE", length: 0.2 },
        ])
        .addEdge(becAmontTerminals, ambesTerminals, [
            { fairway: "BEC AMONT", length: 3 },
            { fairway: "BEC AVAL", length: 3 },
            { fairway: "PETROLIERS", length: 2 },
        ])
        .addEdge(becAmontTerminals, plassacTerminals, [
            { fairway: "BEC AMONT", length: 3 },
            { fairway: "BEC AVAL", length: 3 },
            { fairway: "ILE DU NORD", length: 1.7 },
            { fairway: "ILE VERTE", length: 0.2 },
            { fairway: "PLASSAC ROQUE DE THAU", length: 6.5 },
        ])
        .addEdge(ambesTerminals, pauillacTerminals, [
            { fairway: "PETROLIERS", length: 2 },
            { fairway: "BEC AVAL", length: 0.3 },
            { fairway: "ILE DU NORD", length: 1.7 },
            { fairway: "ILE VERTE", length: 3.4 },
            { fairway: "ILE VERTE AVAL", length: 1.9 },
            { fairway: "CUSSAC", length: 3.5 },
            { fairway: "BEYCHEVELLE", length: 3 },
            { fairway: "SAINT JULIEN", length: 2.5 },
            { fairway: "PAUILLAC", length: 5 },
            { fairway: "TROMPELOUP PASSE", length: 0.2 },
        ])
        .addEdge(ambesTerminals, plassacTerminals, [
            { fairway: "PETROLIERS", length: 2 },
            { fairway: "BEC AVAL", length: 0.3 },
            { fairway: "ILE DU NORD", length: 1.7 },
            { fairway: "ILE VERTE", length: 0.2 },
            { fairway: "PLASSAC ROQUE DE THAU", length: 6.5 },
        ])
        .addEdge(plassacTerminals, pauillacTerminals, [
            { fairway: "PLASSAC ROQUE DE THAU", length: 6.5 },
            { fairway: "ILE VERTE", length: 3.4 },
            { fairway: "ILE VERTE AVAL", length: 1.9 },
            { fairway: "CUSSAC", length: 3.5 },
            { fairway: "BEYCHEVELLE", length: 3 },
            { fairway: "SAINT JULIEN", length: 2.5 },
            { fairway: "PAUILLAC", length: 5 },
            { fairway: "TROMPELOUP PASSE", length: 0.2 },
        ])
        .addEdge(pauillacTerminals, leVerdonTerminals, [
            { fairway: "TROMPELOUP PASSE", length: 4 },
            { fairway: "SAINT ESTEPHE", length: 4 },
            { fairway: "LA MARECHALE", length: 4 },
            { fairway: "LAMENA", length: 5 },
            { fairway: "BY", length: 4.4 },
            { fairway: "GOULEE", length: 5.1 },
            { fairway: "RICHARD", length: 5.1 },
            { fairway: "NO_NAME_4", length: 11.5 },
            { fairway: "CHAMBRETTE ACCES 805/806", length: 0.1 },
        ])
        .addEdge(leVerdonTerminals, endNode, [
            { fairway: "CHAMBRETTE ACCES 805/806", length: 2 },
            { fairway: "CHAMBRETTE ACCES AMONT", length: 0.5 },
        ]);
};

let terminalsGraph: TerminalGraph;

export const getTerminalsGraph = () => {
    if (!terminalsGraph) {
        terminalsGraph = initGraph();
    }

    return terminalsGraph;
};
