import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeZoomLevel } from "qwc2/actions/map";
import LocaleUtils from "qwc2/utils/LocaleUtils";

export const InfoScaleSelector = () => {
    const { currentZoom, scales } = useSelector((state: any) => ({
        currentZoom: state.map.zoom,
        scales: state.map.scales,
    }));
    const dispatch = useDispatch();

    function updateZoom(event: any) {
        dispatch(changeZoomLevel(event.target.value));
    }
    return (
        <label>
            Echelle:&nbsp;
            <select onChange={updateZoom} value={currentZoom}>
                {scales.map((item: any, index: any) => (
                    <option key={index} value={index}>
                        1 : {LocaleUtils.toLocaleFixed(item, 0)}
                    </option>
                ))}
            </select>
        </label>
    );
};
