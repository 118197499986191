import React, { useState } from "react";
import { Alert, Button, Form, Table } from "antd";
import { WaterwayQuotationsData } from "../../Shared/useWaterways";
import { INavigabilityFeedbacks, useNavigabilityFeedbacks } from "../useNavigabilityFeedbacks";
import { NavigabilityFeedbacksCell } from "./NavigabilityFeedbacksCell/NavigabilityFeedbacksCell";
import { arrayToCsv } from "../../Shared/utils/csv";
import { downloadBlob } from "../../Shared/utils/download";
import { DownloadOutlined } from "@ant-design/icons";
import { ITerminal } from "../useTerminals";
import "./NavigabilityFeedbacks.less";

interface INavigabilityFeedbacksProps {
    fairways: WaterwayQuotationsData[];
    terminals: ITerminal[];
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
type CustomColumnType = ColumnTypes[number] & { dataIndex?: string };

const emptyComment: INavigabilityFeedbacks = {
    timestamp: "",
    ship_name: "",
};

export const NavigabilityFeedbacks = ({ fairways, terminals }: INavigabilityFeedbacksProps) => {
    const [form] = Form.useForm();
    const [newComment, setNewComment] = useState<INavigabilityFeedbacks>(emptyComment);
    const [submitStatus, setSubmitStatus] = useState<"success" | "error" | null>(null);
    const { navigabilityFeedbacks, isLoading, submitNavigabilityFeedback } = useNavigabilityFeedbacks();
    const [terminalNameFilter, setTerminalNameFilter] = useState("");
    const [fairwayNameFilter, setFairwayNameFilter] = useState("");

    const onSubmit = async () => {
        try {
            await submitNavigabilityFeedback({
                ...newComment,
                timestamp: newComment.timestamp.split(".")[0],
            });
            form.resetFields();
            setNewComment(emptyComment);
            setSubmitStatus("success");
        } catch (err) {
            console.error(err);
            setSubmitStatus("error");
        } finally {
            setTimeout(() => setSubmitStatus(null), 5000);
        }
    };

    const csvExport = () => {
        const csvHeaders = [
            "timestamp",
            "ship_name",
            "fairway_name",
            "terminal_name",
            "difficulty",
            "intensity",
            "comment",
        ];
        const csvContent = navigabilityFeedbacks.map(
            ({ timestamp, ship_name, fairway_name, terminal_name, difficulty, intensity, comment }) => [
                timestamp,
                ship_name,
                fairway_name,
                terminal_name,
                difficulty,
                intensity,
                comment,
            ],
        ) as string[][];
        const csvString = arrayToCsv([csvHeaders, ...csvContent]);
        downloadBlob(csvString, "retours-experience.csv", "text/csv;charset=utf-8;");
    };

    const columns: CustomColumnType[] = [
        {
            title: "Date",
            dataIndex: "timestamp",
            key: "timestamp",
            width: 200,
            align: "center",
        },
        {
            title: "Nom du navire",
            dataIndex: "ship_name",
            key: "ship_name",
            width: 200,
            align: "center",
        },
        {
            title: "Nom de la passe",
            dataIndex: "fairway_name",
            key: "fairway_name",
            width: 200,
            align: "center",
        },
        {
            title: "Nom du poste à quai",
            dataIndex: "terminal_name",
            key: "terminal_name",
            width: 200,
            align: "center",
        },
        {
            title: "Difficulté",
            dataIndex: "difficulty",
            key: "difficulty",
            width: 200,
            align: "center",
        },
        {
            title: "Intensité",
            dataIndex: "intensity",
            key: "intensity",
            width: 200,
            align: "center",
        },
        {
            title: "Commentaire",
            dataIndex: "comment",
            key: "comment",
            width: 500,
        },
    ];

    const addColumnHandlers = (cols: CustomColumnType[]): any => {
        return cols.map((col) => ({
            ...col,
            onCell: (record: Partial<INavigabilityFeedbacks>) => ({
                record,
                dataIndex: col.dataIndex,
                ...(!record.id && {
                    handleEdit: (dataIndex: keyof INavigabilityFeedbacks, value?: string) => {
                        setNewComment((oldRecord) => {
                            const newRecord = { ...oldRecord, [dataIndex]: value };
                            return {
                                ...newRecord,
                            };
                        });
                    },
                    fairwaysOptions: fairways
                        .map((fairway) => ({ value: fairway.name }))
                        .filter(({ value }) => value.toLowerCase().includes(fairwayNameFilter.toLocaleLowerCase())),
                    setFairwayNameFilter,
                    fairwayNameFilter,
                    terminalsOptions: terminals
                        .map(({ numero_pos }) => ({ value: `P.${numero_pos}` }))
                        .filter(({ value }) => value.toLowerCase().includes(terminalNameFilter.toLocaleLowerCase())),
                    setTerminalNameFilter,
                    terminalNameFilter,
                }),
            }),
        }));
    };

    return (
        <Form onFinish={onSubmit} form={form} className="navigability-feedbacks">
            <div className="navigability-feedbacks-header">
                <Button disabled={isLoading} type="primary" onClick={csvExport}>
                    <DownloadOutlined />
                    Exporter (.csv)
                </Button>
            </div>
            {submitStatus === "success" && (
                <Alert type="success" message="Le commentaire a bien été ajouté." closable />
            )}
            {submitStatus === "error" && (
                <Alert type="error" message="Une erreur est survenue lors de la sauvegarde du commentaire." closable />
            )}
            <div className="table-wrapper">
                <div className="table-inner-wrapper">
                    <Table
                        components={{
                            body: {
                                cell: NavigabilityFeedbacksCell,
                            },
                        }}
                        pagination={false}
                        dataSource={[newComment, ...navigabilityFeedbacks]}
                        columns={addColumnHandlers(columns)}
                    />
                </div>
            </div>
        </Form>
    );
};
