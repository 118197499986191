import React from "react";

export default function CylinderIcon() {
    return (
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.20002 8.20002C12.1765 8.20002 15.4 6.58824 15.4 4.60001C15.4 2.61178 12.1765 1 8.20002 1C4.22356 1 1 2.61178 1 4.60001C1 6.58824 4.22356 8.20002 8.20002 8.20002Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4 4.59985V15.3999C15.4 17.3911 12.1713 18.9999 8.20002 18.9999C4.22876 18.9999 1 17.3911 1 15.3999V4.59985"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4 8.19995C15.4 10.1912 12.1713 11.8 8.20002 11.8C4.22876 11.8 1 10.1912 1 8.19995"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4 11.8C15.4 13.7913 12.1713 15.4001 8.20002 15.4001C4.22876 15.4001 1 13.7913 1 11.8"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
