import React from "react";
import {
    EPlanningLineType,
    IPlanning,
    IPlanningTimeRange,
    IPrefectoralDecree,
    IPrefectoralDecreeTimeRange,
} from "./types";
import { v4 } from "uuid";

const DAY_TO_MS = 1000 * 60 * 60 * 24;
const YEARS_TO_MS = DAY_TO_MS * 365;

const clonePlanning = (planning: IPlanning): IPlanning => {
    const { sections, prefectoralDecrees } = planning;

    return {
        sections: sections.map(({ sectionData, sectionLabel }) => ({
            sectionLabel,
            sectionData: sectionData.map(({ linePlanning, ...rest }) => ({
                ...rest,
                linePlanning: linePlanning.map((line) => ({
                    ...line,
                })),
            })),
        })),
        prefectoralDecrees: prefectoralDecrees.map(({ timeranges, additionalInformation, ...rest }) => ({
            ...rest,
            additionalInformation: {
                debitValue: {
                    ...additionalInformation.debitValue,
                },
                o2dissousValue: {
                    ...additionalInformation.o2dissousValue,
                },
            },
            timeranges: timeranges.map((timerange) => ({ ...timerange })),
        })),
    };
};

export const onResizeTimeRange = (
    value: IPlanning,
    sectionIndex: number,
    lineIndex: number,
    itemId: string,
    keyToModify: "from" | "to",
    blocks: number,
) => {
    const newValue = clonePlanning(value);
    const currentLinePlanning = newValue.sections[sectionIndex].sectionData[lineIndex].linePlanning;
    const itemIndex = currentLinePlanning.findIndex((item) => item.id === itemId);
    const { from, to } = currentLinePlanning[itemIndex];
    const currentSize = Math.floor((new Date(to).getTime() - new Date(from).getTime()) / DAY_TO_MS);
    if (keyToModify === "from") {
        const prevItem = currentLinePlanning[itemIndex - 1];
        const prevItemToTs = prevItem && new Date(prevItem.to).getTime();
        const newFromTs = new Date(from).getTime() + blocks * DAY_TO_MS;
        if (blocks < 0 && (!prevItemToTs || prevItemToTs <= newFromTs)) {
            currentLinePlanning[itemIndex].from = new Date(newFromTs).toISOString();
        } else if (blocks > 0 && currentSize >= 2) {
            currentLinePlanning[itemIndex].from = new Date(newFromTs).toISOString();
        }
    } else {
        const nextItem = currentLinePlanning[itemIndex + 1];
        const nextItemFromTs = nextItem && new Date(nextItem.from).getTime();
        const newToTs = new Date(to).getTime() + blocks * DAY_TO_MS;
        if (blocks < 0 && currentSize >= 2) {
            currentLinePlanning[itemIndex].to = new Date(newToTs).toISOString();
        } else if (blocks > 0 && (!nextItemFromTs || nextItemFromTs >= newToTs)) {
            currentLinePlanning[itemIndex].to = new Date(newToTs).toISOString();
        }
    }

    return newValue;
};

export const onDeleteTimeRange = (value: IPlanning, sectionIndex: number, lineIndex: number, itemId: string) => {
    const newValue = clonePlanning(value);
    const currentLinePlanning = newValue.sections[sectionIndex].sectionData[lineIndex].linePlanning;
    const itemIndex = currentLinePlanning.findIndex((item) => item.id === itemId);
    currentLinePlanning.splice(itemIndex, 1);
    newValue.sections[sectionIndex].sectionData[lineIndex].linePlanning = currentLinePlanning;

    return newValue;
};

export const onCreateTimeRange = (
    value: IPlanning,
    sectionIndex: number,
    lineIndex: number,
    timerange: Partial<IPlanningTimeRange>,
) => {
    const newValue = clonePlanning(value);
    const fullTimerange = {
        id: v4(),
        ...timerange,
    } as IPlanningTimeRange;
    const currentLinePlanning = newValue.sections[sectionIndex].sectionData[lineIndex].linePlanning;
    currentLinePlanning.push(fullTimerange);
    currentLinePlanning.sort((a, b) => {
        const aTs = new Date(a.from).getTime();
        const bTs = new Date(b.from).getTime();
        if (aTs > bTs) {
            return 1;
        }
        return -1;
    });

    return newValue;
};

export const toggleLineLocked = (value: IPlanning, sectionIndex: number, lineIndex: number) => {
    const newValue = clonePlanning(value);
    newValue.sections[sectionIndex].sectionData[lineIndex].locked =
        !newValue.sections[sectionIndex].sectionData[lineIndex].locked;

    return newValue;
};

export const onCreatePrefectoralDecree = (
    value: IPlanning,
    sectionIndex: number,
    lineIndex: number,
    prefectoralDecree: IPrefectoralDecree,
    isUpdate: boolean,
) => {
    const newValue = clonePlanning(value);

    // Remove the old timeranges if the prefectoral decree is updated
    if (isUpdate) {
        newValue.sections[sectionIndex].sectionData[lineIndex].linePlanning = newValue.sections[
            sectionIndex
        ].sectionData[lineIndex].linePlanning.filter((prefectoralDecreeTimeRange) => {
            return (prefectoralDecreeTimeRange as IPrefectoralDecreeTimeRange).code !== prefectoralDecree.code;
        });
    }
    const startYear = new Date(prefectoralDecree.applicationDate as string).getFullYear();
    const numYearsyears = Math.ceil(
        (new Date(prefectoralDecree.endDate as string).getTime() -
            new Date(prefectoralDecree.applicationDate as string).getTime()) /
            YEARS_TO_MS,
    );
    const { additionalInformation } = prefectoralDecree;
    const hasAdditionnalInformation =
        (additionalInformation?.debitValue.value !== null && additionalInformation?.debitValue.operator !== null) ||
        (additionalInformation.o2dissousValue.value !== null && additionalInformation.o2dissousValue.operator !== null);

    const timeranges = prefectoralDecree.timeranges.reduce((acc: Array<IPrefectoralDecreeTimeRange | null>, cur) => {
        const { from, to, activity, fairwayType } = cur;
        const [fromDay, fromMonth] = (from as string).split("/");
        const [toDay, toMonth] = (to as string).split("/");

        const allTimeranges = [...Array(numYearsyears)]
            .map((_, index, array) => {
                const currentYear = startYear + index;
                let fromTs = new Date().setFullYear(currentYear, parseInt(fromMonth) - 1, parseInt(fromDay) - 1);
                let toTs = new Date().setFullYear(currentYear, parseInt(toMonth) - 1, parseInt(toDay));
                if (index === 0) {
                    const applicationTs = new Date(prefectoralDecree.applicationDate as string).getTime();
                    if (fromTs < applicationTs) {
                        if (toTs < applicationTs) {
                            return null;
                        } else {
                            fromTs = applicationTs;
                        }
                    }
                }
                if (index === array.length - 1) {
                    const endTs = new Date(prefectoralDecree.endDate as string).getTime();
                    if (toTs > endTs) {
                        if (fromTs > endTs) {
                            return null;
                        } else {
                            toTs = endTs;
                        }
                    }
                }

                return {
                    id: v4(),
                    from: new Date(fromTs).toISOString(),
                    to: new Date(toTs).toISOString(),
                    text: `${prefectoralDecree.code} - Type de passe : ${fairwayType}`,
                    tooltip: `${prefectoralDecree.code} - Type de passe : ${fairwayType}`,
                    activity,
                    fairwayType,
                    code: prefectoralDecree.code,
                    prefectoralDecreeId: prefectoralDecree.id,
                    ...(hasAdditionnalInformation && { additionalInformation }),
                } as IPrefectoralDecreeTimeRange;
            })
            .filter((timerange) => !!timerange);

        return [...acc, ...allTimeranges];
    }, []);

    newValue.sections[sectionIndex].sectionData[lineIndex].linePlanning.push(
        ...(timeranges as IPrefectoralDecreeTimeRange[]),
    );
    newValue.sections[sectionIndex].sectionData[lineIndex].linePlanning.sort((a, b) => {
        const aTs = new Date(a.from).getTime();
        const bTs = new Date(b.from).getTime();
        if (aTs > bTs) {
            return 1;
        }
        return -1;
    });
    if (isUpdate) {
        const prefectoralDecreeToUpdateIndex = newValue.prefectoralDecrees.findIndex(
            ({ code }) => code === prefectoralDecree.code,
        );
        prefectoralDecreeToUpdateIndex !== -1 &&
            newValue.prefectoralDecrees.splice(prefectoralDecreeToUpdateIndex, 1, prefectoralDecree);
    } else {
        newValue.prefectoralDecrees.push(prefectoralDecree);
    }
    return newValue;
};

export const showPrefectoralDecreeTooltip = (
    lineType: EPlanningLineType,
    linePlanning: IPrefectoralDecreeTimeRange[],
): boolean =>
    lineType === EPlanningLineType.PREFECTURAL_DECREE &&
    linePlanning.filter(({ additionalInformation }) => !!additionalInformation).length > 0;

export const getPrefectoralDecreeAdditionalInformation = (linePlanning: IPrefectoralDecreeTimeRange[]) => {
    return linePlanning
        .reduce((acc: IPrefectoralDecreeTimeRange[], cur) => {
            const hasPrefectoralDecree = acc.find(
                ({ prefectoralDecreeId }) => prefectoralDecreeId === cur.prefectoralDecreeId,
            );
            if (!hasPrefectoralDecree) {
                acc.push(cur);
            }
            return acc;
        }, [])
        .filter(({ additionalInformation }) => !!additionalInformation)
        .map(({ code, additionalInformation }, index) => {
            if (!additionalInformation) {
                return;
            }
            const { o2dissousValue, debitValue } = additionalInformation;
            const hasO2dissous = o2dissousValue.value !== null && o2dissousValue.operator !== null;
            const hasDebitValue = debitValue.value !== null && debitValue.operator !== null;

            return (
                <div key={index}>
                    <b>{code}</b>
                    <ul style={{ margin: 0 }}>
                        {hasDebitValue && (
                            <li>
                                Débit (m3/s) à respecter : {debitValue.operator} {debitValue.value}{" "}
                                {debitValue.fairwayType ? `sur les passes ${debitValue.fairwayType.toLowerCase()}` : ""}
                            </li>
                        )}
                        {hasO2dissous && (
                            <li>
                                Oxygène dissous (mg/l) à respecter : {o2dissousValue.operator} {o2dissousValue.value}{" "}
                                {debitValue.fairwayType ? `sur les passes ${debitValue.fairwayType.toLowerCase()}` : ""}
                            </li>
                        )}
                    </ul>
                </div>
            );
        });
};
