import React, { useState } from "react";
import { BottomPanel } from "../../../Shared/components/BottomPanel/BottomPanel";
import { Legends } from "../../../Shared/components/Legends/Legends";
import { Timeline } from "../../../Shared/components/Timeline/Timeline";
import { AnnualStrategyProgramming } from "./AnnualStrategyProgramming/AnnualStrategyProgramming";

enum AnnualStrategyTabKeys {
    PROGRAMMING = "programming",
    LEGENDS = "legends",
}

export const AnnualStrategyBottomPanelPlugin = () => {
    const [isBottomPanelOpen, setIsBottomPanelOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(AnnualStrategyTabKeys.LEGENDS);
    const handleClickTab = (tabId: string) => {
        setIsBottomPanelOpen(true);
        setSelectedTab(tabId as AnnualStrategyTabKeys);
    };
    const isTabOpen = (tabId: AnnualStrategyTabKeys) => {
        return selectedTab === tabId && isBottomPanelOpen;
    };

    return (
        <>
            <Timeline />
            <BottomPanel
                items={[
                    {
                        label: "Programmation",
                        children: <AnnualStrategyProgramming isOpen={isTabOpen(AnnualStrategyTabKeys.PROGRAMMING)} />,
                        key: AnnualStrategyTabKeys.PROGRAMMING,
                    },
                    {
                        label: "Légendes",
                        children: <Legends isOpen={isTabOpen(AnnualStrategyTabKeys.LEGENDS)} />,
                        key: AnnualStrategyTabKeys.LEGENDS,
                    },
                ]}
                isOpen={isBottomPanelOpen}
                onClose={() => setIsBottomPanelOpen(false)}
                onOpen={handleClickTab}
                style={{ flexBasis: isBottomPanelOpen ? 556 : 42 }}
            />
        </>
    );
};
