import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLayerProperty } from "qwc2/actions/layers";
import { zoomToPoint } from "qwc2/actions/map";
import { useWMSLayer } from "../../../useWMSLayer";
import { getAllLayersNames, getLayerPath } from "../../../utils/layer";
import { bookmarks } from "../../SearchBar/providers/bookmarks.list";
import { IBookmark } from "../../SearchBar/providers/types";
import { List } from "antd";
import { BookOutlined } from "@ant-design/icons";
import CoordinatesUtils from "qwc2/utils/CoordinatesUtils";
import MapUtils from "qwc2/utils/MapUtils";
import { MenuModal } from "../../MenuModal/MenuModal";
import { EModuleID } from "../../../Enums/EModuleID";
import "./Bookmarks.less";
import TopBarButton from "../TopBarButton/TopBarButton";

export const Bookmarks = () => {
    const [showBookmarksMenu, setShowBookmarksMenu] = useState(false);
    const dispatch = useDispatch();
    const wmsLayer = useWMSLayer();
    const { currentModule, map } = useSelector((state: any) => ({
        currentModule: state.theme.current,
        map: state.map,
    }));
    const currentModuleId = currentModule?.id;
    const availableBookmarks = bookmarks[currentModuleId as EModuleID.CARAC_MILIEU] || [];
    const zoomToBookmarkBbox = (bookmark: IBookmark) => {
        const bbox = bookmark.bbox ? bookmark.bbox.slice(0) : [];
        const crs = bookmark.crs;
        const x = 0.5 * (bookmark.bbox[0] + bookmark.bbox[2]);
        const y = 0.5 * (bookmark.bbox[1] + bookmark.bbox[3]);
        const maxZoom = MapUtils.computeZoom(map.scales, 10);
        const mapbbox = CoordinatesUtils.reprojectBbox(bbox, crs, map.projection);
        const newZoom = Math.max(0, MapUtils.getZoomForExtent(mapbbox, map.resolutions, map.size, 0, maxZoom + 1) - 1);
        dispatch(zoomToPoint([x, y], newZoom, crs));
    };
    const onSelectBookmark = (bookmark: IBookmark) => {
        if (!wmsLayer) {
            return;
        }
        zoomToBookmarkBbox(bookmark);
        getAllLayersNames(wmsLayer).forEach((layerName) => {
            if (bookmark.activeLayers === undefined) {
                return;
            }
            const visible = bookmark.activeLayers.includes(layerName);
            const layerPath = getLayerPath(layerName, wmsLayer, []);
            dispatch(changeLayerProperty(wmsLayer.uuid, "visibility", visible, layerPath));
        });
        setShowBookmarksMenu(false);
    };

    return (
        <div className="bookmarks-wrapper">
            <TopBarButton
                tooltip="Signets"
                active={showBookmarksMenu}
                className="bookmarks-button"
                onClick={() => setShowBookmarksMenu((drawerState) => !drawerState)}
            >
                <BookOutlined />
            </TopBarButton>
            {showBookmarksMenu && (
                <MenuModal onClose={() => setShowBookmarksMenu(false)} title="Signets" icon={<BookOutlined />}>
                    <List className="bookmarks-list">
                        <List.Item className="bookmarks-list-title">Zoomer sur le lieu :</List.Item>
                        {availableBookmarks.map((bookmark) => (
                            <List.Item
                                className="bookmark-item"
                                key={bookmark.id}
                                onClick={() => onSelectBookmark(bookmark)}
                            >
                                {bookmark.text}
                            </List.Item>
                        ))}
                    </List>
                </MenuModal>
            )}
        </div>
    );
};
