import React, { useState } from "react";
import { Button, Table } from "antd";
import Column from "antd/lib/table/Column";
import { arrayToCsv } from "../../Shared/utils/csv";
import { downloadBlob } from "../../Shared/utils/download";
import "./AlertLogTable.less";
import { FetchMethods, fetchSLF } from "../../Shared/utils/fetch";
import { LoadingOutlined } from "@ant-design/icons";
import { WaterwayType } from "../../Shared/useWaterways";
import ColorStatus, { Color } from "../../Shared/components/ColorStatus/ColorStatus";
import { IAlertLog, IDredgingAlert } from "../types/alert.types";

interface IAlertLogTable {
    type: WaterwayType;
    alertLog: IAlertLog;
    onReset: () => void;
}

const alertGroupTitleByCollectionKey = {
    month: "Alertes du mois",
    d1: "Alertes J+1",
    d30: "Alertes J+30",
    d100: "Alertes J+100",
};

export const AlertLogTable = ({ type, alertLog, onReset }: IAlertLogTable) => {
    const [resetLoading, setResetLoading] = useState(false);
    const csvExport = () => {
        const csvHeaders = ["notice", "name", "quotation", "timestamp"];
        const csvContent = Object.keys(alertLog).reduce(
            (acc, key) =>
                acc.concat(
                    alertLog[key as keyof typeof alertLog].map(({ name, quotation, timestamp }) => [
                        key,
                        name,
                        String(quotation),
                        timestamp,
                    ]),
                ),
            [] as string[][],
        );
        const csvString = arrayToCsv([csvHeaders, ...csvContent]);
        downloadBlob(csvString, "alert-log.csv", "text/csv;charset=utf-8;");
    };

    const reset = async () => {
        setResetLoading(true);
        try {
            await fetchSLF(`dragage/alerts/${type}`, { method: FetchMethods.DELETE });
        } catch (err) {
            console.error(err);
        } finally {
            setResetLoading(false);
            onReset();
        }
    };

    return (
        <>
            <div className="alert-log-buttons-wrapper">
                <Button type="primary" onClick={csvExport}>
                    Export CSV
                </Button>
                <Button onClick={reset} type="default">
                    Réinitialiser {resetLoading && <LoadingOutlined />}
                </Button>
            </div>
            {Object.entries(alertLog).map(([key, log]: [string, IDredgingAlert[]]) => (
                <React.Fragment key={key}>
                    <h1>{alertGroupTitleByCollectionKey[key as keyof typeof alertLog]}</h1>
                    <Table dataSource={log} pagination={false} size="small">
                        <Column title={type === WaterwayType.FAIRWAY ? "Passe" : "Souille"} dataIndex="name" />
                        <Column title="Cote" dataIndex="quotation" className="quotation" />
                        <Column
                            title="Statut"
                            dataIndex="status"
                            className="status"
                            render={(status: Color) => <ColorStatus color={status} />}
                        />
                        <Column
                            title="Date de détection"
                            dataIndex="timestamp"
                            key="timestamp"
                            className="timestamp"
                            render={(timestamp) => {
                                const date = new Date(timestamp);
                                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                            }}
                        />
                    </Table>
                </React.Fragment>
            ))}
        </>
    );
};
