import React from "react";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { IPlanningTimeRange } from "../../Shared/components/Planning/types";
import { getDateFromVigieSipFormat } from "../../Shared/utils/date";
import { EVigieSipEndpoints, fetchVigieSip } from "../../Shared/utils/fetch";
import { EProgrammedState } from "./types";

const DAY_TO_MS = 1000 * 60 * 60 * 24;

interface INaviresAQuaiDTO {
    ACCOSTAGE: string;
    NAVIRE: string;
    ["TYPE NAVIRE"]: string;
    AGENT: string;
    NUMESC: number;
    ETD: string;
    POSTE: string;
    OPERATION: string;
    TONNAGE: string;
    NATURE: string;
}

const mapVigieSipDataToPlanningData = (data: INaviresAQuaiDTO[]): IPlanningTimeRange[] => {
    const dataWithISOTS = data.map(({ ACCOSTAGE, ETD, ...rest }) => ({
        ...rest,
        ACCOSTAGE,
        ETD,
        fromTs: new Date(getDateFromVigieSipFormat(ACCOSTAGE)).getTime(),
        toTs: new Date(getDateFromVigieSipFormat(ETD)).getTime() + DAY_TO_MS - 1,
    }));
    const { minDateTs, maxDateTs } = dataWithISOTS.reduce(
        (acc, cur) => {
            const { fromTs, toTs } = cur;
            if (!acc.minDateTs || fromTs < acc.minDateTs) {
                acc.minDateTs = fromTs;
            }
            if (!acc.maxDateTs || toTs > acc.maxDateTs) {
                acc.maxDateTs = toTs;
            }

            return acc;
        },
        { minDateTs: 0, maxDateTs: 0 },
    );

    const dayInterval = Math.ceil((maxDateTs - minDateTs) / DAY_TO_MS);

    const linePlanningData = [...Array(dayInterval)]
        .map((_day, index) => {
            const currentDateTs = new Date(minDateTs + index * DAY_TO_MS).getTime();
            const stayingShips = dataWithISOTS.reduce((acc: INaviresAQuaiDTO[], cur) => {
                const { fromTs, toTs, ...rest } = cur;
                if (currentDateTs >= fromTs && currentDateTs <= toTs) {
                    acc.push(rest);
                }

                return acc;
            }, []);

            if (stayingShips.length > 0) {
                stayingShips.sort((a, b) => {
                    const [numQuaiA] = a.POSTE.split(" ");
                    const [numQuaiB] = b.POSTE.split(" ");

                    if (numQuaiA > numQuaiB) {
                        return 1;
                    }

                    return -1;
                });
                return {
                    id: v4(),
                    from: new Date(currentDateTs).toISOString(),
                    to: new Date(currentDateTs + DAY_TO_MS - 1).toISOString(),
                    activity: EProgrammedState.COMMERCIAL_COMMITMENTS,
                    text: "...",
                    tooltip: (
                        <div>
                            {stayingShips.map((stayingShip, index) => (
                                <p key={index}>
                                    {`Poste: ${stayingShip.POSTE}, Accostage: ${stayingShip.ACCOSTAGE}, ETD: ${stayingShip.ETD}, NAVIRE: ${stayingShip.NAVIRE}`}
                                </p>
                            ))}
                        </div>
                    ),
                };
            }
        })
        .filter((linePlanning) => !!linePlanning) as IPlanningTimeRange[];

    return linePlanningData;
};

interface IUseCommercialCommitments {
    isOpen: boolean;
}

export const useCommercialCommitments = ({ isOpen }: IUseCommercialCommitments) => {
    const [commercialCommitments, setCommercialCommitments] = useState<IPlanningTimeRange[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            try {
                const naviresAQuai = await fetchVigieSip(EVigieSipEndpoints.NAVIRES_A_QUAI);
                const mappedData = mapVigieSipDataToPlanningData(naviresAQuai);
                setCommercialCommitments(mappedData);
            } catch (err) {
                console.error(err);
                setCommercialCommitments([]);
            } finally {
                setIsLoading(false);
            }
        };

        if (isOpen) {
            init();
        }
    }, [isOpen]);

    return {
        commercialCommitments,
        isLoading,
    };
};
