import React from "react";
import { Tabs } from "antd";
import dragageImg from "../../../Home/images/IMG_draguage.png";
import { useWMSLayer } from "../../../Shared/useWMSLayer";
import { LayerTreeWrapper } from "../../../Shared/components/LayerTree/LayerTreeWrapper";

enum AnnualStrategyTabKeys {
    LAYERS = "layers",
}

export const AnnualStrategyLeftBar = () => {
    const WMSLayer = useWMSLayer();

    return (
        <>
            <div className="description">
                <img src={dragageImg} alt="dragage" />
                <h1>Stratégie annuelle</h1>
            </div>
            <Tabs
                items={[
                    {
                        label: "Couches",
                        children: <LayerTreeWrapper WMSLayer={WMSLayer} />,
                        key: AnnualStrategyTabKeys.LAYERS,
                    },
                ]}
                defaultActiveKey={AnnualStrategyTabKeys.LAYERS}
            />
        </>
    );
};
