import React from "react";

export default function LogOutIcon() {
    return (
        <div
            style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#484964",
                borderRadius: "8px",
                height: "2rem",
                width: "2rem",
                marginLeft: "0.5rem",
            }}
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM12 11.2L16 8L12 4.8V7.2H5.6V8.8H12V11.2Z"
                    fill="white"
                />
            </svg>
        </div>
    );
}
