import { useCallback, useEffect, useState } from "react";
import { fetchSLF } from "../Shared/utils/fetch";

export interface ITerminal {
    id: number;
    commune: string;
    long: number;
    code_insee: number;
    rive: "Droite" | "Gauche";
    numero_pos: number;
    geom: string;
}

export const useTerminals = () => {
    const [terminals, setTerminals] = useState<ITerminal[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTerminals = useCallback(async () => {
        setIsLoading(true);
        try {
            const response: ITerminal[] = await fetchSLF(`dragage/postes_a_quais`);
            setTerminals(
                response.sort(({ numero_pos: numero_posA }, { numero_pos: numero_posB }) => {
                    if (numero_posA > numero_posB) {
                        return 1;
                    }
                    return -1;
                }),
            );
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchTerminals();
    }, [fetchTerminals]);

    return {
        terminals,
        isLoading,
    };
};
