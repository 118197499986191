import React from "react";
import { Divider, Form, Slider, Tag } from "antd";
import { LayerLevel } from "../useFilteringPanel";
import "./FilteringSlider.less";

export default function FilteringSlider({
    layer,
    min,
    max,
    title,
    type,
    defaultValue,
}: {
    layer: string;
    min: number;
    max: number;
    title?: string;
    type?: LayerLevel;
    defaultValue?: [number, number];
}) {
    return (
        <>
            <Form.Item
                label={
                    <>
                        {title}
                        {type != undefined && <Tag color={filterTagColorByLayerLevel[type]}>{type}</Tag>}
                    </>
                }
                name={layer}
                className="filtering-slider"
            >
                <Slider range marks={{ [min]: min, [max]: max }} max={max} min={min} defaultValue={defaultValue} />
            </Form.Item>
            <Divider />
        </>
    );
}

const filterTagColorByLayerLevel = {
    [LayerLevel.TOP]: "#FF9C6E",
    [LayerLevel.BOTTOM]: "#1890FF",
};
