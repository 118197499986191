import { useSelector } from "react-redux";
import { IQWC2Layer } from "./Interfaces/IQWC2Layer";

export const useWMSLayer = () => {
    const { layers } = useSelector((state: any) => ({
        layers: state.layers.flat,
    }));

    return (layers as IQWC2Layer[]).find(({ type }) => type === "wms");
};
