export function arrayToCsv(rows: string[][]): string {
    return rows
        .flatMap((row: string[]) =>
            row
                .map((v: string) => (v ? v.replaceAll('"', '""') : ""))
                .map((v: string) => `"${v}"`)
                .join(","),
        )
        .join("\n");
}
