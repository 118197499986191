import React, { useMemo } from "react";
import Search from "qwc2/components/Search";
import { searchProviderFactory, SearchProviders } from "./providers";
import "./SearchBar.less";

export const SearchBar: React.FC = () => {
    const SearchCmp = useMemo(() => Search(SearchProviders, searchProviderFactory), []);

    return <SearchCmp searchOptions={{ minScaleDenom: 10 }} />;
};
