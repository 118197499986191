import React, { useContext, useEffect, useState } from "react";
import { useCommercialCommitments } from "../../useCommercialCommitments";
import { Planning } from "../../../../Shared/components/Planning/Planning";
import { Button, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import classNames from "classnames";
import { LayoutContext } from "../../../../Shared/contexts/LayoutContextProvider";
import { AnnualStrategySettings } from "../AnnualStrategySettings/AnnualStrategySettings";
import { usePlanning } from "../../usePlanning";
import { planningLegend } from "../../planning.legend";
import UserContext, { IUser, IUserContext } from "../../../../UserContext";
import { EDraftStatus } from "../../types";
import "./AnnualStrategyProgramming.less";
import useOLResize from "../../../../Shared/useOLResize";

const timespans = [
    {
        label: "5J",
        value: 5,
        columnGap: 1,
    },
    {
        label: "1S",
        value: 7,
        columnGap: 1,
    },
    {
        label: "1M",
        value: 30,
        columnGap: 1,
    },
    {
        label: "3M",
        value: 90,
        columnGap: 7,
    },
    {
        label: "6M",
        value: 180,
        columnGap: 15,
    },
    {
        label: "1Y",
        value: 365,
        columnGap: 30,
    },
];

interface IAnnualStrategyProgramming {
    isOpen: boolean;
}

export const AnnualStrategyProgramming = ({ isOpen }: IAnnualStrategyProgramming) => {
    const [showSettings, setShowSettings] = useState(false);
    const { setOnMapModal } = useContext(LayoutContext);
    const { user } = useContext(UserContext) as IUserContext;
    const [displaySavingLoader, setDisplaySavingLoader] = useState(false);
    const { isLoading: isVigieSipLoading, commercialCommitments } = useCommercialCommitments({ isOpen });
    const {
        isLoading: isPlanningDataLoading,
        planning,
        planningDraft,
        onEdit,
        onDiscard,
        onSave,
        showMismatchVersion,
        setShowMismatchVersion,
        draftStatus,
    } = usePlanning({
        isOpen,
        commercialCommitments,
        user: user as IUser,
    });

    const isLoading = isVigieSipLoading || isPlanningDataLoading;

    useEffect(() => {
        if (draftStatus === EDraftStatus.SAVING) {
            setDisplaySavingLoader(true);
            setTimeout(() => setDisplaySavingLoader(false), 1000);
        }
    }, [draftStatus]);

    useEffect(() => {
        if (!setOnMapModal) {
            return;
        }
        if (showSettings && showMismatchVersion) {
            setOnMapModal({
                title: "Conflit de version",
                content: (
                    <div>
                        <p>La version actuelle du planning semble plus récente que votre brouillon.</p>
                        <div>
                            <Button style={{ margin: 5 }} onClick={() => setShowMismatchVersion(false)}>
                                Continuer à éditer mon brouillon
                            </Button>
                            <Button style={{ margin: 5 }} onClick={onDiscard} type="primary">
                                Repartir de la dernière version du planning
                            </Button>
                        </div>
                    </div>
                ),
                onClose: () => setShowSettings(false),
            });
        } else if (showSettings) {
            const isDraft = Boolean(planningDraft);

            const title = (
                <>
                    <span style={{ marginRight: 5 }}>Programmation</span>
                    {isDraft && <span className="draft-indicator">(brouillon)</span>}
                    {displaySavingLoader && <Spin indicator={<LoadingOutlined spin />} />}
                    {!displaySavingLoader && draftStatus === EDraftStatus.SAVED && (
                        <Tooltip title="Brouillon enregistré">
                            <CheckCircleOutlined className="save-success" />
                        </Tooltip>
                    )}
                    {!displaySavingLoader && draftStatus === EDraftStatus.ERROR && (
                        <Tooltip title="Une erreur est survenu lors de l'enregistrement. Ne fermez par la page pour ne pas perdre votre brouillon.">
                            <CloseCircleOutlined className="save-error" />
                        </Tooltip>
                    )}
                </>
            );
            setOnMapModal({
                title,
                className: "annual-strategy-settings",
                content: (
                    <AnnualStrategySettings
                        planning={planningDraft || planning}
                        isDraft={isDraft}
                        legend={planningLegend}
                        onChange={onEdit}
                        onDiscard={onDiscard}
                        onSave={onSave}
                        onClose={() => setShowSettings(false)}
                    />
                ),
                onClose: () => setShowSettings(false),
                fullscreen: true,
            });
        } else {
            setOnMapModal(null);
        }
    }, [
        setOnMapModal,
        showSettings,
        planningDraft,
        planning,
        showMismatchVersion,
        setShowMismatchVersion,
        draftStatus,
        displaySavingLoader,
        onEdit,
        onDiscard,
        onSave,
    ]);

    const resizeMap = useOLResize();
    useEffect(() => {
        !isLoading && isOpen && resizeMap();
    }, [isLoading, isOpen, resizeMap]);

    return (
        <div className={classNames("annual-strategy-programming-wrapper", { opened: isOpen })}>
            {isLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
            ) : (
                <Planning
                    legend={planningLegend}
                    timespans={timespans}
                    value={planning}
                    header={
                        <div className="planning-header">
                            <div className="planning-legend-wrapper">
                                {planningLegend.map(({ label, color }) => (
                                    <div key={label}>
                                        <span className="legend-item-color" style={{ backgroundColor: color }} />
                                        <span>{label}</span>
                                    </div>
                                ))}
                            </div>
                            <Button type="primary" onClick={() => setShowSettings(true)}>
                                <SettingOutlined style={{ fontSize: 18 }} />
                                Paramétrage
                            </Button>
                        </div>
                    }
                    showCursor
                    showTimespanButtons
                    defaultTimespanIndex={1}
                    showDate
                />
            )}
        </div>
    );
};
