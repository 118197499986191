import { addSearchResults } from "qwc2/actions/search";
import { changeLayerProperty } from "qwc2/actions/layers";
import { IQWC2Layer } from "../../../Interfaces/IQWC2Layer";
import { bookmarks } from "./bookmarks.list";
import { getAllLayersNames, getLayerPath } from "../../../utils/layer";
import { EModuleID } from "../../../Enums/EModuleID";

export const bookmarksSearch = (
    text: string,
    requestId: string,
    searchOptions: any,
    dispatch: (action: any) => void,
    state: any,
) => {
    const onSelectBookmark = (activeLayers: string[]) => () => {
        const wmsLayer = (state.layers.flat as IQWC2Layer[]).find(({ type }) => type === "wms");
        if (!wmsLayer) {
            return;
        }

        getAllLayersNames(wmsLayer).forEach((layerName) => {
            const visible = activeLayers.includes(layerName);
            const layerPath = getLayerPath(layerName, wmsLayer, []);
            dispatch(changeLayerProperty(wmsLayer.uuid, "visibility", visible, layerPath));
        });
    };
    const currentModuleId = state.theme.current.id;
    const moduleBookmarks = bookmarks[currentModuleId as EModuleID.CARAC_MILIEU];
    const selectedBookmarks = moduleBookmarks
        .filter(({ text: bookmarkText }) => bookmarkText.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
        .map(({ activeLayers, ...bookmark }) => ({
            ...bookmark,
            ...(activeLayers !== undefined && { onClick: onSelectBookmark(activeLayers) }),
        }));
    const data =
        selectedBookmarks.length > 0
            ? [
                  {
                      id: "bookmarksgroup",
                      title: "Géosignets",
                      items: selectedBookmarks,
                  },
              ]
            : [];

    dispatch(
        addSearchResults(
            {
                data,
                provider: "bookmarks",
                reqId: requestId,
            },
            true,
        ),
    );
};
