import React from "react";
import { Tooltip } from "antd";
import classNames from "classnames";
import "./MapButton.less";

export interface IMapButton {
    children: React.ReactNode;
    onClick: () => void;
    isDisabled?: boolean;
    tooltip?: string;
    active?: boolean;
    className?: string;
}

export default function MapButton({ children, onClick, isDisabled, tooltip, active, className }: IMapButton) {
    const button = (
        <button
            disabled={isDisabled}
            className={classNames(["custom-map-button", className, { active }])}
            onClick={onClick}
        >
            {children}
        </button>
    );
    return tooltip ? (
        <Tooltip placement="left" title={tooltip}>
            {button}
        </Tooltip>
    ) : (
        button
    );
}
