import React, { useMemo } from "react";
import { WaterwayQuotationsData, WaterwayType } from "../../Shared/useWaterways";
import WaterwayCell from "../../Shared/components/WaterwayList/WaterwayCell";
import QuotationIndicator from "../QuotationIndicator/QuotationIndicator";
import { ColumnsType } from "antd/lib/table";
import { InfoCircleOutlined } from "@ant-design/icons";

const numberFormat = new Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 });

export default function useWaterwayQuotationColumns(waterways: WaterwayQuotationsData[], type: WaterwayType) {
    return useMemo(() => {
        let zoneCounter = 0;
        let renderZoneCell = false;
        const columns: ColumnsType<WaterwayQuotationsData> = [
            {
                title: "Nom",
                dataIndex: "name",
                className: "first-col border-right",
                render: (name: string, { bounds }: WaterwayQuotationsData) => (
                    <WaterwayCell name={name} bounds={bounds} />
                ),
            },
            {
                title: "Cote du mois",
                dataIndex: "month-quotation-header border-right",
                children: [
                    {
                        title: "Cote",
                        dataIndex: "quotationCorrected",
                        className: "border-right-half bottom-group-col",
                        render: (quotationCorrected: number, { quotationModel }: WaterwayQuotationsData) => {
                            const quotation = quotationCorrected ?? quotationModel;

                            return numberFormat.format(quotation);
                        },
                    },
                    {
                        title: "PK",
                        dataIndex: "pkCorrected",
                        className: "border-right-half bottom-group-col",
                        render: (pkCorrected: number, { pk }: WaterwayQuotationsData) => {
                            // some pk are returning XX.X/YY.Y and we only want to display YY.Y
                            const pointKilometrique = String(pkCorrected ?? pk ?? "")
                                .split("/")
                                .pop();

                            return pointKilometrique;
                        },
                    },
                    {
                        title: "Statut",
                        dataIndex: "quotationCorrected",
                        key: "statut",
                        className: "quotation-col border-right bottom-group-col",
                        render: (
                            quotationCorrected: number,
                            { targetQuotation, quotationModel }: WaterwayQuotationsData,
                        ) => {
                            const quotation = quotationCorrected ?? quotationModel;

                            return (
                                <QuotationIndicator quotation={quotation} target={targetQuotation} warning={false} />
                            );
                        },
                    },
                ],
            },
            {
                title: (
                    <>
                        Évolution
                        <a
                            target="_blank"
                            href="https://giros-fleuve.jumeaux-numeriques.fr/documentation/modules/dragage/commission_des_seuils/index.html"
                            rel="noreferrer"
                            className="evolution-documentation-link"
                        >
                            <InfoCircleOutlined />
                        </a>
                    </>
                ),
                dataIndex: "evolution-header",
                children: [
                    {
                        title: "j+1",
                        dataIndex: "quotation1d",
                        className: "quotation-col bottom-group-col",
                        render: (quotation: number, { targetQuotation }: WaterwayQuotationsData) => (
                            <QuotationIndicator quotation={quotation} target={targetQuotation} />
                        ),
                    },
                    {
                        title: "j+30",
                        dataIndex: "quotation30d",
                        className: "quotation-col bottom-group-col",
                        render: (quotation: number, { targetQuotation }: WaterwayQuotationsData) => (
                            <QuotationIndicator quotation={quotation} target={targetQuotation} />
                        ),
                    },

                    {
                        title: "j+100",
                        dataIndex: "quotation100d",
                        className: "quotation-col bottom-group-col",
                        render: (quotation: number, { targetQuotation }: WaterwayQuotationsData) => (
                            <QuotationIndicator quotation={quotation} target={targetQuotation} />
                        ),
                    },
                ],
            },
        ];
        type === WaterwayType.FAIRWAY &&
            columns.unshift({
                title: "",
                dataIndex: "zone",
                key: "zone",
                className: "zone-col",
                onCell: (waterway: WaterwayQuotationsData, index) => {
                    if (index != null) {
                        // display a rowspan zone column that groups every fairway from the same zone
                        if (zoneCounter === 0 && waterways) {
                            const currentZone = waterway.zone;
                            let rowSpan = 0;
                            while (waterways[index]?.zone === currentZone) {
                                rowSpan++;
                                index++;
                            }
                            zoneCounter = rowSpan - 1;
                            renderZoneCell = true;
                            return { rowSpan };
                        } else {
                            renderZoneCell = false;
                            zoneCounter--;
                        }
                    }
                    return { className: "display-none" };
                },
                render: (zone: string) => (renderZoneCell ? <span>{zone}</span> : null),
            });
        return columns;
    }, [type, waterways]);
}
