import React from "react";

export const ToolboxIcon = () => {
    return (
        <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.0787249 20.8748L2.1712 15.3076H0.74206C0.468019 15.3076 0.245679 15.0972 0.245679 14.8379C0.245679 14.5787 0.468023 14.3685 0.74206 14.3685H2.52383L5.69866 5.92349C5.31946 5.45926 5.09342 4.87799 5.09342 4.24786C5.09342 2.89251 6.1363 1.76382 7.50313 1.5405L7.50331 0.469413C7.50366 0.210261 7.72601 0 8.00005 0C8.27444 0 8.49643 0.210265 8.49643 0.469413V1.5405C9.86307 1.76399 10.9061 2.89234 10.9061 4.24786C10.9061 4.87816 10.6801 5.45943 10.301 5.92349L13.4755 14.3689H15.2576C15.5317 14.3689 15.754 14.5792 15.754 14.8383C15.754 15.0975 15.5317 15.3081 15.2576 15.3081H13.8285L15.9211 20.8752C16.1145 21.3893 15.9404 21.9436 15.529 22.2813L15.5869 23.5096C15.5965 23.7155 15.4628 23.9037 15.2573 23.9728C15.0518 24.042 14.8225 23.9762 14.6924 23.8103L13.917 22.8232C13.3723 22.807 12.8674 22.4807 12.6743 21.9669L10.1711 15.3082H8.63826V16.6251C8.63826 16.8843 8.41627 17.0945 8.14188 17.0945H7.85793C7.58353 17.0945 7.36155 16.8842 7.36155 16.6251V15.3082H5.83044L3.32583 21.9669C3.13252 22.481 2.62765 22.807 2.08327 22.8232L1.30791 23.8103C1.17779 23.9758 0.948543 24.0418 0.742644 23.9728C0.537113 23.9037 0.403634 23.7158 0.41302 23.5096L0.471083 22.2813C0.0598478 21.9438 -0.114531 21.3892 0.0787916 20.8751L0.0787249 20.8748ZM8.76595 2.58981C7.87162 2.22017 6.83775 2.55147 6.35343 3.32708L9.84196 4.73583C10.0958 3.8807 9.65041 2.95508 8.76599 2.58981H8.76595ZM7.36141 14.3686V13.0518C7.36141 12.7926 7.58341 12.5824 7.8578 12.5824L8.1421 12.5822C8.41614 12.5822 8.63848 12.7925 8.63848 13.0516V14.3685H9.81836L8.00152 9.53548L6.18345 14.3685L7.36141 14.3686ZM7.2336 5.90566C8.12847 6.27547 9.1632 5.94383 9.64721 5.16736L6.15818 3.75861C5.90344 4.6139 6.34901 5.54017 7.2336 5.90566Z"
                fill="white"
            />
        </svg>
    );
};
