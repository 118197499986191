import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Input, Select, Button, Tooltip, Menu, message, Popover } from "antd";
import { CopyOutlined, RadiusBottomrightOutlined } from "@ant-design/icons";
import { changeMeasurementState } from "qwc2/actions/measurement";
import measureUtils from "qwc2/utils/MeasureUtils";
import displayCrsSelector from "qwc2/selectors/displaycrs";
import CoordinatesUtils from "qwc2/utils/CoordinatesUtils";
import { DistanceIcon } from "../../../Icons/Distance";
import { CoordinateIcon } from "../../../Icons/Coordinate";
import { ToolboxIcon } from "../../../Icons/ToolboxIcon";
import classNames from "classnames";
import "./ToolboxButton.less";
import { LayoutContext } from "../../../contexts/LayoutContextProvider";

const { Option } = Select;

enum ETool {
    LINE = "LINE",
    SURFACE = "SURFACE",
    COORDINATE = "COORDINATE",
}

export default function ToolboxButton() {
    const { measureState, mapcrs } = useSelector((state: any) => ({
        measureState: state.measurement,
        mapcrs: state.map.projection,
    }));
    const [openTool, setOpenTool] = useState<ETool | null>(null);
    const { setOnMapModal } = useContext(LayoutContext);
    const displaycrs = useSelector(displayCrsSelector);
    const dispatch = useDispatch();

    function displayClipBoardMessage() {
        message.info("Copié dans le presse papier");
    }

    function closeTool() {
        dispatch(changeMeasurementState({ geomType: null }));
        setOpenTool(null);
        setOnMapModal && setOnMapModal(null);
    }

    useEffect(() => {
        if (openTool !== ETool.LINE) {
            return;
        }

        const length = (measureState.length || []).reduce((tot: any, num: any) => tot + num, 0);
        const lineMeasurementValue = measureUtils.getFormattedLength(measureState.lenUnit, length)?.toFixed(2);

        setOnMapModal &&
            setOnMapModal({
                title: "Mesurer une distance",
                content: (
                    <Input.Group compact className="tool-input-group">
                        <Input style={{ textAlign: "center" }} value={lineMeasurementValue} />
                        <Select
                            defaultValue="m"
                            value={measureState.lenUnit}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={(value: string) =>
                                dispatch(changeMeasurementState({ ...measureState, lenUnit: value }))
                            }
                        >
                            <Option value="m">m</Option>
                            <Option value="ft">ft</Option>
                            <Option value="km">km</Option>
                            <Option value="mi">mi</Option>
                        </Select>
                        <Tooltip placement="top" title="Copier">
                            <Button
                                icon={<CopyOutlined />}
                                onClick={() => {
                                    navigator.clipboard.writeText(lineMeasurementValue);
                                    displayClipBoardMessage();
                                }}
                            />
                        </Tooltip>
                    </Input.Group>
                ),
                onClose: closeTool,
            });
    }, [openTool, measureState]);

    const openMeasureTool = () => {
        dispatch(changeMeasurementState({ geomType: "LineString" }));
        setOpenTool(ETool.LINE);
    };

    useEffect(() => {
        if (openTool !== ETool.SURFACE) {
            return;
        }

        const surfaceMeasurementValue = measureUtils
            .getFormattedArea(measureState.areaUnit, measureState.area)
            ?.toFixed(2);

        setOnMapModal &&
            setOnMapModal({
                title: "Mesurer une surface",
                content: (
                    <Input.Group compact className="tool-input-group">
                        <Input style={{ textAlign: "center" }} value={surfaceMeasurementValue} />
                        <Select
                            defaultValue="sqm"
                            value={measureState.areaUnit}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={(value: string) =>
                                dispatch(changeMeasurementState({ ...measureState, areaUnit: value }))
                            }
                        >
                            <Option value="sqm">m&#178;</Option>
                            <Option value="sqft">ft&#178;</Option>
                            <Option value="sqkm">km&#178;</Option>
                            <Option value="sqmi">mi&#178;</Option>
                            <Option value="ha">ha</Option>
                        </Select>
                        <Tooltip placement="top" title="Copier">
                            <Button
                                icon={<CopyOutlined />}
                                onClick={() => {
                                    navigator.clipboard.writeText(surfaceMeasurementValue);
                                    displayClipBoardMessage();
                                }}
                            />
                        </Tooltip>
                    </Input.Group>
                ),
                onClose: closeTool,
            });
    }, [openTool, measureState]);

    const openSurfaceTool = () => {
        dispatch(changeMeasurementState({ geomType: "Polygon" }));
        setOpenTool(ETool.SURFACE);
    };

    const coordinatesValue = useMemo(() => {
        let text = "0 0";
        if (measureState.geomType !== "Point") return text;

        const digits = CoordinatesUtils.getUnits(displaycrs) === "degrees" ? 4 : 0;
        if (!isEmpty(measureState.coordinates)) {
            const coo = CoordinatesUtils.reproject(measureState.coordinates, mapcrs, displaycrs);
            const units = CoordinatesUtils.getUnits(displaycrs);
            text = `${coo[0]?.toFixed(digits)} ${units} ${coo[1]?.toFixed(digits)} ${units}`;
        }
        return text;
    }, [measureState, mapcrs, displaycrs]);

    useEffect(() => {
        if (openTool !== ETool.COORDINATE) {
            return;
        }

        setOnMapModal &&
            setOnMapModal({
                title: "Afficher des coordonnées",
                content: (
                    <Input.Group compact style={{ display: "flex", justifyContent: "center" }}>
                        <Input style={{ textAlign: "center" }} value={coordinatesValue} />
                        <Tooltip placement="top" title="Copier">
                            <Button
                                icon={<CopyOutlined />}
                                onClick={() => {
                                    navigator.clipboard.writeText(coordinatesValue);
                                    displayClipBoardMessage();
                                }}
                            />
                        </Tooltip>
                    </Input.Group>
                ),
                onClose: closeTool,
            });
    }, [openTool, coordinatesValue]);

    const openCoordinateTool = () => {
        dispatch(changeMeasurementState({ geomType: "Point" }));
        setOpenTool(ETool.COORDINATE);
    };

    const ToolMenu = (
        <Menu className="tool-menu">
            <Menu.Item key={ETool.LINE} onClick={openMeasureTool} className="tool-menu-item">
                <DistanceIcon />
                <p style={{ display: "inline-block" }}>Mesure</p>
            </Menu.Item>
            <Menu.Item key={ETool.SURFACE} onClick={openSurfaceTool} className="tool-menu-item">
                <RadiusBottomrightOutlined />
                <p style={{ display: "inline-block" }}>Surface</p>
            </Menu.Item>
            <Menu.Item key={ETool.COORDINATE} onClick={openCoordinateTool} className="tool-menu-item">
                <CoordinateIcon />
                <p style={{ display: "inline-block" }}>Coordonnées</p>
            </Menu.Item>
        </Menu>
    );

    return (
        <Tooltip placement="topLeft" title="Boite à outils">
            <Popover
                content={ToolMenu}
                placement="leftBottom"
                trigger={["click", "hover"]}
                color="white"
                overlayClassName="tool-menu-popover"
            >
                <button className={classNames("custom-map-button", { active: Boolean(openTool) })}>
                    <ToolboxIcon />
                </button>
            </Popover>
        </Tooltip>
    );
}
