import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { IQWC2Layer } from "../../Interfaces/IQWC2Layer";
import { findLayer } from "../../utils/layer";
import { FormInstance } from "antd";
import { fetchSLF } from "../../utils/fetch";
import useFILTER from "../../useFILTER";

export default function useFilteringPanel(WMSLayer?: IQWC2Layer, projectId?: number) {
    const [isLoading, setIsLoading] = useState(false);
    const [layerFilterMinMaxList, setLayerFilterMinMaxList] = useState<ILayerFilterMinMax[]>([]);
    const { setFilterKeys, removeFilterKeys, getFilterValueFromKey } = useFILTER();
    const [filteredKeys, setFilteredKeys] = useState<string[]>([]);
    const [initializedProject, setInitializedProject] = useState<number>();
    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                setIsLoading(true);
                const layerFilterMinMaxList = await fetchSLF(`model/layers/${projectId}`);
                setLayerFilterMinMaxList(
                    layerFilterMinMaxList.map(({ layer, property, min, max, type }: ILayerFilterMinMax) => ({
                        layer,
                        property,
                        min: Math.floor(min),
                        max: Math.ceil(max),
                        type,
                    })),
                );
                setInitializedProject(projectId);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        if (!isLoading && projectId !== undefined && projectId !== initializedProject) {
            fetchFilterData();
        }
    }, [isLoading, WMSLayer, getFilterValueFromKey, projectId, initializedProject]);

    const visibleFilterList = useMemo<ILayerFilterMinMax[]>(
        () =>
            WMSLayer
                ? layerFilterMinMaxList
                      .map(
                          (layerFilterMinMax) =>
                              [layerFilterMinMax, findLayer(layerFilterMinMax.layer, WMSLayer)] as [
                                  ILayerFilterMinMax,
                                  IQWC2Layer,
                              ],
                      )
                      .filter(([, layer]) => layer && layer.visibility)
                      .map(([layerFilterMinMax, { title }]) => ({
                          ...layerFilterMinMax,
                          title,
                      }))
                : [],
        [WMSLayer, layerFilterMinMaxList],
    );

    const initialValues = useMemo(
        () =>
            visibleFilterList?.reduce(
                (values, { layer, min, max }) => ({
                    ...values,
                    [layer]: [min, max],
                }),
                {},
            ),
        [visibleFilterList],
    );

    const filterLayers = useCallback(
        (values) => {
            setFilterKeys(
                Object.keys(values)
                    .map((layerName) => {
                        const layerProperty = visibleFilterList.find(({ layer }) => layer === layerName)?.property;
                        const [min, max] = values[layerName] ?? [];
                        return { min, max, layerProperty, layerName };
                    })
                    .filter(({ min, max }) => min != null || max != null)
                    .map(({ min, max, layerProperty, layerName }) => {
                        const filter = [];
                        if (min != null) {
                            filter.push(`"${layerProperty}" > '${min}'`);
                        }
                        if (max != null) {
                            filter.push(`"${layerProperty}" < '${max}'`);
                        }
                        if (projectId !== undefined) {
                            filter.push(`"project_id" = '${projectId}'`);
                        }
                        return [layerName, filter.join(" AND ")];
                    }),
            );
            if (JSON.stringify(initialValues) === JSON.stringify(values)) {
                setFilteredKeys([]);
            } else {
                setFilteredKeys(Object.keys(values));
            }
        },
        [setFilterKeys, visibleFilterList, projectId, initialValues],
    );

    const canFilter = visibleFilterList.length > 0;

    const formRef = useRef<FormInstance>(null);

    const resetFilters = useCallback(() => {
        removeFilterKeys(filteredKeys);
        setFilteredKeys([]);
        formRef.current?.resetFields();
    }, [filteredKeys, removeFilterKeys]);

    return { visibleFilterList, filterLayers, canFilter, resetFilters, filtered: filteredKeys.length > 0, formRef };
}
export interface ILayerFilterMinMax {
    layer: string;
    property: string;
    min: number;
    max: number;
    type?: LayerLevel;
}

export enum LayerLevel {
    TOP = "surface",
    BOTTOM = "fond",
}
