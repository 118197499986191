import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ZoomOutOutlined } from "@ant-design/icons";
import { changeZoomLevel } from "qwc2/actions/map";
import MapButton from "../MapButton";

export default function ZoomOutButton() {
    const { currentZoom } = useSelector((state: any) => ({
        currentZoom: state.map.zoom,
    }));
    const dispatch = useDispatch();

    function ZoomIn() {
        dispatch(changeZoomLevel(currentZoom - 1));
    }

    const isDisabled = currentZoom <= 0;

    return (
        <MapButton onClick={ZoomIn} isDisabled={isDisabled} tooltip="Zoom arrière">
            <ZoomOutOutlined />
        </MapButton>
    );
}
