import { useCallback, useEffect, useRef, useState } from "react";
import { WaterwayType } from "../Shared/useWaterways";
import { FetchMethods, fetchSLF } from "../Shared/utils/fetch";
import { IFairwaysPassingTimes, ITerminalsArrivalTimes } from "./useEstimatedPassingTime";
import { FairwayNavigabilityData } from "./useFairwayNavigabilityColumns";
import { TerminalNavigabilityData } from "./useTerminalNavigabilityColumns";

interface IUseNavigabilityIndicators {
    fairwaysWithPassingTime: IFairwaysPassingTimes[];
    terminalsWithArrivalTime: ITerminalsArrivalTimes[];
}

interface IFairwayIndicatorDTO {
    nom_passe: string;
    risque_de_squat: number;
    resistance_avancement: number;
    bathymetrie: number;
    vitesse_courant: number;
    turbidite: number;
    x_min: number;
    y_min: number;
    x_max: number;
    y_max: number;
}

interface ITerminalIndicatorDTO {
    num_poste_a_quai: number;
    risque_de_squat: number;
    tenue_des_amarres: number;
    bathymetrie: number;
    risque_de_submersion: number;
    vitesse_courant: number;
    turbidite: number;
    x_min: number;
    y_min: number;
    x_max: number;
    y_max: number;
}

export const useNavigabilityIndicators = (passingTimes: IUseNavigabilityIndicators) => {
    const [fairwaysWithIndicators, setFairwaysWithIndicators] = useState<FairwayNavigabilityData[]>([]);
    const [terminalsWithIndicators, setTerminalsWithIndicators] = useState<TerminalNavigabilityData[]>([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const errorTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const getFairwaysIndicators = useCallback(
        (fairwaysWithPassingTime: IFairwaysPassingTimes[]): Promise<FairwayNavigabilityData[][]> => {
            return Promise.all(
                fairwaysWithPassingTime.map(async ({ caracteristiques_navire, heures_de_passage }) => {
                    const indicators: { data: Array<IFairwayIndicatorDTO> } = await fetchSLF(`navigabilite/forecast`, {
                        headers: {
                            "qwc2-token": process.env.REACT_APP_QWC2_TOKEN || "",
                        },
                        method: FetchMethods.POST,
                        content: {
                            layer: "passes",
                            caracteristiques_navire,
                            heures_de_passage: heures_de_passage.map(({ nom_passe, date }) => ({ nom_passe, date })),
                        },
                    });

                    return heures_de_passage.map(({ nom_passe, timestamp }, index) => {
                        const fairwayIndicator = indicators.data.find(
                            ({ nom_passe: _nom_passe }) => _nom_passe === nom_passe,
                        );

                        return {
                            key: new Date(timestamp).getTime() * (index + 1),
                            name: nom_passe,
                            type: WaterwayType.FAIRWAY,
                            timestamp,
                            ordre: index,
                            bounds: [
                                fairwayIndicator?.x_min,
                                fairwayIndicator?.y_min,
                                fairwayIndicator?.x_max,
                                fairwayIndicator?.y_max,
                            ],
                            resistance: fairwayIndicator?.resistance_avancement,
                            squat: fairwayIndicator?.risque_de_squat,
                            bathymetrie: fairwayIndicator?.bathymetrie,
                            first: index === 0,
                            turbidite: fairwayIndicator?.turbidite,
                            swell: fairwayIndicator?.vitesse_courant,
                        } as FairwayNavigabilityData;
                    });
                }),
            );
        },
        [],
    );

    const getTerminalsIndicators = useCallback(
        (terminalsWithArrivalTime: ITerminalsArrivalTimes[]): Promise<TerminalNavigabilityData[][]> => {
            return Promise.all(
                terminalsWithArrivalTime.map(async ({ caracteristiques_navire, heures_de_passage }) => {
                    const indicators: { data: Array<ITerminalIndicatorDTO> } = await fetchSLF(`navigabilite/forecast`, {
                        headers: {
                            "qwc2-token": process.env.REACT_APP_QWC2_TOKEN || "",
                        },
                        method: FetchMethods.POST,
                        content: {
                            layer: "postes_a_quais",
                            caracteristiques_navire,
                            heures_de_passage: heures_de_passage.map(({ num_poste_a_quai, date }) => ({
                                num_poste_a_quai,
                                date,
                            })),
                        },
                    });

                    return heures_de_passage.map(({ num_poste_a_quai, timestamp }, index) => {
                        const terminalIndicator = indicators.data.find(
                            ({ num_poste_a_quai: _num_poste_a_quai }) => _num_poste_a_quai === num_poste_a_quai,
                        );

                        return {
                            key: num_poste_a_quai,
                            name: `P. ${num_poste_a_quai}`,
                            type: WaterwayType.TERMINAL,
                            timestamp,
                            ordre: index,
                            bounds: [
                                terminalIndicator?.x_min,
                                terminalIndicator?.y_min,
                                terminalIndicator?.x_max,
                                terminalIndicator?.y_max,
                            ],
                            tenueAmarres: terminalIndicator?.tenue_des_amarres,
                            submersion: terminalIndicator?.risque_de_submersion,
                            bathymetrie: terminalIndicator?.bathymetrie,
                            turbidite: terminalIndicator?.turbidite,
                            squat: terminalIndicator?.risque_de_squat,
                            swell: terminalIndicator?.vitesse_courant,
                        } as TerminalNavigabilityData;
                    });
                }),
            );
        },
        [],
    );

    useEffect(() => {
        const updateIndicators = async () => {
            const { fairwaysWithPassingTime, terminalsWithArrivalTime } = passingTimes;
            setIsLoading(true);
            try {
                const fairwaysWithIndicators = await getFairwaysIndicators(fairwaysWithPassingTime);
                setFairwaysWithIndicators(fairwaysWithIndicators.flat());
                const terminalsWithIndicators = await getTerminalsIndicators(terminalsWithArrivalTime);
                setTerminalsWithIndicators(terminalsWithIndicators.flat());
            } catch (err) {
                console.error(err);
                setHasError(true);
                if (errorTimeoutRef.current) {
                    clearTimeout(errorTimeoutRef.current);
                }
                errorTimeoutRef.current = setTimeout(() => {
                    setHasError(false);
                }, 5000);
            } finally {
                setIsLoading(false);
            }
        };

        updateIndicators();
    }, [passingTimes, getFairwaysIndicators, getTerminalsIndicators]);

    return {
        fairwaysWithIndicators,
        terminalsWithIndicators,
        isLoading,
        hasError,
    };
};
