import React, { createContext, useEffect, useState } from "react";
import { IModule } from "./Shared/Interfaces/IModule";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakPromise } from "keycloak-js";
import moduleList from "./moduleList";
import { EPermission } from "./Shared/Enums/EPermissions";
import { getKeycloakClientId } from "./keycloak";

export interface IUser {
    familyName: string;
    givenName: string;
    email: string;
    modules: IModule[];
    permissions: EPermission[];
}

export interface IAuthToken {
    email: string;
    family_name: string;
    given_name: string;
    resource_access: { [clientId: string]: { roles: EPermission[] } };
}

export interface IUserContext {
    user: IUser | null | undefined;
    login: () => KeycloakPromise<void, void>;
    logout: () => KeycloakPromise<void, void>;
}

const UserContext = createContext<IUserContext | null>(null);

export function UserProvider({ children }: { children: JSX.Element }) {
    const { keycloak, initialized } = useKeycloak();
    const [user, setUser] = useState<IUser | null>();
    const getUserFromParsedToken = ({ family_name, given_name, email, resource_access }: IAuthToken): IUser => {
        return {
            familyName: family_name,
            givenName: given_name,
            email,
            modules: moduleList.filter((module: IModule) => {
                const userPermissions = resource_access[getKeycloakClientId()]?.roles || [];

                if (!module.permissions) {
                    return true;
                } else {
                    return module.permissions.some((modulePermission: EPermission) =>
                        userPermissions.includes(modulePermission),
                    );
                }
            }),
            permissions: resource_access[getKeycloakClientId()]?.roles || [],
        };
    };

    useEffect(() => {
        if (!initialized) {
            return;
        }

        if (keycloak.authenticated) {
            keycloak.tokenParsed && setUser(getUserFromParsedToken(keycloak.tokenParsed as IAuthToken));
        } else {
            setUser(null);
        }
    }, [keycloak, initialized]);

    return (
        <UserContext.Provider
            value={{
                user,
                login: () =>
                    keycloak.login({
                        redirectUri: window.location.href,
                    }),
                logout: () => keycloak.logout({ redirectUri: window.location.href }),
            }}
        >
            {user === undefined ? null : children}
        </UserContext.Provider>
    );
}

export default UserContext;
