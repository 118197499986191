import React, { useContext } from "react";

import ModuleTopBar from "../../Shared/components/ModuleTopBar";

import DredgingLeftBar from "../DredgingLeftBar/DredgingLeftBar";
import { InfoPanelPlugin } from "../../Shared/components/InfoPanelPlugin/InfoPanelPlugin";
import { ButtonsPlugin } from "../../Shared/components/ButtonsPlugin/ButtonsPlugin";
import { ThresholdCommissionBottomPanelPlugin } from "./ThresholdCommissionBottomPanelPlugin/ThresholdCommissionBottomPanelPlugin";
import { LayoutContext } from "../../Shared/contexts/LayoutContextProvider";
import { MapModal } from "../../Shared/components/MapModal/MapModal";
import { AlertSettingsButton } from "./AlertSettingsButton";
import { AlertLogButton } from "./AlertLogButton/AlertLogButton";
import useTideGauge from "./useTideGauge";
import useWaterways from "../../Shared/useWaterways";
import EvolutionButtons from "../EvolutionButtons/EvolutionButtons";
import { ThresholdCommissionLeftBar } from "./ThresholdCommissionLeftBar/ThresholdCommissionLeftBar";
import { IDredgingModuleLayout } from "..";
import { ZhRefSettingsButton } from "./ZhRefSettingsButton";
import Map from "../../Shared/components/Map";

export const ThresholdCommissionLayout = ({ onChangeCurrentModule, currentModule }: IDredgingModuleLayout) => {
    const { onMapModal, setOnMapModal } = useContext(LayoutContext);
    const { fairways, defiles, isLoading: isWaterwaysLoading, updateWaterways } = useWaterways();
    const { isLoading: isTideGaugeLoading, tideGauges, updateTideGauges } = useTideGauge();

    return (
        <div className="wrapper plugin-container flex column">
            <ModuleTopBar
                customButtons={
                    <>
                        <AlertSettingsButton
                            fairways={fairways}
                            defiles={defiles}
                            isWaterwaysLoading={isWaterwaysLoading}
                            updateWaterways={updateWaterways}
                            displayModal={setOnMapModal}
                        />
                        <AlertLogButton />
                        <ZhRefSettingsButton
                            disabled={isTideGaugeLoading}
                            tideGauges={tideGauges}
                            updateTideGauges={updateTideGauges}
                            displayModal={setOnMapModal}
                        />
                    </>
                }
            />
            <div className="plugin-container flex row grow">
                <DredgingLeftBar onChangeCurrentModule={onChangeCurrentModule} currentModule={currentModule}>
                    <ThresholdCommissionLeftBar fairways={fairways} defiles={defiles} isLoading={isWaterwaysLoading} />
                </DredgingLeftBar>
                <div className="plugin-container flex column grow overflow">
                    <div className="plugin-container grow wrapper flex flex-end">
                        <Map />
                        <ButtonsPlugin toolbox info />
                        <EvolutionButtons />
                        {onMapModal && <MapModal {...onMapModal} />}
                        <InfoPanelPlugin />
                    </div>
                    <ThresholdCommissionBottomPanelPlugin />
                </div>
            </div>
        </div>
    );
};
