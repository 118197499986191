import React, { useState } from "react";
import { EFairwayType, EPrefectoralDecreeAdditionalInformationOperator, IPrefectoralDecree } from "../types";
import { Form, Select, Input, Modal, DatePicker, Button } from "antd";
import moment, { Moment } from "moment";
import { EProgrammedState } from "../../../../Dredging/AnnualStrategy/types";
import { planningLegend } from "../../../../Dredging/AnnualStrategy/planning.legend";
import { v4 } from "uuid";
import "./CreatePrefectoralDecree.less";

const { Option } = Select;

interface ICreatePrefectoralDecree {
    from: Date;
    prefectoralDecree?: IPrefectoralDecree;
    onCreate: (prefectoralDecree: IPrefectoralDecree, isUpdate: boolean) => void;
    onClose: () => void;
}

export const CreatePrefectoralDecree = ({
    onCreate,
    onClose,
    from,
    prefectoralDecree: prefectoralDecreeInit,
}: ICreatePrefectoralDecree) => {
    const [form] = Form.useForm();
    const [prefectoralDecree, setPrefectoralDecree] = useState<IPrefectoralDecree>(
        prefectoralDecreeInit
            ? {
                  ...prefectoralDecreeInit,
                  applicationDate: moment(prefectoralDecreeInit.applicationDate),
                  endDate: moment(prefectoralDecreeInit.endDate),
              }
            : {
                  id: v4(),
                  code: "",
                  applicationDate: moment(from),
                  endDate: moment(from).add(10, "years"),
                  timeranges: [
                      {
                          from: null,
                          to: null,
                          fairwayType: null,
                          activity: null,
                      },
                  ],
                  additionalInformation: {
                      debitValue: {
                          operator: null,
                          value: null,
                          fairwayType: null,
                      },
                      o2dissousValue: {
                          operator: null,
                          value: null,
                          fairwayType: null,
                      },
                  },
              },
    );

    const onSubmit = async () => {
        try {
            await form.validateFields();
            onCreate(
                {
                    ...prefectoralDecree,
                    applicationDate: (prefectoralDecree.applicationDate as Moment).toISOString(),
                    endDate: (prefectoralDecree.endDate as Moment).toISOString(),
                },
                !!prefectoralDecreeInit,
            );
            onClose();
        } catch (err) {
            console.error(err);
        }
    };

    const onEditForm = (key: keyof IPrefectoralDecree, value: Moment | string | null) => {
        setPrefectoralDecree((oldPrefectoralDecree) => ({
            ...oldPrefectoralDecree,
            [key]: value,
        }));
    };

    const onEditTimerange = (index: number, key: keyof IPrefectoralDecree["timeranges"][0], value: any) => {
        setPrefectoralDecree((oldPrefectoralDecree) => {
            const newTimeranges = [...oldPrefectoralDecree.timeranges] as IPrefectoralDecree["timeranges"];
            newTimeranges[index][key] = value;

            return {
                ...oldPrefectoralDecree,
                timeranges: newTimeranges,
            };
        });
    };

    const onEditAdditionalInformation = (
        key: "debitValue" | "o2dissousValue",
        value: {
            operator: EPrefectoralDecreeAdditionalInformationOperator | null;
            value: number | null;
            fairwayType: EFairwayType | null;
        },
    ) => {
        setPrefectoralDecree((oldPrefectoralDecree) => ({
            ...oldPrefectoralDecree,
            additionalInformation: {
                ...oldPrefectoralDecree.additionalInformation,
                [key]: value,
            },
        }));
    };

    const onCreateTimeRange = () => {
        setPrefectoralDecree((oldPrefectoralDecree) => ({
            ...oldPrefectoralDecree,
            timeranges: [
                ...oldPrefectoralDecree.timeranges,
                {
                    from: null,
                    to: null,
                    fairwayType: null,
                    activity: null,
                },
            ],
        }));
    };

    const onDeleteTimeRange = () => {
        setPrefectoralDecree((oldPrefectoralDecree) => {
            const newTimeranges = [...oldPrefectoralDecree.timeranges] as IPrefectoralDecree["timeranges"];
            newTimeranges.splice(newTimeranges.length - 1, 1);
            return {
                ...oldPrefectoralDecree,
                timeranges: newTimeranges,
            };
        });
    };

    const checkFormat = (_: any, value: string) => {
        if (/^[0-3]{1}[0-9]{1}\/[0-1]{1}[0-9]{1}$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject("La plage de temps doit être jj/mm");
    };

    return (
        <Modal
            className="create-prefectoral-decree-modale"
            title="Nouvel arrêté préfectoral"
            open
            onCancel={onClose}
            okButtonProps={{ htmlType: "submit", type: "primary" }}
            onOk={onSubmit}
            okText="Ajouter"
        >
            <Form form={form} initialValues={prefectoralDecree}>
                <h1>Informations générales</h1>
                <Form.Item name="code" label="Code" rules={[{ required: true }]}>
                    <Input
                        value={prefectoralDecree.code}
                        onChange={(event) => onEditForm("code", event.target.value)}
                    />
                </Form.Item>
                <div className="form-block">
                    <Form.Item name="applicationDate" label="Date d'application" rules={[{ required: true }]}>
                        <DatePicker
                            format={"DD-MM-YYYY"}
                            value={moment(prefectoralDecree.applicationDate)}
                            onChange={(date) => onEditForm("applicationDate", date)}
                        />
                    </Form.Item>
                    <Form.Item name="endDate" label="Date de fin" rules={[{ required: true }]}>
                        <DatePicker
                            format={"DD-MM-YYYY"}
                            value={moment(prefectoralDecree.endDate)}
                            onChange={(date) => onEditForm("endDate", date)}
                        />
                    </Form.Item>
                </div>
                <h1>Description</h1>
                <h2>Plages de temps</h2>
                {prefectoralDecree.timeranges.map((timerange, index) => (
                    <React.Fragment key={index}>
                        <div className="form-block">
                            <Form.Item
                                name={`fairwayType-${index}`}
                                label="Type de passe"
                                rules={[{ required: true }]}
                                initialValue={timerange.fairwayType}
                            >
                                <Select onChange={(value) => onEditTimerange(index, "fairwayType", value)}>
                                    {Object.values(EFairwayType).map((fairwayType) => (
                                        <Option key={fairwayType} value={fairwayType}>
                                            {fairwayType}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={`activity-${index}`}
                                label="Statut"
                                rules={[{ required: true }]}
                                initialValue={timerange.activity}
                            >
                                <Select onChange={(value) => onEditTimerange(index, "activity", value)}>
                                    {planningLegend
                                        .filter(({ value }) =>
                                            [EProgrammedState.FORBIDDEN, EProgrammedState.TO_AVOID].includes(value),
                                        )
                                        .map(({ value, label }) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="form-block">
                            <Form.Item
                                name={`from-${index}`}
                                label="Début"
                                rules={[{ required: true, validator: checkFormat }]}
                                initialValue={timerange.from}
                            >
                                <Input onChange={(event) => onEditTimerange(index, "from", event.target.value)} />
                            </Form.Item>
                            <Form.Item
                                name={`to-${index}`}
                                label="Fin"
                                rules={[{ required: true, validator: checkFormat }]}
                                initialValue={timerange.to}
                            >
                                <Input onChange={(event) => onEditTimerange(index, "to", event.target.value)} />
                            </Form.Item>
                        </div>
                    </React.Fragment>
                ))}
                <div className="form-block add-timerange-button">
                    <Button onClick={onCreateTimeRange}>Ajouter une plage de temps</Button>
                    <Button onClick={onDeleteTimeRange} disabled={prefectoralDecree.timeranges.length <= 1}>
                        Supprimer une plage de temps
                    </Button>
                </div>

                <h2>Informations additionnelles</h2>
                <div className="form-block">
                    <label>Débit (m3/s) à respecter</label>
                    <Form.Item
                        name="additionalInformationDebitOperator"
                        initialValue={prefectoralDecree.additionalInformation.debitValue.operator}
                    >
                        <Select
                            className="operator-select"
                            allowClear
                            onChange={(value) =>
                                onEditAdditionalInformation("debitValue", {
                                    operator: value,
                                    value: prefectoralDecree.additionalInformation?.debitValue.value || null,
                                    fairwayType:
                                        prefectoralDecree.additionalInformation?.debitValue.fairwayType || null,
                                })
                            }
                        >
                            {Object.values(EPrefectoralDecreeAdditionalInformationOperator).map((operator) => (
                                <Option key={operator} value={operator}>
                                    {operator}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="additionalInformationDebitValue"
                        initialValue={prefectoralDecree.additionalInformation.debitValue.value}
                    >
                        <Input
                            type="number"
                            onChange={(event) =>
                                onEditAdditionalInformation("debitValue", {
                                    operator: prefectoralDecree.additionalInformation?.debitValue.operator || null,
                                    fairwayType:
                                        prefectoralDecree.additionalInformation?.debitValue.fairwayType || null,
                                    value: parseInt(event.target.value) || null,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="additionalInformationDebitFairwayType"
                        initialValue={prefectoralDecree.additionalInformation.debitValue.fairwayType}
                    >
                        <Select
                            className="fairway-select"
                            onChange={(value) =>
                                onEditAdditionalInformation("debitValue", {
                                    value: prefectoralDecree.additionalInformation?.debitValue.value || null,
                                    operator: prefectoralDecree.additionalInformation?.debitValue.operator || null,
                                    fairwayType: value,
                                })
                            }
                        >
                            {Object.values(EFairwayType).map((fairwayType) => (
                                <Option key={fairwayType} value={fairwayType}>
                                    {fairwayType}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="form-block">
                    <label>Oxygène dissous (mg/l) à respecter</label>
                    <Form.Item
                        name="additionalInformationO2DissousOperator"
                        initialValue={prefectoralDecree.additionalInformation.o2dissousValue.operator}
                    >
                        <Select
                            className="operator-select"
                            allowClear
                            onChange={(value) =>
                                onEditAdditionalInformation("o2dissousValue", {
                                    operator: value,
                                    value: prefectoralDecree.additionalInformation?.o2dissousValue.value || null,
                                    fairwayType:
                                        prefectoralDecree.additionalInformation?.o2dissousValue.fairwayType || null,
                                })
                            }
                        >
                            {Object.values(EPrefectoralDecreeAdditionalInformationOperator).map((operator) => (
                                <Option key={operator} value={operator}>
                                    {operator}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="additionalInformationO2DissousValue"
                        initialValue={prefectoralDecree.additionalInformation.o2dissousValue.value}
                    >
                        <Input
                            type="number"
                            onChange={(event) =>
                                onEditAdditionalInformation("o2dissousValue", {
                                    operator: prefectoralDecree.additionalInformation?.o2dissousValue.operator || null,
                                    fairwayType:
                                        prefectoralDecree.additionalInformation?.o2dissousValue.fairwayType || null,
                                    value: parseInt(event.target.value) || null,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="additionalInformationO2DissousFairwayType"
                        initialValue={prefectoralDecree.additionalInformation.o2dissousValue.fairwayType}
                    >
                        <Select
                            className="fairway-select"
                            onChange={(value) =>
                                onEditAdditionalInformation("o2dissousValue", {
                                    value: prefectoralDecree.additionalInformation?.o2dissousValue.value || null,
                                    operator: prefectoralDecree.additionalInformation?.o2dissousValue.operator || null,
                                    fairwayType: value,
                                })
                            }
                        >
                            {Object.values(EFairwayType).map((fairwayType) => (
                                <Option key={fairwayType} value={fairwayType}>
                                    {fairwayType}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};
