import React from "react";
import FullScreenButton from "../MapButton/buttons/FullScreenButton";
import MaxZoomOutButton from "../MapButton/buttons/MaxZoomOutButton";
import ToolboxButton from "../MapButton/buttons/ToolboxButton";
import ZoomInButton from "../MapButton/buttons/ZoomInButton";
import ZoomOutButton from "../MapButton/buttons/ZoomOutButton";
import InfoBubbleButton from "../MapButton/buttons/InfoBubbleButton";
import "./ButtonsPlugin.less";
import ZoneZoomButton from "../MapButton/buttons/ZoneZoomButton";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

export const ButtonsPlugin = ({ toolbox, info }: { toolbox?: boolean; info?: boolean }) => {
    return (
        <div className="buttons-plugin-wrapper">
            <ButtonGroup>
                {toolbox && <ToolboxButton />}
                {info && <InfoBubbleButton />}
            </ButtonGroup>

            <ButtonGroup>
                <FullScreenButton />
                <MaxZoomOutButton />
                <ZoomInButton />
                <ZoomOutButton />
                <ZoneZoomButton />
            </ButtonGroup>
        </div>
    );
};
