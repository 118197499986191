import { useState, useEffect, useMemo } from "react";
import { getAllLayersNames } from "../../utils/layer";
import fetchWMS, { WMSRequest } from "../../WMSApiService";
import { useWMSLayer } from "../../useWMSLayer";
import { findLayer, getLayerFromPath, getLayerPath } from "../../utils/layer";
import { ILegendData, ELegendType } from "./Legends";
import { ILegendGradient } from "./components/LegendGradient";
import { ILegendIcon } from "./components/LegendIcons";

interface IUseLegendFromMapParams {
    isOpen: boolean;
}
interface ILegendDTO {
    icon?: string;
    symbols?: {
        icon: string;
        title: string;
    }[];
    title: string;
    layer: string;
}

export const useLegendFromMap = ({ isOpen }: IUseLegendFromMapParams) => {
    const WMSLayer = useWMSLayer();
    const [isLoading, setIsLoading] = useState(false);
    const [legendData, setLegendData] = useState<null | Record<string, ILegendGradient | ILegendIcon>>(null);

    useEffect(() => {
        if (isOpen && !isLoading && legendData == null && WMSLayer) {
            setIsLoading(true);
            const fetchLegendData = async (baseUrl: string) => {
                try {
                    const allLayers = getAllLayersNames(WMSLayer);
                    await Promise.all(
                        allLayers.map(async (layer) => {
                            const response: { nodes: ILegendDTO[] } = await fetchWMS({
                                baseUrl,
                                request: {
                                    name: WMSRequest.GET_LEGEND_GRAPHIC,
                                    options: {
                                        FORMAT: "application/json",
                                        LAYER: layer,
                                    },
                                },
                            });

                            const firstNode = response.nodes
                                .filter(({ symbols, icon }) => Boolean(symbols) || Boolean(icon))
                                .map((legend) => ({ ...legend, layer }))
                                .find(() => true);

                            if (firstNode) {
                                if (firstNode.symbols) {
                                    const legend: ILegendGradient = {
                                        symbols: firstNode.symbols,
                                        title: firstNode.title,
                                        type: ELegendType.GRADIENT,
                                    };
                                    setLegendData((legendData) => ({
                                        ...legendData,
                                        [layer]: legend,
                                    }));
                                } else if (firstNode.icon) {
                                    const legend: ILegendIcon = {
                                        icon: firstNode.icon,
                                        title: firstNode.title,
                                        type: ELegendType.ICON,
                                    };
                                    setLegendData((legendData) => ({
                                        ...legendData,
                                        [layer]: legend,
                                    }));
                                }
                            }
                        }),
                    );
                } catch (err) {
                    console.error(`An error occured while fetching legend data : ${err}`);
                    setLegendData(null);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchLegendData(WMSLayer.url);
        }
    }, [WMSLayer, isLoading, isOpen, legendData]);

    const legendGroups = useMemo(
        () =>
            legendData && WMSLayer
                ? Object.keys(legendData)
                      // First filter to display only the legends for the visible layers
                      .filter((layerName) => findLayer(layerName, WMSLayer)?.visibility)
                      // Second filter to avoid showing two times the same legend
                      .filter((layerName, index, array) => {
                          const curlegend = legendData[layerName];
                          const prevLayerNames = array.slice(0, index);
                          const legendAlreadyExists = prevLayerNames.find(
                              (prevLayerName) => legendData[prevLayerName].title.trim() === curlegend.title.trim(),
                          );

                          return !legendAlreadyExists;
                      })
                      // Group the layers by top groups
                      .reduce((acc: Record<string, Array<ILegendData>>, layerName) => {
                          const layerPath = getLayerPath(layerName, WMSLayer, []);
                          if (layerPath !== null) {
                              const layerRootGroup = getLayerFromPath(layerPath.slice(0, 1), WMSLayer);
                              if (layerRootGroup !== null) {
                                  if (!acc[layerRootGroup.title]) {
                                      acc[layerRootGroup.title] = [];
                                  }
                                  acc[layerRootGroup.title].push({ ...legendData[layerName] });
                              }
                          }

                          return acc;
                      }, {})
                : undefined,
        [legendData, WMSLayer],
    );

    return {
        isLoading,
        legendGroups,
    };
};
