import { useContext, useMemo } from "react";
import { useMatches } from "react-router-dom";
import UserContext, { IUserContext } from "../UserContext";
import { IModule } from "./Interfaces/IModule";
import { default as globalModuleList } from "../moduleList";

export default function useCurrentModule(): [IModule | undefined, IModule[]] {
    const matches = useMatches();
    const { user } = useContext(UserContext) as IUserContext;
    const moduleList = user ? user.modules : globalModuleList;

    const currentModule = useMemo(
        () => moduleList.find(({ path }) => matches.find(({ pathname }) => pathname === path)),
        [moduleList, matches],
    );
    const otherModules = useMemo(
        () =>
            moduleList.filter(
                (module) =>
                    module !== currentModule &&
                    module.path &&
                    ((user && user.modules.find(({ id }) => id === module.id)) || !module.permissions),
            ) ?? [],
        [user, moduleList, currentModule],
    );
    return [currentModule, otherModules];
}
