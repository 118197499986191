import { ExportOutlined, GitlabOutlined, LoadingOutlined, PullRequestOutlined } from "@ant-design/icons";
import { Alert, Button, DatePicker, Form, Timeline } from "antd";
import classNames from "classnames";
import React, { useCallback, useContext, useState } from "react";
import { fetchGitLab, FetchMethods, fetchSimulation } from "../../../utils/fetch";
import { IStudy } from "../LayersMenuDataPanel";
import moment from "moment";
import "./DataStudyEditSteps.less";
import UserContext, { IUserContext } from "../../../../UserContext";
import useJupyterUrl from "../useJupyterUrl";

export default function DataStudyEditSteps({ study, onCancel }: { study?: IStudy; onCancel: () => void }) {
    const [calculationLoading, setCalculationLoading] = useState(false);
    const [generateLoading, setGenerateLoading] = useState(false);
    const [canceling, setCanceling] = useState(false);
    const globalDisable = !study || canceling;
    const { user } = useContext(UserContext) as IUserContext;
    const jupyterUrl = useJupyterUrl(study?.id);
    const launchCalculations = useCallback(async () => {
        if (study) {
            setCalculationLoading(true);
            try {
                await fetchGitLab(`projects/${study.id}/merge_requests`, FetchMethods.POST, {
                    source_branch: study.default_branch,
                    target_branch: study.default_branch,
                    title: study.name,
                    target_project_id: study.fromModelId,
                    description: `run requested by ${user?.email}`,
                });
            } catch (err) {
                console.error(err);
            }
        }
    }, [study, user]);

    const cancel = useCallback(async () => {
        setCanceling(true);
        try {
            await fetchGitLab(`projects/${study?.id}`, FetchMethods.DELETE);
        } catch (err) {
            console.error(err);
        } finally {
            setCanceling(false);
            setGenerated(false);
            setGitlabOpened(false);
            setCalculationLoading(false);
            onCancel();
        }
    }, [onCancel, study?.id]);
    const [generated, setGenerated] = useState(false);
    const [gitlabOpened, setGitlabOpened] = useState(false);
    return (
        <div className={classNames("data-study-edit-steps", { visible: study })}>
            <div className="new-study-name">
                <h4>Nouvelle étude : {study?.name}</h4>
                <div>Crée à partir de : {study?.fromModelName}</div>
            </div>
            <Timeline>
                <Timeline.Item
                    color="#484964"
                    dot={<div className={classNames({ done: generated }, "step-dot")}>1</div>}
                >
                    <h4>Créer les fichiers de forçage</h4>
                    <Form
                        name="layers-menu-data-dates"
                        layout="vertical"
                        onFinish={async ({ daterange: [from, to] }: { daterange: [moment.Moment, moment.Moment] }) => {
                            if (study) {
                                setGenerateLoading(true);
                                try {
                                    // get sources from model repo
                                    const sources = JSON.parse(
                                        await fetchGitLab(
                                            `projects/${study.fromModelId}/repository/files/sources-forcages%2Ejson/raw?ref=main`,
                                        ),
                                    );

                                    await fetchSimulation({
                                        start_date: from.toISOString(),
                                        end_date: to.toISOString(),
                                        model_name: study.fromModelPath,
                                        project_id: study.id,
                                        sources,
                                    });
                                    setGenerated(true);
                                } catch (err) {
                                    console.error(err);
                                } finally {
                                    setGenerateLoading(false);
                                }
                            }
                        }}
                        disabled={globalDisable}
                    >
                        <Form.Item
                            label="Sélectionner une date de début et fin"
                            name="daterange"
                            rules={[
                                {
                                    required: true,
                                    message: "Merci de sélectionner une période",
                                },
                                () => ({
                                    validator(_, [from, to]) {
                                        if (from.isAfter(moment())) {
                                            return Promise.reject(
                                                new Error("La date de début ne peut être dans le futur"),
                                            );
                                        }
                                        if (to.diff(from) / 1000 / 3600 / 24 > 30) {
                                            return Promise.reject(
                                                new Error(
                                                    "Vous ne pouvez pas générer des fichiers pour une durée supérieure à 30 jours",
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <DatePicker.RangePicker size="large" />
                        </Form.Item>
                        <Button type="default" htmlType="submit" disabled={generated}>
                            <ExportOutlined /> Générer {generateLoading && <LoadingOutlined />}
                        </Button>
                    </Form>
                </Timeline.Item>
                <Timeline.Item
                    color="#484964"
                    dot={<div className={classNames({ done: gitlabOpened, disabled: !generated }, "step-dot")}>2</div>}
                >
                    <h4>Modifier les fichiers</h4>
                    <p>Modifier si besoin les fichiers avant lancement</p>
                    <Button
                        type="default"
                        href={study?.web_url}
                        target="_blank"
                        disabled={!generated}
                        onClick={() => setGitlabOpened(true)}
                    >
                        <GitlabOutlined /> Ouvrir Gitlab
                    </Button>
                </Timeline.Item>
                <Timeline.Item
                    color="#484964"
                    dot={
                        <div className={classNames("step-dot", { done: calculationLoading, disabled: !generated })}>
                            3
                        </div>
                    }
                >
                    <h4>Lancer le calcul</h4>
                    <p>Et accès à Jupyter Lab pour exploiter, requêter, analyser les résultats</p>
                    <Button
                        type="primary"
                        onClick={launchCalculations}
                        disabled={globalDisable || calculationLoading || !generated}
                    >
                        <PullRequestOutlined /> Calculer {calculationLoading && <LoadingOutlined />}
                    </Button>

                    {calculationLoading && (
                        <>
                            <Alert
                                message="Une notification vous sera envoyée par email lorsque les résultats pourront être affichés sur la carte."
                                type="info"
                                showIcon
                            />
                            <Button disabled={!jupyterUrl} type="default" href={jupyterUrl} target="_blank">
                                Ouvrir dans Jupyter Lab
                            </Button>
                        </>
                    )}
                </Timeline.Item>
            </Timeline>
            <Button type="default" block onClick={cancel} disabled={globalDisable}>
                Annuler {canceling && <LoadingOutlined />}
            </Button>
        </div>
    );
}
