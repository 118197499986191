import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { toggleFullscreen } from "qwc2/actions/display";
import MapButton from "../MapButton";

export default function FullScreenButton() {
    const { fullscreen } = useSelector((state: any) => ({
        fullscreen: state.display?.fullscreen,
    }));
    const dispatch = useDispatch();

    function fullScreenToogled() {
        dispatch(toggleFullscreen(!fullscreen));
    }

    return (
        <MapButton onClick={fullScreenToogled} isDisabled={false} tooltip="Plein écran">
            {fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        </MapButton>
    );
}
