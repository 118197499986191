import React from "react";
import classNames from "classnames";
import "./ColorStatus.less";

export enum Color {
    GREEN = "green",
    ORANGE = "orange",
    RED = "red",
}

export default function ColorStatus({ color }: { color: Color }) {
    return <div className={classNames("color-status", color)}></div>;
}
