import React, { useContext } from "react";

import { Garonne2050MeteoBottomPanelPlugin } from "./Garonne2050BottomPanelPlugin/Garonne2050BottomPanelPlugin";
import { InfoPanelPlugin } from "../Shared/components/InfoPanelPlugin/InfoPanelPlugin";
import { LayoutContextProvider, LayoutContext } from "../Shared/contexts/LayoutContextProvider";
import { OLContextProvider } from "qwc2/contexts/OLContext";
import ModuleTopBar from "../Shared/components/ModuleTopBar";

import QWC2 from "../Shared/QWC2/QWC2";
import { MapModal } from "../Shared/components/MapModal/MapModal";
import { ButtonsPlugin } from "../Shared/components/ButtonsPlugin/ButtonsPlugin";
import { LayersMenuPlugin } from "../Shared/components/LayersMenu/LayersMenuPlugin";
import Map from "../Shared/components/Map";

const Garonne2050Layout = () => {
    const { onMapModal } = useContext(LayoutContext);

    return (
        <div className="wrapper plugin-container flex column">
            <ModuleTopBar />
            <div className="plugin-container flex row grow">
                <LayersMenuPlugin />
                <div className="plugin-container flex column grow overflow">
                    <div className="plugin-container grow wrapper flex flex-end">
                        <Map />
                        <ButtonsPlugin />
                        {onMapModal && <MapModal {...onMapModal} />}
                        <InfoPanelPlugin />
                    </div>
                    <Garonne2050MeteoBottomPanelPlugin />
                </div>
            </div>
        </div>
    );
};

export const Garonne2050Module = () => {
    return (
        <QWC2 theme="scan/gpmb_garonne_2050">
            <OLContextProvider>
                <LayoutContextProvider>
                    <Garonne2050Layout />
                </LayoutContextProvider>
            </OLContextProvider>
        </QWC2>
    );
};
