import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ZoomInOutlined } from "@ant-design/icons";
import { changeZoomLevel } from "qwc2/actions/map";
import MapButton from "../MapButton";

export default function ZoomInButton() {
    const { currentZoom, maxZoom } = useSelector((state: any) => ({
        currentZoom: state.map.zoom,
        maxZoom: state.map.resolutions.length - 1,
    }));
    const dispatch = useDispatch();

    function ZoomIn() {
        dispatch(changeZoomLevel(currentZoom + 1));
    }

    const isDisabled = currentZoom >= maxZoom;

    return (
        <MapButton onClick={ZoomIn} isDisabled={isDisabled} tooltip="Zoom avant">
            <ZoomInOutlined />
        </MapButton>
    );
}
