import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button, Dropdown, Space } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { setTopbarHeight } from "qwc2/actions/map";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import classNames from "classnames";
import UserContext, { IUserContext } from "../../../UserContext";
import { SearchBar } from "../SearchBar/SearchBar";
import { Bookmarks } from "./Bookmarks/Bookmarks";
import useCurrentModule from "../../useCurrentModule";
import HelpButton from "./HelpButton";
import "./ModuleTopBar.less";

interface IModuleTopBar {
    customButtons?: React.ReactNode;
}

export default function ModuleTopBar({ customButtons }: IModuleTopBar) {
    const { fullscreen } = useSelector((state: any) => ({
        fullscreen: state.display?.fullscreen,
    }));
    const dispatch = useDispatch();
    const { user } = useContext(UserContext) as IUserContext;
    const navigate = useNavigate();
    useEffect(() => {
        // no padding on map since the topbar doesn't overlap
        dispatch(setTopbarHeight(0));
    }, [dispatch]);
    const [currentModule, otherModules] = useCurrentModule();
    return (
        <header className={classNames("module-top-bar", { fullscreen })}>
            <div className="left-side">
                <Link to="/">
                    <Button type="text" size="large" className="back-button">
                        <Space>
                            <ArrowLeftOutlined />
                        </Space>
                    </Button>
                </Link>
                {otherModules?.length ? (
                    <h1>
                        <Dropdown
                            menu={{
                                onClick: ({ key }) => navigate(key),
                                items: otherModules?.map(({ title, path }) => ({ key: path, label: title })),
                            }}
                            trigger={["click", "hover"]}
                        >
                            <Button type="text" size="large">
                                <Space>
                                    {currentModule?.title}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </h1>
                ) : (
                    <h1>{currentModule?.title}</h1>
                )}
            </div>
            <div className="right-side">
                <SearchBar />
                <Bookmarks />
                {customButtons}
                <HelpButton to={currentModule?.documentation ?? ""} />
                {user && (
                    <div className="avatar-wrapper">
                        <Avatar className="user-avatar">
                            {`${user.givenName.slice(0, 1)} ${user.familyName.slice(0, 1)}`}
                        </Avatar>
                    </div>
                )}
            </div>
        </header>
    );
}
