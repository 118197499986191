import React, { useState } from "react";
import { CloseOutlined, DoubleRightOutlined } from "@ant-design/icons";
import classNames from "classnames";
import useOLResize from "../../Shared/useOLResize";
import { Tabs } from "antd";
import { EDredgingModule, IDredgingModuleLayout } from "..";
import "./DredgingLeftBar.less";

interface IDredgingLeftBar extends IDredgingModuleLayout {
    children: React.ReactNode;
}

export default function DredgingLeftBar({ children, onChangeCurrentModule, currentModule }: IDredgingLeftBar) {
    const [isLeftPanelOpen, setisLeftPanelOpen] = useState(true);
    const resizeOLMap = useOLResize(200);

    return (
        <>
            <button
                className={classNames("left-bar-open-button", { hidden: isLeftPanelOpen })}
                onClick={() => {
                    setisLeftPanelOpen(true);
                    resizeOLMap();
                }}
            >
                <DoubleRightOutlined />
            </button>

            <div
                className={classNames("dredging-left-bar", {
                    open: isLeftPanelOpen,
                    comission: currentModule === EDredgingModule.THRESHOLD_COMMISSION,
                    strategy: currentModule === EDredgingModule.ANNUAL_STRATEGY,
                })}
            >
                <button
                    className="close-button"
                    onClick={() => {
                        setisLeftPanelOpen(false);
                        resizeOLMap();
                    }}
                >
                    <CloseOutlined />
                </button>
                <Tabs
                    items={[
                        {
                            label: "Commission des seuils",
                            key: EDredgingModule.THRESHOLD_COMMISSION,
                        },
                        {
                            label: "Stratégie annuelle",
                            key: EDredgingModule.ANNUAL_STRATEGY,
                        },
                    ]}
                    activeKey={currentModule}
                    onChange={(key) => onChangeCurrentModule(key as EDredgingModule)}
                />
                {children}
            </div>
        </>
    );
}
