import React from "react";
import { Button, Form, FormInstance } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import FilteringSlider from "./components/FilteringSlider";
import "./LayersMenuFilteringPanel.less";

interface ILayerFilterMinMax {
    layer: string;
    property: string;
    min: number;
    max: number;
    title?: string;
}

export default function LayerMenuFilteringPanel({
    onClose,
    filterLayers,
    visibleFilterList,
    formRef,
}: {
    onClose: () => void;
    filterLayers: (values: any) => void;
    visibleFilterList: ILayerFilterMinMax[];
    formRef: React.RefObject<FormInstance>;
}) {
    return (
        <section className="layers-menu-filtering-panel extra-left-panel">
            <header>
                <h3>Filtrage des données</h3>
                <Button type="text" className="close-button" onClick={onClose}>
                    <CloseOutlined />
                </Button>
            </header>
            {visibleFilterList.length > 0 && (
                <Form
                    ref={formRef}
                    name="layers-menu-filtering"
                    layout="vertical"
                    onFinish={(values) => {
                        filterLayers(values);
                        onClose();
                    }}
                    initialValues={visibleFilterList?.reduce(
                        (values, { layer, min, max }) => ({
                            ...values,
                            [layer]: [min, max],
                        }),
                        {},
                    )}
                >
                    {visibleFilterList.map((layerFilter) => (
                        <FilteringSlider
                            {...layerFilter}
                            key={layerFilter.layer}
                            defaultValue={[layerFilter.min, layerFilter.max]}
                        />
                    ))}
                    <div className="action-buttons">
                        <Button type="default" htmlType="button" onClick={() => formRef.current?.resetFields()}>
                            Tout Effacer
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Afficher
                        </Button>
                    </div>
                </Form>
            )}
        </section>
    );
}
