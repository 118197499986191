import { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import { queryBuilder } from "../../../../Shared/utils/querybuilder";
import { fetchSLF } from "../../../../Shared/utils/fetch";

interface IUseChartDataParams {
    layer: { label: string; value: string[] };
    coords: Array<number>;
    isOpen: boolean;
}

interface IAntChartData {
    timestamp: string;
    value: number;
    layer: string;
}

const getLayerLibelleFromName = (layerName: string) => {
    return layerName
        .split("_")
        .map((splittedName, index) =>
            index === 0 ? `${splittedName.slice(0, 1).toUpperCase()}${splittedName.slice(1)}` : splittedName,
        )
        .join(" ");
};

export const useChartData = ({ layer, coords, isOpen }: IUseChartDataParams) => {
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState<null | any>(null);
    const [currentParams, setCurrentParams] = useState<null | {
        layer: IUseChartDataParams["layer"];
        coords: IUseChartDataParams["coords"];
    }>(null);

    const fetchChartData = useCallback(async () => {
        setIsLoading(true);
        try {
            const minTs = moment().subtract(30, "day").format("YYYY-MM-DD");
            const maxTs = moment().add(130, "day").format("YYYY-MM-DD");
            const query = queryBuilder("model/charts", {
                time_step_min: minTs,
                time_step_max: maxTs,
                x: coords[0].toString(),
                y: coords[1].toString(),
                layers: layer.value,
            });
            const response = await fetchSLF(query);
            const chartData = mapResponseToChartData(response);
            setCurrentParams({
                layer,
                coords,
            });
            setChartData(chartData);
        } catch (err) {
            console.error(`An error occured while fetching chart data : ${err}`);
            setChartData(null);
            setCurrentParams(null);
        } finally {
            setIsLoading(false);
        }
    }, [coords, layer]);

    useEffect(() => {
        const canTriggerUpdate =
            JSON.stringify(currentParams) !==
            JSON.stringify({
                layer,
                coords,
            });

        if (coords?.length !== 2 || isLoading || !isOpen || !canTriggerUpdate) {
            return;
        }
        fetchChartData();
    }, [layer, isOpen, coords, currentParams, isLoading, fetchChartData]);

    const mapResponseToChartData = (
        response: { layer: string; libelle: string; time_steps_avg: { [ts: string]: number } }[],
    ): Array<IAntChartData> => {
        return response.reduce((acc, { time_steps_avg, layer }) => {
            const values = Object.entries(time_steps_avg).map(([timestamp, value]) => {
                return {
                    timestamp: moment.utc(timestamp).tz("Europe/Paris").valueOf().toString(),
                    layer: getLayerLibelleFromName(layer),
                    value,
                };
            });
            return [...acc, ...values];
        }, [] as Array<IAntChartData>);
    };

    return {
        isLoading,
        chartData,
    };
};
