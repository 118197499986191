import React from "react";

export default function WavesIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9 2.17505C13.1 3.07505 11.6 3.97505 10 3.97505C8.5 3.97505 7.6 3.47505 6.8 3.07505C6 2.77505 5.2 2.27505 3.9 2.27505C2.7 2.27505 1.6 2.97505 1 3.57505L0 2.07505C0.9 1.17505 2.3 0.175049 3.9 0.175049C5.4 0.175049 6.3 0.775049 7.1 1.07505C7.9 1.37505 8.7 1.97505 10 1.97505C11.2 1.97505 12.3 1.17505 12.9 0.575049L13.9 2.17505ZM14 7.07505C13.1 7.97505 11.7 8.97505 10.1 8.97505C8.6 8.97505 7.7 8.47505 6.9 8.07505C6.1 7.77505 5.3 7.17505 4 7.17505C2.8 7.17505 1.7 7.97505 1.1 8.57505L0.0999999 6.97505C1 6.07505 2.4 5.07505 4 5.07505C5.5 5.07505 6.4 5.57505 7.2 5.97505C8 6.27505 8.8 6.77505 10.1 6.77505C11.3 6.77505 12.4 5.97505 13 5.37505L14 7.07505Z"
                fill="white"
            />
            <path
                d="M13.9 7.0249C13.1 7.9249 11.6 8.8249 10 8.8249C8.5 8.8249 7.6 8.3249 6.8 7.9249C6 7.6249 5.2 7.1249 3.9 7.1249C2.7 7.1249 1.6 7.8249 1 8.4249L0 6.9249C0.9 6.0249 2.3 5.0249 3.9 5.0249C5.4 5.0249 6.3 5.6249 7.1 5.9249C7.9 6.2249 8.7 6.8249 10 6.8249C11.2 6.8249 12.3 6.0249 12.9 5.4249L13.9 7.0249ZM14 11.9249C13.1 12.8249 11.7 13.8249 10.1 13.8249C8.6 13.8249 7.7 13.3249 6.9 12.9249C6.1 12.6249 5.3 12.0249 4 12.0249C2.8 12.0249 1.7 12.8249 1.1 13.4249L0.0999999 11.8249C1 10.9249 2.4 9.9249 4 9.9249C5.5 9.9249 6.4 10.4249 7.2 10.8249C8 11.1249 8.8 11.6249 10.1 11.6249C11.3 11.6249 12.4 10.8249 13 10.2249L14 11.9249Z"
                fill="white"
            />
        </svg>
    );
}
