import { useEffect, useState } from "react";
import { fetchSLF } from "../../utils/fetch";

export default function useJupyterUrl(projectId?: number): string {
    const [url, setUrl] = useState("");

    useEffect(() => {
        const fetchUrl = async (projectId: number) => {
            const response = await fetchSLF(`model/notebook_url/${projectId}`);
            setUrl(response.url);
        };

        if (!projectId) {
            return;
        }
        fetchUrl(projectId);
    }, [projectId]);

    return url;
}
