import React from "react";

export const InfoIcon = () => {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C8.02219 0 6.08881 0.586487 4.44431 1.6853C2.79981 2.78412 1.5181 4.34592 0.761202 6.17316C0.00433514 8.00043 -0.193693 10.0111 0.19214 11.9509C0.578005 13.8907 1.53043 15.6725 2.92893 17.0711C4.32746 18.4696 6.10928 19.422 8.0491 19.8079C9.59299 20.115 11.1817 20.0522 12.6872 19.6322C12.5585 19.007 12.4171 18.3478 12.2648 17.6702C11.536 17.8861 10.7738 17.999 10 18C8.41776 18 6.87103 17.5308 5.55545 16.6518C4.23984 15.7727 3.21448 14.5233 2.60898 13.0615C2.00348 11.5997 1.84503 9.99112 2.15372 8.43927C2.4624 6.88742 3.22434 5.46198 4.34314 4.34314C5.46198 3.22433 6.88742 2.4624 8.43927 2.15372C9.99112 1.84503 11.5997 2.00345 13.0615 2.60898C14.5233 3.21448 15.7727 4.23984 16.6518 5.55545C17.5308 6.87103 18 8.41776 18 10C17.9993 10.5418 17.9438 11.0779 17.8364 11.6009L19.76 12.178C19.9187 11.4666 20 10.7364 20 10C19.9971 7.34872 18.9426 4.80688 17.0679 2.93216C15.1931 1.0574 12.6513 0.00289917 10 0ZM10 9.5C9.7348 9.5 9.48044 9.60535 9.29291 9.79291C9.10538 9.98044 9 10.2348 9 10.5V13.5C9 13.7652 9.10538 14.0196 9.29291 14.2071C9.48044 14.3947 9.7348 14.5 10 14.5C10.2652 14.5 10.5196 14.3947 10.7071 14.2071C10.8947 14.0196 11 13.7652 11 13.5V10.5C11 10.2348 10.8947 9.98044 10.7071 9.79291C10.5196 9.60535 10.2652 9.5 10 9.5ZM10 5.5C9.75278 5.5 9.51111 5.5733 9.30554 5.71066C9.09998 5.84802 8.93976 6.04324 8.84516 6.27164C8.75055 6.50006 8.7258 6.75137 8.77402 6.99387C8.82227 7.23633 8.94132 7.45908 9.11612 7.63388C9.29093 7.80869 9.51367 7.92776 9.75614 7.97598C9.99863 8.0242 10.2499 7.99945 10.4784 7.90485C10.7068 7.81024 10.902 7.65002 11.0393 7.44446C11.1767 7.23889 11.25 6.99722 11.25 6.75C11.25 6.41849 11.1183 6.10052 10.8839 5.86612C10.6495 5.63168 10.3315 5.5 10 5.5ZM23 16L19.23 17.26L22.48 20.49L20.5 22.47L17.26 19.23L16 23L13 13L23 16Z"
                fill="white"
            />
        </svg>
    );
};
