import React, { useState } from "react";
import { OLContextProvider } from "qwc2/contexts/OLContext";

import QWC2 from "../Shared/QWC2/QWC2";
import { LayoutContextProvider } from "../Shared/contexts/LayoutContextProvider";
import { AnnualStrategyLayout } from "./AnnualStrategy/AnnualStrategyLayout";
import { ThresholdCommissionLayout } from "./ThresholdCommission/ThresholdCommissionLayout";

export enum EDredgingModule {
    THRESHOLD_COMMISSION = "scan/gpmb_dragage_commission_seuils",
    ANNUAL_STRATEGY = "scan/gpmb_dragage_strategie_annuelle",
}

export interface IDredgingModuleLayout {
    onChangeCurrentModule: (nextModule: EDredgingModule) => void;
    currentModule: EDredgingModule;
}

export const DredgingModule = () => {
    const [currentModule, setCurrentModule] = useState(EDredgingModule.THRESHOLD_COMMISSION);

    return (
        <QWC2 key={currentModule} theme={currentModule}>
            <OLContextProvider>
                <LayoutContextProvider>
                    {currentModule === EDredgingModule.THRESHOLD_COMMISSION && (
                        <ThresholdCommissionLayout
                            onChangeCurrentModule={(nextModule) => setCurrentModule(nextModule)}
                            currentModule={currentModule}
                        />
                    )}
                    {currentModule === EDredgingModule.ANNUAL_STRATEGY && (
                        <AnnualStrategyLayout
                            onChangeCurrentModule={(nextModule) => setCurrentModule(nextModule)}
                            currentModule={currentModule}
                        />
                    )}
                </LayoutContextProvider>
            </OLContextProvider>
        </QWC2>
    );
};
