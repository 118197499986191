export const queryBuilder = (baseUrl: string, queryParams: { [name: string]: string | string[] }) => {
    return Object.keys(queryParams).reduce((acc, cur, index) => {
        const qs = index === 0 ? "?" : "&";
        let value;
        if (Array.isArray(queryParams[cur])) {
            value = (queryParams[cur] as string[]).reduce(
                (_acc, _cur, _index) => _acc.concat(`${_index !== 0 ? "&" : ""}${cur}=${_cur}`),
                "",
            );
        } else {
            value = `${cur}=${queryParams[cur]}`;
        }

        return acc.concat(`${qs}${value}`);
    }, baseUrl);
};

export const parseQueryString = (queryString: string): any => {
    return queryString
        .replace("?", "")
        .split("&")
        .reduce((acc, cur) => {
            const [key, value] = cur.split("=");

            return {
                ...acc,
                [key]: value,
            };
        }, {});
};
