import { Input } from "antd";
import React from "react";
import { ITideGauge } from "../../useTideGauge";
import "./ZhRefSettingsEditableCell.less";

interface IZhRefSettingsEditableCell {
    isEditing: boolean;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof ITideGauge;
    record: ITideGauge;
    handleEdit: (dataIndex: keyof ITideGauge, newValue: number) => void;
}

export const ZhRefSettingsEditableCell: React.FC<IZhRefSettingsEditableCell> = ({
    editable,
    isEditing,
    children,
    dataIndex,
    record,
    handleEdit,
    ...restProps
}) => {
    let childNode = children;

    if (editable && isEditing) {
        childNode = (
            <Input
                type="number"
                allowClear
                step=".01"
                value={record[dataIndex] ?? undefined}
                onChange={(event) => handleEdit(dataIndex, parseFloat(event.target.value))}
            />
        );
    } else if (record && record[dataIndex] === null) {
        childNode = "-";
    } else if (record && record[dataIndex]) {
        childNode = record[dataIndex];
    }

    return <td {...restProps}>{childNode}</td>;
};
