import { CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { fetchGitLab, fetchSLF } from "../../../utils/fetch";
import { IStudy } from "../LayersMenuDataPanel";
import "./DataExistingStudySelectForm.less";
import { useParams } from "react-router";
import UserContext, { IUserContext } from "../../../../UserContext";

export default function DataExistingStudySelectForm({ onStudySelected }: { onStudySelected: (study: IStudy) => void }) {
    const [studies, setStudies] = useState([]);
    const [selectedStudy, setSelectedStudy] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext) as IUserContext;

    useEffect(() => {
        const fetchModels = async () => {
            if (!user) {
                return;
            }

            setLoading(true);
            try {
                const studies = (await fetchGitLab(`groups/${process.env.REACT_APP_GIT_TELEMAC_FORK_TARGET_GROUP_ID}`))
                    ?.projects;
                const userStudies: { project_id: number }[] = await fetchSLF(`model/user/${user.email}`, {
                    headers: {
                        "qwc2-token": `${process.env.REACT_APP_QWC2_TOKEN}`,
                    },
                });
                const processingDoneStudyIdList = (await fetchSLF(`model/project_ids/etudes`))?.project_ids;
                const availableStudies = processingDoneStudyIdList.filter((id: number) => {
                    return userStudies.find(({ project_id }) => id === project_id);
                });
                const filteredStudies = studies
                    .filter(({ id }: { id: number }) => availableStudies.includes(id))
                    .map(({ id, name }: { id: number; name: string }) => ({
                        label: name,
                        key: id,
                        value: id,
                    }));
                setStudies(filteredStudies);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchModels();
    }, [user]);

    const loadExistingStudy = useCallback(
        async (studyId) => {
            setLoading(true);
            try {
                const project = await fetchGitLab(`projects/${studyId}`);
                const { id, name, path, web_url, namespace, default_branch, forked_from_project } = project;
                onStudySelected({
                    id,
                    name,
                    path,
                    web_url,
                    namespace_id: namespace?.id,
                    fromModelId: forked_from_project?.id,
                    fromModelName: forked_from_project?.name,
                    fromModelPath: forked_from_project?.path,
                    default_branch: default_branch,
                });
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        },
        [onStudySelected],
    );

    // load requested study from url
    const { studyId } = useParams();
    useEffect(() => {
        if (studyId && studies.length > 0) {
            if (studies.find(({ key }) => key === studyId)) {
                loadExistingStudy(studyId);
            }
        }
    }, [studyId, studies, loadExistingStudy]);
    return (
        <div className="data-existing-study-select-form">
            <h4>visualiser une étude existante</h4>
            <Input.Group compact>
                <Select placeholder="Sélectionner" options={studies} showSearch onChange={setSelectedStudy} />
                <Button type="primary" block disabled={!selectedStudy} onClick={() => loadExistingStudy(selectedStudy)}>
                    <CloudDownloadOutlined /> Charger {loading && <LoadingOutlined />}
                </Button>
            </Input.Group>
        </div>
    );
}
