import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { EProgrammedState } from "../../../../../Dredging/AnnualStrategy/types";
import useWaterways from "../../../../useWaterways";
import { IPlanningTimeRange } from "../../types";

export interface ICreateTimeRangeWaterway {
    onCreate: (timerange: Partial<IPlanningTimeRange>) => void;
    searchbar: React.ReactNode;
    timerangeSelect: React.ReactNode;
    search: string;
}

export const CreateTimeRangeWaterway = ({ onCreate, searchbar, search, timerangeSelect }: ICreateTimeRangeWaterway) => {
    const { fairways, defiles, isLoading } = useWaterways();

    const waterways = [...fairways, ...defiles].map(({ name }) => name).sort();

    const handleCreate = (waterwayName: string) => {
        const newTimerange = {
            activity: EProgrammedState.PROGRAMMED,
            text: waterwayName,
            tooltip: waterwayName,
        };
        onCreate(newTimerange);
    };

    return isLoading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
    ) : (
        <div className="create-timerange">
            {timerangeSelect}
            {searchbar}
            {waterways
                .filter((waterwayName) => !search || waterwayName.toLowerCase().includes(search.toLowerCase()))
                .map((waterwayName, index) => (
                    <button key={index} className="selectable-item" onClick={() => handleCreate(waterwayName)}>
                        {waterwayName}
                    </button>
                ))}
        </div>
    );
};
