import React, { useContext } from "react";
import { Switch, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { changeLayerProperty } from "qwc2/actions/layers";
import { IQWC2Layer } from "../../../../Interfaces/IQWC2Layer";
import { useWMSLayer } from "../../../../useWMSLayer";
import { LayoutContext } from "../../../../contexts/LayoutContextProvider";
import { LayerMetadata } from "../../../LayerMetadata/LayerMetadata";
import { getLayerFromPath, getLayerPath } from "../../../../utils/layer";
import "./LayerMenuRow.less";

interface ILayerMenuRow {
    layer: IQWC2Layer;
    rootLayerUuid: string;
    layerPath: number[];
}

export function LayerMenuRow({ layer, rootLayerUuid, layerPath }: ILayerMenuRow) {
    const WMSLayer = useWMSLayer();
    const dispatch = useDispatch();
    const { setOnMapModal } = useContext(LayoutContext);

    const onToggleVisibility = () => {
        const recurseDirection = !layer.visibility ? "both" : "children";
        dispatch(changeLayerProperty(rootLayerUuid, "visibility", !layer.visibility, layerPath, recurseDirection));
    };

    const onDisplayMetadata = () => {
        if (!WMSLayer) {
            return;
        }
        const layerPath = getLayerPath(layer.name, WMSLayer, []);
        const layerGroup = layerPath && getLayerFromPath(layerPath.slice(0, 1), WMSLayer);
        setOnMapModal &&
            setOnMapModal({
                title: layerGroup ? `${layer.title} - ${layerGroup.title}` : `${layer.title}`,
                onClose: () => setOnMapModal(null),
                content: <LayerMetadata layer={layer} rootLayer={WMSLayer} parentLayerTitle={layerGroup?.title} />,
            });
    };

    return (
        <div className="tr" data-id={JSON.stringify({ layer: layer.uuid })}>
            <div className="td">
                <Switch size="small" checked={layer.visibility} onClick={onToggleVisibility} />
            </div>
            <div className="td">
                {layer.title.length >= 35 ? (
                    <Tooltip placement="top" title={layer.title} overlayStyle={{ fontSize: 12 }}>
                        {layer.title}
                    </Tooltip>
                ) : (
                    layer.title
                )}
            </div>
            <div className="td">
                <button className="layer-metadata-button" type="button" onClick={onDisplayMetadata}>
                    <InfoCircleOutlined />
                </button>
            </div>
        </div>
    );
}
