import React from "react";
import MapPlugin from "qwc2/plugins/Map";
import EditingSupport from "qwc2/plugins/map/EditingSupport";
import MeasurementSupport from "qwc2/plugins/map/MeasurementSupport";
import LocateSupport from "qwc2/plugins/map/LocateSupport";
import RedliningSupport from "qwc2/plugins/map/RedliningSupport";
import ScaleBarSupport from "qwc2/plugins/map/ScaleBarSupport";
import SelectionSupport from "qwc2/plugins/map/SelectionSupport";
import SnappingSupport from "qwc2/plugins/map/SnappingSupport";

const MapComponent = MapPlugin({
    EditingSupport,
    MeasurementSupport,
    LocateSupport,
    RedliningSupport,
    ScaleBarSupport,
    SelectionSupport,
    SnappingSupport,
});

export default function Map() {
    return <MapComponent />;
}
