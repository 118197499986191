import React, { useContext } from "react";
import { OLContextProvider } from "qwc2/contexts/OLContext";

import QWC2 from "../Shared/QWC2/QWC2";
import { LayoutContext, LayoutContextProvider } from "../Shared/contexts/LayoutContextProvider";
import ModuleTopBar from "../Shared/components/ModuleTopBar";
import { ButtonsPlugin } from "../Shared/components/ButtonsPlugin/ButtonsPlugin";
import { MapModal } from "../Shared/components/MapModal/MapModal";
import { InfoPanelPlugin } from "../Shared/components/InfoPanelPlugin/InfoPanelPlugin";
import { NavigabilityBottomPanelPlugin } from "./NavigabilityBottomPanelPlugin/NavigabilityBottomPanelPlugin";
import { NavigabilityLeftBar } from "./NavigabilityLeftBar/NavigabilityLeftBar";
import useWaterways from "../Shared/useWaterways";
import { useTerminals } from "./useTerminals";
import { NavigabilityFeedbackButton } from "./NavigabilityFeedbackButton/NavigabilityFeedbackButton";
import { NavigabilityAlertLogButton } from "./NavigabilityAlertLogButton/NavigabilityAlertLogButton";
import Map from "../Shared/components/Map";

const NavigabilityLayout = () => {
    const { onMapModal } = useContext(LayoutContext);
    const { fairways, isLoading: isLoadingWaterways } = useWaterways();
    const { terminals, isLoading: isLoadingTerminals } = useTerminals();
    const isLoading = isLoadingWaterways || isLoadingTerminals;

    return (
        <div className="wrapper plugin-container flex column">
            <ModuleTopBar
                customButtons={
                    <>
                        <NavigabilityFeedbackButton
                            fairways={fairways}
                            terminals={terminals}
                            isWaterwaysLoading={isLoading}
                        />
                        <NavigabilityAlertLogButton />
                    </>
                }
            />
            <div className="plugin-container flex row grow">
                <NavigabilityLeftBar />
                <div className="plugin-container flex column grow overflow">
                    <div className="plugin-container grow wrapper flex flex-end">
                        <Map />
                        <ButtonsPlugin toolbox info />
                        {onMapModal && <MapModal {...onMapModal} />}
                        <InfoPanelPlugin />
                    </div>
                    <NavigabilityBottomPanelPlugin />
                </div>
            </div>
        </div>
    );
};

export const NavigabilityModule = () => {
    return (
        <QWC2 theme="scan/gpmb_navigabilite">
            <OLContextProvider>
                <LayoutContextProvider>
                    <NavigabilityLayout />
                </LayoutContextProvider>
            </OLContextProvider>
        </QWC2>
    );
};
