import React, { useContext, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useMarkers } from "../../../useMarkers";
import MapButton from "../MapButton";
import { Spin } from "antd";
import { FeatureInfo, IFeatureInfo } from "../../FeatureInfo/FeatureInfo";
import { findLayer, getAllLayersNames } from "../../../utils/layer";
import fetchWMS, { WMSRequest } from "../../../WMSApiService";
import { useWMSLayer } from "../../../useWMSLayer";
import { Collapse } from "antd";
import { InfoIcon } from "../../../Icons/InfoIcon";
import { LayoutContext } from "../../../contexts/LayoutContextProvider";
import { useSelector } from "react-redux";
import "./InfoBubbleButton.less";

const { Panel } = Collapse;

const getBboxFromCoords = (coords: number[], scale: number) => {
    const delta = scale / 100;
    const [x, y] = coords;
    return [x - delta, y - delta, x + delta, y + delta];
};

export default function InfoBubbleButton() {
    const [isLoading, setIsLoading] = useState(false);
    const { setOnMapModal } = useContext(LayoutContext);
    const { markers, addMarker, deleteMarker, isSettingMarker, stopMarkerSelection } = useMarkers({
        showMarkers: true,
        maxMarkers: 1,
        showMarkersTitle: false,
        continiousAdd: true,
    });
    const { zoom, scales } = useSelector((state: any) => ({
        zoom: state.map.zoom,
        scales: state.map.scales,
    }));
    const currentScale: number = scales[zoom];
    const [wmsParams, setWmsParams] = useState<null | { timeFilter?: string; visibleLayers: string[] }>(null);
    const [featureInfo, setFeatureInfo] = useState<null | Record<string, IFeatureInfo[]>>(null);
    const WMSLayer = useWMSLayer();
    const toggleInfoBubble = () => {
        isSettingMarker || Boolean(markers[0]) ? onStopMarkerSelection() : addMarker();
    };
    const getInfoBubbleData = (wmsResponse: {
        features: { id: string; properties: any }[];
    }): Record<string, IFeatureInfo[]> => {
        if (!WMSLayer) {
            return {};
        }
        const infoBubbleProps = wmsResponse.features
            .filter(({ properties }) => Object.keys(properties).length > 0)
            .map(({ id, properties }) => {
                const [layerName, feature] = id.split(".");
                const title = findLayer(layerName, WMSLayer)?.title;

                return {
                    properties,
                    feature,
                    title,
                };
            })
            .reduce((acc, { title, ...rest }) => {
                if (!title) {
                    return acc;
                }
                if (!acc[title]) {
                    acc[title] = [];
                }
                acc[title].push({ ...rest });
                return acc;
            }, {} as Record<string, IFeatureInfo[]>);

        return infoBubbleProps;
    };

    const onCloseMapModal = () => {
        setOnMapModal && setOnMapModal(null);
        setFeatureInfo(null);
        markers.length > 0 && deleteMarker(0);
        stopMarkerSelection();
    };

    const onStopMarkerSelection = () => {
        onCloseMapModal();
    };

    useEffect(() => {
        if (!WMSLayer) {
            return;
        }
        const visibleLayers = WMSLayer && getAllLayersNames(WMSLayer, "visibility");
        const timeFilter = WMSLayer?.params.TIME;
        const newWmsParams = {
            visibleLayers,
            timeFilter,
        };
        if (JSON.stringify(newWmsParams) !== JSON.stringify(wmsParams)) {
            setWmsParams(newWmsParams);
        }
    }, [WMSLayer]);

    useEffect(() => {
        const fetchData = async () => {
            if (!WMSLayer || !markers[0]) {
                return;
            }
            setIsLoading(true);
            setFeatureInfo(null);
            try {
                const response = await fetchWMS({
                    baseUrl: WMSLayer.url,
                    request: {
                        name: WMSRequest.GET_FEATURE_INFO,
                        options: {
                            LAYERS: wmsParams?.visibleLayers.toString(),
                            QUERY_LAYERS: wmsParams?.visibleLayers.toString(),
                            INFO_FORMAT: "application/json",
                            SRS: "EPSG:3857",
                            CRS: "EPSG:3857",
                            // Activate this param to draw polygons on the map
                            WITH_GEOMETRY: "false",
                            WITH_MAPTIP: "false",
                            HEIGHT: "101",
                            WIDTH: "101",
                            FEATURE_COUNT: "20",
                            X: "51",
                            Y: "51",
                            I: "51",
                            J: "51",
                            BBOX: getBboxFromCoords(markers[0].coords, currentScale).toString(),
                            FI_LINE_TOLERANCE: "8",
                            FI_POINT_TOLERANCE: "16",
                            FI_POLYGON_TOLERANCE: "4",
                            region_feature_count: "100",
                            ...(wmsParams?.timeFilter && {
                                TIME: wmsParams.timeFilter,
                            }),
                        },
                    },
                });
                const featureInfoProps = getInfoBubbleData(response);
                setFeatureInfo(featureInfoProps);
                addMarker();
            } catch (err) {
                console.error(`An error occured while fetching WFS data : ${err}`);
            } finally {
                setIsLoading(false);
            }
        };

        if (!isLoading && markers[0] && wmsParams) {
            fetchData();
        }
    }, [markers, wmsParams, currentScale]);

    useEffect(() => {
        if (setOnMapModal && featureInfo) {
            setOnMapModal({
                title: "Informations sur l'objet",
                content:
                    Object.keys(featureInfo).length === 0 ? (
                        <div className="infobulle-content">
                            <p>
                                Activer des couches dans le panneau de gauche pour voir des informations détaillées sur
                                le point sélectionné.
                            </p>
                        </div>
                    ) : (
                        <div className="infobulle-content">
                            <Collapse>
                                {Object.keys(featureInfo).map((layerTitle) => (
                                    <Panel header={<h1>{layerTitle}</h1>} key={layerTitle}>
                                        {featureInfo[layerTitle].map(({ feature, properties }) => (
                                            <FeatureInfo key={feature} feature={feature} properties={properties} />
                                        ))}
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>
                    ),
                onClose: onCloseMapModal,
            });
        } else if (setOnMapModal) {
            setOnMapModal(
                isLoading
                    ? {
                          title: "Informations sur l'objet",
                          content: (
                              <div className="infobulle-content">
                                  <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
                              </div>
                          ),
                          onClose: onCloseMapModal,
                      }
                    : null,
            );
        }
    }, [isLoading, featureInfo]);

    return (
        <MapButton
            onClick={toggleInfoBubble}
            tooltip="Identifier les entités"
            active={isSettingMarker || Boolean(markers[0])}
            className="infobubble-button"
        >
            <InfoIcon />
        </MapButton>
    );
}
