import React from "react";
import { Link } from "react-router-dom";
import { IModule } from "../Shared/Interfaces/IModule";
import { RightArrowIcon } from "../Shared/Icons";
import { Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";

interface IModuleCard {
    module: IModule;
    userLoggedIn?: boolean;
}

export default function ModuleCard({ module, userLoggedIn }: IModuleCard) {
    const { title, description, img, icon, path, permissions, component } = module;

    const canAccessModule = (userLoggedIn || !permissions) && component;

    return (
        <div className="module-card card">
            <div className="card-icon">{icon}</div>
            <img alt={title} src={img} className="card-img" />
            <div className="card-content">
                <h3>{title}</h3>
                <p>{description}</p>
                <div className="card-action">
                    {canAccessModule ? (
                        <Link to={path}>
                            <RightArrowIcon />
                        </Link>
                    ) : (
                        component && (
                            <Tooltip
                                title={
                                    !userLoggedIn
                                        ? "Merci de vous connecter avec votre compte professionnel Giros pour accéder à ce module"
                                        : "Vous n'avez pas les droits nécessaires pour accéder à ce module"
                                }
                            >
                                <LockOutlined style={{ fontSize: 18, cursor: "pointer" }} />
                            </Tooltip>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
