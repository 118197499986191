import { useCallback, useEffect, useState } from "react";
import { FetchMethods, fetchSLF } from "../Shared/utils/fetch";

export enum EDifficultyOptions {
    DRAG = "Résistance à l'avancement",
    SQUAT = "Squat",
    DOCKING = "Amarrage",
    OTHER = "Autre",
}

export const difficultyMap: {
    [key: string]: {
        value: EDifficultyOptions;
        label?: string;
        color: string;
        intensityOptions?: { value: string; label?: string; color: string }[];
    };
} = {
    [EDifficultyOptions.DRAG]: {
        value: EDifficultyOptions.DRAG,
        color: "#13C2C2",
        intensityOptions: [
            {
                value: "Nul",
                color: "#64a021",
            },
            {
                value: "Faible",
                color: "#ffaa87",
            },
            {
                value: "Fort",
                color: "#ac5053",
            },
            {
                value: "Escale",
                label: "Escale en urgence",
                color: "#7e3741",
            },
        ],
    },
    [EDifficultyOptions.SQUAT]: {
        value: EDifficultyOptions.SQUAT,
        label: "Sensation de squat",
        color: "#ffb5b5",
        intensityOptions: [
            {
                value: "Nul",
                color: "#64a021",
            },
            {
                value: "Faible",
                label: "Faible (estimé à moins de 20cm)",
                color: "#ffaa87",
            },
            {
                value: "Moyen",
                label: "Moyen (estimé à moins de 50 cm)",
                color: "#ec756a",
            },
            {
                value: "Fort",
                label: "Fort (estimé à plus d'un mètre)",
                color: "#ac5053",
            },
        ],
    },
    [EDifficultyOptions.DOCKING]: {
        value: EDifficultyOptions.DOCKING,
        color: "#2F54EB",
        intensityOptions: [
            {
                value: "Nul",
                color: "#64a021",
            },
            {
                value: "Faible",
                color: "#ffaa87",
            },
            {
                value: "Moyen",
                color: "#ec756a",
            },
            {
                value: "Fort",
                color: "#ac5053",
            },
            {
                value: "Très fort",
                color: "#7e3741",
            },
        ],
    },
    [EDifficultyOptions.OTHER]: {
        value: EDifficultyOptions.OTHER,
        label: "Autre - préciser en commentaire",
        color: "#722ED1",
    },
};

export interface INavigabilityFeedbacks {
    id?: string;
    timestamp: string;
    ship_name: string;
    fairway_name?: string;
    terminal_name?: string;
    difficulty?: EDifficultyOptions;
    intensity?: string;
    comment?: string;
}

export const useNavigabilityFeedbacks = () => {
    const [navigabilityFeedbacks, setNavigabilityFeedbacks] = useState<INavigabilityFeedbacks[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchNavigabilityFeedbacks = useCallback(async () => {
        setIsLoading(true);
        try {
            const response: INavigabilityFeedbacks[] = await fetchSLF(`navigabilite/comments`);
            setNavigabilityFeedbacks(
                response.sort(({ timestamp: timestampA }, { timestamp: timestampB }) => {
                    const dateA = new Date(timestampA).getTime();
                    const dateB = new Date(timestampB).getTime();
                    if (dateA > dateB) {
                        return -1;
                    }
                    return 1;
                }),
            );
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchNavigabilityFeedbacks();
    }, [fetchNavigabilityFeedbacks]);

    const submitNavigabilityFeedback = useCallback(
        async (comment: INavigabilityFeedbacks) => {
            await fetchSLF(`navigabilite/comment`, {
                headers: {
                    "qwc2-token": process.env.REACT_APP_QWC2_TOKEN || "",
                },
                method: FetchMethods.POST,
                content: comment,
            });
            await fetchNavigabilityFeedbacks();
        },
        [fetchNavigabilityFeedbacks],
    );

    return {
        navigabilityFeedbacks,
        submitNavigabilityFeedback,
        isLoading,
    };
};
