import React, { useContext } from "react";

import ModuleTopBar from "../../Shared/components/ModuleTopBar";

import DredgingLeftBar from "../DredgingLeftBar/DredgingLeftBar";
import { InfoPanelPlugin } from "../../Shared/components/InfoPanelPlugin/InfoPanelPlugin";
import { ButtonsPlugin } from "../../Shared/components/ButtonsPlugin/ButtonsPlugin";
import { LayoutContext } from "../../Shared/contexts/LayoutContextProvider";
import { MapModal } from "../../Shared/components/MapModal/MapModal";
import { AnnualStrategyLeftBar } from "./AnnualStrategyLeftBar/AnnualStrategyLeftBar";
import { IDredgingModuleLayout } from "..";
import { AnnualStrategyBottomPanelPlugin } from "./AnnualStrategyBottomPanelPlugin/AnnualStrategyBottomPanelPlugin";
import EvolutionButtons from "../EvolutionButtons/EvolutionButtons";
import Map from "../../Shared/components/Map";

export const AnnualStrategyLayout = ({ onChangeCurrentModule, currentModule }: IDredgingModuleLayout) => {
    const { onMapModal } = useContext(LayoutContext);

    return (
        <div className="wrapper plugin-container flex column">
            <ModuleTopBar />
            <div className="plugin-container flex row grow">
                <DredgingLeftBar onChangeCurrentModule={onChangeCurrentModule} currentModule={currentModule}>
                    <AnnualStrategyLeftBar />
                </DredgingLeftBar>
                <div className="plugin-container flex column grow overflow">
                    <div className="plugin-container grow wrapper flex flex-end">
                        <Map />
                        <ButtonsPlugin toolbox info />
                        <EvolutionButtons />
                        {onMapModal && <MapModal {...onMapModal} />}
                        <InfoPanelPlugin />
                    </div>
                    <AnnualStrategyBottomPanelPlugin />
                </div>
            </div>
        </div>
    );
};
