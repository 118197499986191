import React, { useMemo, useState } from "react";

export const OLContext = React.createContext({
    setOlMap: () => {},
    olMap: null
});

export const OLContextProvider = ({
    children,
}) => {
    const [olMap, setOlMap] = useState(null);
    const value = useMemo(()=>({ olMap, setOlMap }),[olMap])
    return (
        <OLContext.Provider
            value={value}
        >
            {children}
        </OLContext.Provider>
    );
};
