import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeLayerProperty } from "qwc2/actions/layers";
import { useWMSLayer } from "../../Shared/useWMSLayer";
import MapButton from "../../Shared/components/MapButton/MapButton";
import ButtonGroup from "../../Shared/components/ButtonGroup/ButtonGroup";
import "./EvolutionButtons.less";

enum WaterwayLayerNames {
    FAIRWAYS = "passes",
    DEFILES = "souilles",
}

enum Evolution {
    NOW = "", // default
    J1 = "evolution_1d",
    J30 = "evolution_30d",
    J100 = "evolution_100d",
}

const evolutionButtons = [
    { title: "J1", evolution: Evolution.J1 },
    { title: "J30", evolution: Evolution.J30 },
    { title: "J100", evolution: Evolution.J100 },
];

export default function EvolutionButtons() {
    const dispatch = useDispatch();
    const WMSLayer = useWMSLayer();
    const visibleSublayersString = WMSLayer?.params.LAYERS;
    const visibleSublayersStylesString = WMSLayer?.params.STYLES || "";
    const [selectedEvolution, setSelectedEvolution] = useState<Evolution>(Evolution.NOW);
    useEffect(() => {
        if (WMSLayer?.uuid && visibleSublayersString) {
            // avoid requesting default Evolution.NOW style as it's the same has no style requested
            const visibleSublayers = visibleSublayersString.split(",");
            const visibleSublayersStyles = visibleSublayersStylesString.split(",");
            dispatch(
                changeLayerProperty(WMSLayer?.uuid, "dimensionValues", {
                    STYLES: visibleSublayers
                        .map((sublayer, index) =>
                            [WaterwayLayerNames.FAIRWAYS, WaterwayLayerNames.DEFILES].includes(
                                sublayer as WaterwayLayerNames,
                            )
                                ? selectedEvolution
                                : visibleSublayersStyles[index] ?? "",
                        )
                        .join(","),
                }),
            );
        }
    }, [dispatch, selectedEvolution, WMSLayer?.uuid, visibleSublayersString, visibleSublayersStylesString]);

    return (
        <ButtonGroup direction="horizontal" className="dredging-evolution-buttons">
            {evolutionButtons.map(({ title, evolution }) => {
                const active = evolution === selectedEvolution;
                return (
                    <MapButton
                        key={evolution}
                        active={active}
                        onClick={() => setSelectedEvolution(active ? Evolution.NOW : evolution)}
                        className={evolution}
                    >
                        {title}
                    </MapButton>
                );
            })}
        </ButtonGroup>
    );
}
