/**
 * Copyright 2016 GeoSolutions Sas
 * Copyright 2016-2021 Sourcepole AG
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";

// Needed for IE11 to avoid 'Promise not defined' error in axios
import "core-js/stable";
import "regenerator-runtime/runtime";

import axios from "axios";
import Proj4js from "proj4";
import { register as olProj4Register } from "ol/proj/proj4";

import StandardStore from "qwc2/stores/StandardStore";
import WindowManager from "qwc2/components/WindowManager";

import { changeBrowserProperties } from "qwc2/actions/browser";
import { loadLocale } from "qwc2/actions/locale";
import { localConfigLoaded, setStartupParameters } from "qwc2/actions/localConfig";
import { themesLoaded, setCurrentTheme } from "qwc2/actions/theme";
import { setCurrentTask } from "qwc2/actions/task";

import ConfigUtils from "qwc2/utils/ConfigUtils";
import CoordinatesUtils from "qwc2/utils/CoordinatesUtils";
import MapUtils from "qwc2/utils/MapUtils";
import MiscUtils from "qwc2/utils/MiscUtils";
import { UrlParams, resolvePermaLink } from "qwc2/utils/PermaLinkUtils";
import ThemeUtils from "qwc2/utils/ThemeUtils";

import defaultLocaleData from "./fr-FR.json";
import "./QWC2.less";
import QWC2ThemeContext from "../../QWC2ThemeContext";

const CSRF_TOKEN = MiscUtils.getCsrfToken();

if (CSRF_TOKEN) {
    axios.interceptors.request.use(
        (config: any) => {
            if (["POST", "PUT", "PATCH", "DELETE"].includes(config.method.toUpperCase())) {
                config.headers["X-CSRF-TOKEN"] = CSRF_TOKEN;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
}

function AppInitComponent({ initialParams, theme: themeId }: { initialParams: any; theme: string }) {
    const { mapSize } = useSelector((state: any) => ({
        mapSize: state.map.size,
    }));
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);
    const [initialThemeId, setInitialThemeId] = useState(themeId);
    const themes = useContext(QWC2ThemeContext);
    useEffect(() => {
        if ((!initialized || themeId !== initialThemeId) && themes && mapSize) {
            setInitialized(true);
            setInitialThemeId(themeId);

            dispatch(themesLoaded(themes));

            // Resolve permalink and restore settings
            resolvePermaLink(initialParams, (params: any, state: any) => {
                dispatch(setStartupParameters(params));
                let theme = ThemeUtils.getThemeById(themes, themeId);
                const layerParams = params.l !== undefined ? params.l.split(",").filter((entry: any) => entry) : null;
                if (layerParams && ConfigUtils.getConfigProp("urlReverseLayerOrder")) {
                    layerParams.reverse();
                }
                const visibleBgLayer = params.bl || params.bl === "" ? params.bl : null;
                let initialView = null;
                if (theme) {
                    if (params.c && params.s !== undefined) {
                        const coords = params.c.split(/[;,]/g).map((x: string) => parseFloat(x));
                        const scales = theme.scales || themes.defaultScales;
                        const zoom = MapUtils.computeZoom(scales, params.s);
                        if (coords.length === 2) {
                            initialView = {
                                center: coords,
                                zoom: zoom,
                                crs: params.crs || theme.mapCrs,
                            };
                        }
                    } else if (params.e) {
                        const bounds = params.e.split(/[;,]/g).map((x: string) => parseFloat(x));
                        if (bounds.length === 4) {
                            initialView = {
                                bounds: bounds,
                                crs: params.crs || theme.mapCrs,
                            };
                        }
                    }
                }

                // Clear all params
                UrlParams.clear();

                // Restore theme and layers
                if (theme) {
                    try {
                        dispatch(
                            setCurrentTheme(
                                theme,
                                themes,
                                false,
                                initialView,
                                layerParams,
                                visibleBgLayer,
                                state.layers,
                            ),
                        );
                    } catch (e: any) {
                        console.log(e.stack);
                    }
                }
            });
            const task = ConfigUtils.getConfigProp("startupTask");
            if (task) {
                dispatch(setCurrentTask(task.key, task.mode, task.mapClickAction));
            }
        }
    }, [initialized, mapSize, themes, themeId]);

    return null;
}

export default function QWC2({ children, theme }: { children: any; theme: string }) {
    const [store] = useState(() => {
        StandardStore.init({
            defaultState: { mousePosition: { crs: "EPSG:2154" } },
            mobile: {},
        });
        return StandardStore.get();
    });
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (!initialized && store) {
            setInitialized(true);
            // Detect browser properties
            store.dispatch(changeBrowserProperties(ConfigUtils.getBrowserProperties()));

            // Load config.json
            const configParams = Object.entries(UrlParams.getParams()).reduce((res: any, [key, value]) => {
                if (key.startsWith("config:")) {
                    res[key.slice(7)] = value;
                }
                return res;
            }, {});
            ConfigUtils.loadConfiguration(configParams).then((config: any) => {
                store.dispatch(localConfigLoaded(config));
                // Dispatch user locale
                store.dispatch(loadLocale(defaultLocaleData, "fr-FR"));

                // Add projections from config
                for (const proj of config.projections || []) {
                    if (Proj4js.defs(proj.code) === undefined) {
                        Proj4js.defs(proj.code, proj.proj);
                    }
                    CoordinatesUtils.setCrsLabels({ [proj.code]: proj.label });
                }

                olProj4Register(Proj4js);
            });
        }
    }, [initialized, store]);

    // Save initial params before they get overwritten
    const [initialParams] = useState(UrlParams.getParams);
    const [touchY, setTouchY] = useState(0);

    useEffect(() => {
        const computeVh = () => {
            // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
            document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
        };
        computeVh();

        window.addEventListener("resize", computeVh);
        return () => window.removeEventListener("resize", computeVh);
    });

    const preventOverscroll = useCallback(
        (ev) => {
            if (ev.touches[0].touchType !== "direct") {
                // Don't do anything for stylus inputs
                return;
            }
            let scrollEvent = false;
            let element = ev.target;
            const direction = ev.targetTouches[0].clientY - touchY;
            setTouchY(ev.targetTouches[0].clientY);
            while (!scrollEvent && element) {
                let scrollable = element.scrollHeight > element.clientHeight;
                // Workaround for resizeable-window having scrollHeight > clientHeight even though it has no scrollbar
                if (element.classList.contains("resizeable-window")) {
                    scrollable = false;
                }
                if (element.type === "range") {
                    // If it is a range element, treat it as a scroll event
                    scrollEvent = true;
                } else if (
                    scrollable &&
                    element.scrollTop + element.clientHeight < element.scrollHeight &&
                    direction < 0
                ) {
                    // User scrolls down and element is not at end of scroll
                    scrollEvent = true;
                } else if (scrollable && element.scrollTop > 0 && direction > 0) {
                    // User scrolls up and element is not at start of scroll
                    scrollEvent = true;
                } else {
                    element = element.parentElement;
                }
            }
            if (!scrollEvent) {
                ev.preventDefault();
            }
        },
        [touchY],
    );
    const setupTouchEvents = useCallback(
        (el) => {
            el?.addEventListener(
                "touchstart",
                (ev: any) => {
                    setTouchY(ev.targetTouches[0].clientY);
                },
                { passive: false },
            );
            el?.addEventListener("touchmove", preventOverscroll, { passive: false });
        },
        [preventOverscroll],
    );

    return (
        <Provider store={store}>
            <div className="wrapper" ref={setupTouchEvents}>
                <AppInitComponent initialParams={initialParams} theme={theme} />
                {children}
                <WindowManager />
            </div>
        </Provider>
    );
}
