import React from "react";
import { RainingCloudIcon } from "../../../Icons/RainingCloudIcon";
import { WaveIcon } from "../../../Icons/WaveIcon";
import { WindDirectionArrowIcon } from "../../../Icons/WindDirectionArrowIcon";
import { IWeatherForecast } from "../fetchOpenMeteo";
import "./MeteoCard.less";

export const MeteoCard = ({
    time,
    precipitation_sum,
    windspeed_10m_max,
    winddirection_10m_dominant,
    temperature_2m,
    pressure_msl,
}: IWeatherForecast) => {
    const formatTime = (ts: string) => {
        const daysOfWeek = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
        const date = new Date(ts);
        let dayOfWeek = date.getDay() - 1;
        if (dayOfWeek === -1) {
            dayOfWeek = daysOfWeek.length - 1;
        }

        return `${daysOfWeek[dayOfWeek]} ${date.getDate()}/${date.getMonth() + 1}`;
    };
    const weatherData = [
        {
            value: temperature_2m,
            unit: "°C",
        },
        {
            value: precipitation_sum,
            unit: "mm",
            icon: <RainingCloudIcon />,
            iconInline: true,
        },
        {
            value: windspeed_10m_max,
            unit: "km/h",
            icon: <WindDirectionArrowIcon style={{ transform: `rotate(${winddirection_10m_dominant - 45}deg)` }} />,
            iconInline: false,
        },
        {
            value: pressure_msl,
            unit: "hPa",
            icon: <WaveIcon />,
            iconInline: true,
        },
    ];
    return (
        <div className="meteo-card">
            <div className="day">{formatTime(time)}</div>
            {weatherData.map(({ value, unit, icon, iconInline }, index) => {
                return (
                    <div key={index} className="weather-info">
                        <div className="weather-info-inner">
                            {iconInline && icon}
                            <b>{value}</b>
                            <span>{unit}</span>
                        </div>
                        {!iconInline && icon}
                    </div>
                );
            })}
        </div>
    );
};
