import React from "react";
import { InfoCRS } from "./components/InfoCRS";
import { InfoCoordinates } from "./components/InfoCoordinates";
import { InfoScaleSelector } from "./components/InfoScaleSelector";
import "./InfoPanelPlugin.less";
import InfoMentions from "./components/InfoMentions";

export const InfoPanelPlugin = () => {
    return (
        <div className="info-panel">
            <InfoScaleSelector />
            <InfoCoordinates />
            <InfoCRS />
            <InfoMentions />
        </div>
    );
};
