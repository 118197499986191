import React, { useState } from "react";
import classNames from "classnames";
import { Button, Tooltip } from "antd";
import useOLResize from "../../useOLResize";
import {
    FilterOutlined,
    CloseOutlined,
    DoubleRightOutlined,
    CloseCircleFilled,
    ExperimentOutlined,
} from "@ant-design/icons";
import LayersMenuFilteringPanel from "./LayersMenuFilteringPanel";
import useFilteringPanel from "./useFilteringPanel";
import "./LayersMenuPlugin.less";
import useCurrentModule from "../../useCurrentModule";
import { LayerTreeWrapper } from "../LayerTree/LayerTreeWrapper";
import { useWMSLayer } from "../../useWMSLayer";
import LayersMenuDataPanel from "./LayersMenuDataPanel";

interface ILayersMenuPlugin {
    enableDataPanel?: boolean;
    onStudySelected?: (studyId?: number) => void;
    selectedStudyId?: number;
}

export const LayersMenuPlugin = ({ enableDataPanel, onStudySelected, selectedStudyId }: ILayersMenuPlugin) => {
    const WMSLayer = useWMSLayer();
    const [isLeftPanelOpen, setisLeftPanelOpen] = useState(true);
    const [isFilteringPanelOpen, setIsFilteringPanelOpen] = useState(false);
    const [isDataPanelOpen, setIsDataPanelOpen] = useState(false);
    const [currentModule] = useCurrentModule();
    const projectId = selectedStudyId !== undefined ? selectedStudyId : currentModule?.defaultProjectId;
    const { visibleFilterList, filterLayers, canFilter, filtered, resetFilters, formRef } = useFilteringPanel(
        WMSLayer,
        projectId,
    );
    const resizeOLMap = useOLResize(200);

    return (
        <>
            <button
                className={classNames("layers-menu-open-button", { hidden: isLeftPanelOpen })}
                onClick={() => {
                    setisLeftPanelOpen(true);
                    resizeOLMap();
                }}
            >
                <DoubleRightOutlined />
            </button>
            <div
                className={classNames("layers-menu", {
                    open: isLeftPanelOpen,
                    "extra-left-panel-open": isFilteringPanelOpen || isDataPanelOpen,
                    filtering: isFilteringPanelOpen,
                    "data-edit": isDataPanelOpen,
                })}
            >
                <div className="layers-menu-inner" role="body">
                    <button
                        className="close-button"
                        onClick={() => {
                            setisLeftPanelOpen(false);
                            setIsFilteringPanelOpen(false);
                            resizeOLMap();
                        }}
                    >
                        <CloseOutlined />
                    </button>
                    <div className="description">
                        <img src={currentModule?.img} alt="drawer header" />
                        <h1>{currentModule?.description}</h1>
                        {enableDataPanel && (
                            <>
                                <p>Étudier les données via le bouton ci-dessous.</p>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => {
                                        setIsDataPanelOpen(true);
                                        setIsFilteringPanelOpen(false);
                                    }}
                                >
                                    <ExperimentOutlined /> Étudier
                                </Button>
                            </>
                        )}
                    </div>

                    <div className="filter-wrapper">
                        <h2>Afficher des données</h2>
                        <Tooltip title={"Le filtrage fonctionne uniquement avec les données physico-chimiques"}>
                            <Button
                                type="primary"
                                className={classNames({ filtered })}
                                onClick={() => {
                                    setIsFilteringPanelOpen(true);
                                    setIsDataPanelOpen(false);
                                    resizeOLMap();
                                }}
                                disabled={!canFilter}
                                data-test="filtering-button"
                            >
                                <FilterOutlined /> Filtrage{" "}
                                {filtered && (
                                    <CloseCircleFilled
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            resetFilters();
                                        }}
                                        style={{ fontSize: "1.2em", verticalAlign: "bottom" }}
                                    />
                                )}
                            </Button>
                        </Tooltip>
                    </div>
                    <LayerTreeWrapper WMSLayer={WMSLayer} className="layer-tree-wrapper-margin" />
                </div>
                <LayersMenuFilteringPanel
                    onClose={() => {
                        setIsFilteringPanelOpen(false);
                        resizeOLMap();
                    }}
                    visibleFilterList={visibleFilterList}
                    filterLayers={filterLayers}
                    formRef={formRef}
                />
                {enableDataPanel && (
                    <LayersMenuDataPanel
                        onStudySelected={onStudySelected}
                        onClose={() => {
                            setIsDataPanelOpen(false);
                            resizeOLMap();
                        }}
                    />
                )}
            </div>
        </>
    );
};
