import React from "react";
import { Skeleton, Table } from "antd";
import "./WaterwayList.less";
import classNames from "classnames";
import { ColumnsType } from "antd/lib/table";

interface IWaterwayList {
    waterways: any[];
    isLoading: boolean;
    className: string;
    columns: ColumnsType<any>;
}

export default function WaterwaysList({ waterways, isLoading, className, columns }: IWaterwayList) {
    return (
        <div className={classNames("waterways", className)}>
            <Skeleton loading={isLoading} active>
                <Table
                    dataSource={waterways}
                    columns={columns}
                    pagination={false}
                    size="small"
                    rowClassName="waterway-row"
                />
            </Skeleton>
        </div>
    );
}
