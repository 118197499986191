import React, { useState } from "react";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";

interface IFoldablePlanningSection {
    children: React.ReactNode;
    label: string;
    isFoldable: boolean;
}

export const FoldablePlanningSection = ({ children, isFoldable, label }: IFoldablePlanningSection) => {
    const [expanded, setExpanded] = useState(true);
    return (
        <section className="foldable-planning-section">
            <h1>
                <button
                    style={{ cursor: isFoldable ? "pointer" : "default" }}
                    onClick={() => isFoldable && setExpanded((oldState) => !oldState)}
                >
                    {isFoldable && expanded && <CaretDownOutlined />}
                    {isFoldable && !expanded && <CaretRightOutlined />}
                    <span>{label}</span>
                </button>
                <span />
            </h1>
            {expanded && children}
        </section>
    );
};
