import React from "react";
import { Button } from "antd";
import classNames from "classnames";
import "./ButtonSwitch.less";
import { CloseOutlined } from "@ant-design/icons";

interface IButtonSwitch {
    buttons: {
        label: string;
        disabled?: boolean;
    }[];
    value: number;
    onChange: (value: number) => void;
    onClose?: (value: number) => void;
    buttonClassName?: string;
    className?: string;
}

export const ButtonSwitch = ({ buttons, value, onChange, buttonClassName, className, onClose }: IButtonSwitch) => {
    const handleClose = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, index: number) => {
        event.stopPropagation();
        onClose && onClose(index);
    };

    return (
        <div className={classNames("button-switch-wrapper", className)}>
            {buttons.map(({ label, disabled }, index) => (
                <Button
                    key={label}
                    disabled={disabled}
                    type="text"
                    className={classNames(buttonClassName, {
                        active: label === (buttons[value] && buttons[value].label),
                    })}
                    onClick={() => onChange(index)}
                >
                    {label}

                    {onClose && <CloseOutlined onClick={(event) => handleClose(event, index)} />}
                </Button>
            ))}
        </div>
    );
};
