import React, { createContext, useEffect, useState } from "react";
import { fetchQWC2Theme } from "./Shared/utils/fetch";

const QWC2ThemeContext = createContext<any | null>(null);

export function QWC2ThemeProvider({ children }: { children: JSX.Element }) {
    const [themes, setThemes] = useState();

    useEffect(() => {
        fetchQWC2Theme().then(({ themes }: any) => {
            setThemes(themes);
        });
    }, []);
    return <QWC2ThemeContext.Provider value={themes}>{children}</QWC2ThemeContext.Provider>;
}

export default QWC2ThemeContext;
