import React from "react";

export default function LogInIcon() {
    return (
        <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1866_27980)">
                <path
                    d="M13.7744 12.65H7C7.42607 8.35495 11.0595 5 15.4787 5C20.185 5 24 8.80545 24 13.5C24 18.1946 20.185 22 15.4787 22C11.0604 22 7.42692 18.6451 7 14.35H13.7744V16.9L18.0351 13.5L13.7744 10.1V12.65Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_1866_27980">
                    <rect width="30" height="28" rx="5" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
