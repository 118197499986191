import React, { useContext } from "react";

import { ECBottomPanelPlugin } from "./ECBottomPanelPlugin/ECBottomPanelPlugin";
import { InfoPanelPlugin } from "../Shared/components/InfoPanelPlugin/InfoPanelPlugin";
import { LayoutContextProvider, LayoutContext } from "../Shared/contexts/LayoutContextProvider";
import { OLContextProvider } from "qwc2/contexts/OLContext";
import { LayersMenuPlugin } from "../Shared/components/LayersMenu/LayersMenuPlugin";
import ModuleTopBar from "../Shared/components/ModuleTopBar";

import QWC2 from "../Shared/QWC2/QWC2";
import { MapModal } from "../Shared/components/MapModal/MapModal";
import { ButtonsPlugin } from "../Shared/components/ButtonsPlugin/ButtonsPlugin";
import Map from "../Shared/components/Map";

const EnvironmentalCharacteristicsLayout = () => {
    const { onMapModal } = useContext(LayoutContext);
    return (
        <div className="wrapper plugin-container flex column">
            <ModuleTopBar />
            <div className="plugin-container flex row grow">
                <LayersMenuPlugin />
                <div className="plugin-container flex column grow overflow">
                    <div className="plugin-container grow wrapper flex flex-end">
                        <Map />
                        <ButtonsPlugin toolbox info />
                        {onMapModal && <MapModal {...onMapModal} />}
                        <InfoPanelPlugin />
                    </div>
                    <ECBottomPanelPlugin />
                </div>
            </div>
        </div>
    );
};

export const EnvironmentalCharacteristicsModule = () => {
    return (
        <QWC2 theme="scan/gpmb_carac_milieu">
            <OLContextProvider>
                <LayoutContextProvider>
                    <EnvironmentalCharacteristicsLayout />
                </LayoutContextProvider>
            </OLContextProvider>
        </QWC2>
    );
};
