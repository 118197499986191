import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import "./MenuModal.less";

interface IMenuModal {
    onClose: () => void;
    children: React.ReactNode;
    headerClassName?: string;
    title: string;
    icon?: React.ReactNode;
}

export const MenuModal = ({ onClose, children, headerClassName, title, icon }: IMenuModal) => {
    return (
        <div className="menu-modal-wrapper">
            <header className={headerClassName}>
                <div>
                    {icon}
                    <h1>{title}</h1>
                </div>
                <Button type="text" onClick={onClose}>
                    <CloseOutlined />
                </Button>
            </header>
            <section>{children}</section>
        </div>
    );
};
