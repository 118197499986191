import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { changeLayerProperty } from "qwc2/actions/layers";
import { useWMSLayer } from "./useWMSLayer";

// return a [key][filterValue] Array
const getFilterDict = (WMSFilter?: string) => WMSFilter?.split(";").map((entry) => entry.split(":")) ?? [];
const getFilterValueFromDict = (filterDict: string[][]) => filterDict.map((keyValue) => keyValue.join(":")).join(";");

export default function useFILTER() {
    const WMSLayer = useWMSLayer();
    const WMSUuid = WMSLayer?.uuid;
    const WMSFILTERValue = WMSLayer?.dimensionValues?.FILTER;
    const dispatch = useDispatch();
    const filter = useCallback(
        (value: string) => {
            if (value !== WMSFILTERValue) {
                dispatch(
                    changeLayerProperty(WMSUuid, "dimensionValues", {
                        ...WMSLayer?.dimensionValues,
                        FILTER: value,
                    }),
                );
            }
        },
        [WMSFILTERValue, WMSLayer?.dimensionValues, WMSUuid, dispatch],
    );
    const removeFilterKeys = useCallback(
        (keysToRemove: string[]) => {
            if (WMSFILTERValue) {
                filter(
                    getFilterValueFromDict(
                        getFilterDict(WMSFILTERValue).filter(([key]) => !keysToRemove.includes(key)),
                    ),
                );
            }
        },
        [WMSFILTERValue, filter],
    );
    const setFilterKey = useCallback(
        (key: string, value: string) => {
            const filterDict = getFilterDict(WMSFILTERValue);
            const filterDictKeyIndex = filterDict.findIndex(([k]) => k === key);
            if (filterDictKeyIndex >= 0) {
                filterDict[filterDictKeyIndex] = [key, value];
            } else {
                filterDict.push([key, value]);
            }
            filter(getFilterValueFromDict(filterDict));
        },
        [WMSFILTERValue, filter],
    );
    const setFilterKeys = useCallback(
        (keyValueTuple: [string, string][]) => {
            const filterDict = getFilterDict(WMSFILTERValue);
            keyValueTuple.forEach(([key, value]) => {
                const filterDictKeyIndex = filterDict.findIndex(([k]) => k === key);
                if (filterDictKeyIndex >= 0) {
                    filterDict[filterDictKeyIndex] = [key, value];
                } else {
                    filterDict.push([key, value]);
                }
            });
            filter(getFilterValueFromDict(filterDict));
        },
        [WMSFILTERValue, filter],
    );
    const getFilterValueFromKey = useCallback(
        (keyToFind) =>
            WMSFILTERValue
                ? getFilterDict(WMSFILTERValue)
                      .find(([key]) => key === keyToFind)
                      ?.pop()
                : undefined,
        [WMSFILTERValue],
    );
    return { removeFilterKeys, setFilterKey, setFilterKeys, getFilterValueFromKey };
}
