import { EModuleID } from "../../../Enums/EModuleID";
import { IBookmark } from "./types";

const defaultBookmarks: IBookmark[] = [
    {
        id: 1,
        text: "Le Verdon",
        crs: "EPSG:3857",
        bbox: [-125257.18838678644, 5706146.80924722, -108577.8550534531, 5711226.80924722],
    },
    {
        id: 2,
        text: "Pauillac",
        crs: "EPSG:3857",
        bbox: [-95058.68200309375, 5647865.377335508, -61700.015336427095, 5658025.377335508],
    },
    {
        id: 3,
        text: "Ambès",
        crs: "EPSG:3857",
        bbox: [-68385.40362868151, 5621072.089342992, -51706.07029534818, 5626152.089342992],
    },
    {
        id: 4,
        text: "Bordeaux - Salinité",
        crs: "EPSG:3857",
        bbox: [-106952.84479080586, 5587831.251752531, -23556.178124139195, 5612278.751752531],
        activeLayers: ["salinite_fond", "salinite_surface", "openstreetmap"],
    },
];

export const bookmarks: Record<EModuleID, IBookmark[]> = {
    [EModuleID.CARAC_MILIEU]: [...defaultBookmarks],
    [EModuleID.DRAGGAGE_STRATEGIE_ANNUELLE]: [...defaultBookmarks],
    [EModuleID.DRAGGAGE_COMMISSION_SEUILS]: [...defaultBookmarks],
    [EModuleID.NAVIGABILITE]: [...defaultBookmarks],
    [EModuleID.GARONNE_2050]: [...defaultBookmarks],
    [EModuleID.ETUDES]: [...defaultBookmarks],
};
