import { IMarker } from "../../useMarkers";
import { queryBuilder } from "../../utils/querybuilder";
import CoordinatesUtils from "qwc2/utils/CoordinatesUtils";

export interface IWeatherForecast {
    time: string;
    precipitation_sum: number;
    windspeed_10m_max: number;
    winddirection_10m_dominant: number;
    temperature_2m: number;
    pressure_msl: number;
}

const BASE_URL = "https://api.open-meteo.com/v1/forecast";

const hourlyWeatherParams = ["pressure_msl", "temperature_2m"];
const dailyWeatherParams = ["precipitation_sum", "windspeed_10m_max", "winddirection_10m_dominant"];

const getWeatherForecast = (apiResponse: any): IWeatherForecast[] => {
    const { daily, hourly } = apiResponse;

    return daily.time
        .map((time: string, index: number) => {
            return dailyWeatherParams.reduce(
                (acc: any, cur) => {
                    acc[cur] = daily[cur][index];

                    return acc;
                },
                { time },
            );
        })
        .map(({ time, ...rest }: IWeatherForecast) => {
            const {
                0: firstIndex,
                length: l,
                [l - 1]: lastIndex,
            } = hourly.time
                .map((hourTs: string, index: number) => (hourTs.includes(time) ? index : undefined))
                .filter((index: number) => index != undefined);
            const dailyPressures = hourly.pressure_msl.slice(firstIndex, lastIndex);
            const dailyTemperatures = hourly.temperature_2m.slice(firstIndex, lastIndex);

            return {
                time,
                ...rest,
                pressure_msl: Math.round(
                    dailyPressures.reduce((acc: number, cur: number) => acc + cur, 0) / dailyPressures.length,
                ),
                temperature_2m: Math.round(
                    dailyTemperatures.reduce((acc: number, cur: number) => acc + cur, 0) / dailyTemperatures.length,
                ),
            };
        });
};

export const fetchOpenMeteo = async (marker: IMarker, mapcrs: string) => {
    if (!marker || !marker.coords || marker.coords.length !== 2) {
        return;
    }
    const [coords4326Long, coords4326Lat] = CoordinatesUtils.reproject(marker.coords, mapcrs, "EPSG:4326");
    const query = queryBuilder(BASE_URL, {
        longitude: coords4326Long.toString(),
        latitude: coords4326Lat.toString(),
        daily: dailyWeatherParams.toString(),
        hourly: hourlyWeatherParams.toString(),
        timezone: "Europe/Paris",
    });
    const response = await fetch(query);
    const responseJSON = await response.json();
    const weatherForecast = getWeatherForecast(responseJSON);
    return weatherForecast;
};
