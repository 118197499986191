import React, { useCallback, useMemo, useState } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { IMouvementsAnnouncementDTO, useShipMouvements } from "../useShipMouvements";
import classNames from "classnames";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getDateAndTimeFromVigieSipFormat } from "../../Shared/utils/date";
import { EShipDirection } from "../terminalsGraph";
import "./NavigabilityShipSelect.less";
import moment from "moment";

interface INavigabilityShipMouvement {
    from: number;
    to: number;
    arrivalTimestamp: string;
    departureTimestamp: string;
    tirant_eau: number;
    direction: EShipDirection;
    type: "DS" | "DPQ" | "DH";
    longueur: number;
    largeur: number;
}

export interface INavigabilityShipSelectForm {
    selectedMouvement: INavigabilityShipMouvement;
    shipSpeed: number;
    blocCoef: number;
}

interface INavigabilityShipSelect {
    onChange: (value: INavigabilityShipSelectForm) => void;
}

const DEFAULT_SHIP_SPEED = 10;
const DEFAULT_BLOC_COEF = 0.8;

export const NavigabilityShipSelect = ({ onChange }: INavigabilityShipSelect) => {
    const [form] = Form.useForm();
    const [selectedAnnoucement, setSelectedAnnoucement] = useState<IMouvementsAnnouncementDTO | null>(null);
    const [selectedMouvementIndex, setSelectedMouvementIndex] = useState<number>();
    const [isOnShipSelection, setIsOnShipSelection] = useState(false);
    const { shipMouvements, isLoading } = useShipMouvements();

    const getAnnoucement = useCallback(
        (annoucementId: number) => {
            const selectedAnnoucement = shipMouvements?.find((annoucement) => annoucement.ANNONCE === annoucementId);

            return selectedAnnoucement;
        },
        [shipMouvements],
    );

    const availableShipsOptions = useMemo(() => {
        return shipMouvements?.map((annoucement) => ({
            value: annoucement.ANNONCE,
            label: annoucement.INFOS_NAVIRE.NAVIRE,
        }));
    }, [shipMouvements]);

    const availableMouvements = useMemo(() => {
        if (!selectedAnnoucement) {
            return [];
        }
        const info_navire = selectedAnnoucement.INFOS_NAVIRE;
        const getTerminalFromDTO = (value: string) => parseInt(value.split(" ")[0]);

        const formattedMouvements = selectedAnnoucement.MOUVEMENTS.map(
            ({ ORIGINE, DESTINATION, TYPE, ETA, ETD, TE }, index) => {
                const from = ORIGINE && getTerminalFromDTO(ORIGINE);
                const to = DESTINATION && getTerminalFromDTO(DESTINATION);
                const departureTimestamp = ETD && getDateAndTimeFromVigieSipFormat(ETD);
                const arrivalTimestamp = ETA && getDateAndTimeFromVigieSipFormat(ETA);
                const tirant_eau = TE ?? info_navire.TE_ETE;

                switch (TYPE) {
                    case "DPQ":
                        return {
                            from: 1000,
                            to,
                            arrivalTimestamp,
                            tirant_eau,
                            direction: EShipDirection.ASC,
                            value: index,
                            label: `Arrivée à ${DESTINATION} le ${new Date(arrivalTimestamp).toLocaleDateString()}`,
                            type: TYPE,
                            longueur: info_navire.LONGUEUR,
                            largeur: info_navire.LARGEUR,
                        };
                    case "DS":
                        return {
                            from,
                            to: 1000,
                            departureTimestamp,
                            tirant_eau,
                            direction: EShipDirection.DESC,
                            value: index,
                            label: `Départ de ${ORIGINE} le ${new Date(departureTimestamp).toLocaleDateString()}`,
                            type: TYPE,
                            longueur: info_navire.LONGUEUR,
                            largeur: info_navire.LARGEUR,
                        };
                    default:
                        return {
                            from,
                            to,
                            arrivalTimestamp,
                            departureTimestamp,
                            tirant_eau,
                            direction: from > to ? EShipDirection.DESC : EShipDirection.ASC,
                            value: index,
                            label: `Trajet de ${ORIGINE} à ${DESTINATION} le ${new Date(
                                departureTimestamp,
                            ).toLocaleDateString()}`,
                            type: TYPE,
                            longueur: info_navire.LONGUEUR,
                            largeur: info_navire.LARGEUR,
                        };
                }
            },
        );

        return formattedMouvements;
    }, [selectedAnnoucement]);

    const onFinish = (values: any) => {
        const { mouvement, shipSpeed, blocCoef, date } = values;
        const selectedMouvement = availableMouvements.find(
            ({ value }) => value === mouvement,
        ) as INavigabilityShipMouvement;
        selectedMouvement.type === "DPQ"
            ? (selectedMouvement.arrivalTimestamp = date.toISOString())
            : (selectedMouvement.departureTimestamp = date.toISOString());

        onChange({
            selectedMouvement,
            shipSpeed: Number.isNaN(parseFloat(shipSpeed)) ? DEFAULT_SHIP_SPEED : parseFloat(shipSpeed),
            blocCoef: Number.isNaN(parseFloat(blocCoef)) ? DEFAULT_BLOC_COEF : parseFloat(blocCoef),
        });
    };

    const selectedMouvement = selectedMouvementIndex !== undefined && availableMouvements[selectedMouvementIndex];

    return (
        <div className={classNames("ship-selection-wrapper", { selection: isOnShipSelection })}>
            {isOnShipSelection ? (
                <Form onFinish={onFinish} form={form}>
                    <Form.Item
                        label="Sélectionner votre navire"
                        name="ship"
                        rules={[{ required: true, message: "La sélection d'un navire est obligatoire" }]}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder="Sélectionner votre navire"
                            disabled={isLoading}
                            allowClear
                            options={availableShipsOptions}
                            onChange={(value: number) => {
                                setSelectedMouvementIndex(undefined);
                                form.resetFields(["mouvement", "date"]);
                                setSelectedAnnoucement(getAnnoucement(value) || null);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Sélectionner un trajet"
                        name="mouvement"
                        rules={[{ required: true, message: "La sélection d'un trajet est obligatoire" }]}
                    >
                        <Select
                            placeholder="Sélectionner un trajet"
                            disabled={availableMouvements.length === 0}
                            options={availableMouvements}
                            onChange={(value: number) => {
                                setSelectedMouvementIndex(value);
                                form.resetFields(["date"]);
                            }}
                        />
                    </Form.Item>
                    {selectedMouvement && (
                        <>
                            <Form.Item
                                label={selectedMouvement.type === "DPQ" ? "Arrivée prévue" : "Départ prévu"}
                                name="date"
                                rules={[{ required: true, message: "La date prévue est obligatoire" }]}
                                initialValue={
                                    selectedMouvement.type === "DPQ"
                                        ? moment(selectedMouvement.arrivalTimestamp)
                                        : moment(selectedMouvement.departureTimestamp)
                                }
                            >
                                <DatePicker format="DD/MM/YYYY HH:mm" showTime />
                            </Form.Item>
                            <div className="form-block">
                                <Form.Item initialValue={DEFAULT_SHIP_SPEED} label="Vitesse moyenne" name="shipSpeed">
                                    <Input
                                        placeholder={DEFAULT_SHIP_SPEED.toString()}
                                        type="number"
                                        min="0"
                                        addonAfter="noeuds"
                                    />
                                </Form.Item>
                                <Form.Item
                                    initialValue={DEFAULT_BLOC_COEF}
                                    label={
                                        <div>
                                            Coefficient de bloc{" "}
                                            <a
                                                href="https://fr.wikipedia.org/wiki/Coefficient_de_bloc"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <InfoCircleOutlined />
                                            </a>
                                        </div>
                                    }
                                    name="blocCoef"
                                >
                                    <Input
                                        placeholder={DEFAULT_BLOC_COEF.toString()}
                                        min="0"
                                        max="1"
                                        step=".1"
                                        type="number"
                                    />
                                </Form.Item>
                            </div>
                        </>
                    )}

                    <Button htmlType="submit" type="primary">
                        Voir les itinéraires
                    </Button>
                </Form>
            ) : (
                <>
                    <h1>Visualiser et évaluer votre itinéraire</h1>
                    <Button onClick={() => setIsOnShipSelection(true)} type="primary">
                        Sélectionner un bateau
                    </Button>
                </>
            )}
        </div>
    );
};
