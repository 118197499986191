import { ForkOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { fetchGitLab, FetchMethods } from "../../../utils/fetch";
import { IStudy } from "../LayersMenuDataPanel";
import GitLabProjectNameInput from "./GitLabProjectNameInput";
import "./DataNewStudyCreateForm.less";

export default function DataNewStudyCreateForm({ onStudyCreated }: { onStudyCreated: (study: IStudy) => void }) {
    const [initialized, setInitialized] = useState(false);
    const [models, setModels] = useState<{ id: number; name: string; path: string }[]>([]);
    const [selectedModelId, setSelectedModelId] = useState<number>();
    const [studyName, setStudyName] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchModels = async () => {
            setInitialized(false);
            try {
                const groups = (await fetchGitLab(`groups/${process.env.REACT_APP_GIT_MODELES_TELEMAC_GROUP_ID}`))
                    ?.projects;
                // Filter groups on "Houle2D" (id=598) and "Gironde XL 3D" (id=552)
                const filteredGroups = groups.filter(({ id }: { id: number }) => id === 552 || id === 598);
                setModels(
                    filteredGroups.map(({ id, name, path }: { id: number; name: string; path: string }) => ({
                        id,
                        name,
                        path,
                    })),
                );
            } catch (err) {
                console.error(err);
            } finally {
                setInitialized(true);
            }
        };
        fetchModels();
    }, []);
    const canSubmit = initialized && selectedModelId && studyName && !loading;
    return (
        <div className="data-new-study-create-form">
            <h4>Créer une nouvelle Étude</h4>
            <Space direction="vertical">
                <GitLabProjectNameInput onChange={setStudyName} />

                <Input.Group compact>
                    <Select
                        placeholder="Sélectionner"
                        options={models.map(({ id, name }) => ({ label: name, key: id, value: id }))}
                        showSearch
                        onChange={setSelectedModelId}
                    />
                    <Button
                        type="primary"
                        block
                        disabled={!canSubmit}
                        onClick={async () => {
                            if (canSubmit) {
                                setLoading(true);
                                try {
                                    const { id: projectId } = await fetchGitLab(
                                        `projects/${selectedModelId}/fork`,
                                        FetchMethods.POST,
                                        {
                                            name: studyName,
                                            path: studyName,
                                            namespace_id: process.env.REACT_APP_GIT_TELEMAC_FORK_TARGET_GROUP_ID,
                                            mr_default_target_self: false,
                                            visibility: "internal",
                                        },
                                    );

                                    const selectedModel = models.find(({ id }) => id === selectedModelId);
                                    const checkProjectImportStatus = async () => {
                                        const project = await fetchGitLab(`projects/${projectId}`);
                                        if (selectedModel && project.import_status === "finished") {
                                            setLoading(false);
                                            const { id, name, path, web_url, namespace, default_branch } = project;
                                            onStudyCreated({
                                                id,
                                                name,
                                                path,
                                                web_url,
                                                namespace_id: namespace?.id,
                                                fromModelId: selectedModelId,
                                                fromModelName: selectedModel?.name,
                                                fromModelPath: selectedModel?.path,
                                                default_branch: default_branch,
                                            });
                                        } else {
                                            window.setTimeout(checkProjectImportStatus, 2000);
                                        }
                                    };
                                    checkProjectImportStatus();
                                } catch (err) {
                                    console.error(err);
                                    setLoading(false);
                                }
                            }
                        }}
                    >
                        <ForkOutlined /> Créer {loading && <LoadingOutlined />}
                    </Button>
                </Input.Group>
            </Space>
        </div>
    );
}
