import React from "react";
import { Tabs } from "antd";
import WaterwayList from "../../../Shared/components/WaterwayList/WaterwayList";
import dragageImg from "../../../Home/images/IMG_draguage.png";
import { LayerTreeWrapper } from "../../../Shared/components/LayerTree/LayerTreeWrapper";
import { useWMSLayer } from "../../../Shared/useWMSLayer";
import { WaterwayQuotationsData, WaterwayType } from "../../../Shared/useWaterways";
import useWaterwayQuotationColumns from "../useWaterwayQuotationColumns";

interface IThresholdCommissionLeftBar {
    fairways: WaterwayQuotationsData[];
    defiles: WaterwayQuotationsData[];
    isLoading: boolean;
}

enum ThresholdCommissionTabKeys {
    LAYERS = "layers",
    FAIRWAYS = "fairways",
    DEFILES = "defiles",
}

export const ThresholdCommissionLeftBar = ({ fairways, defiles, isLoading }: IThresholdCommissionLeftBar) => {
    const WMSLayer = useWMSLayer();
    const fairwayColumns = useWaterwayQuotationColumns(fairways, WaterwayType.FAIRWAY);
    const defileColumns = useWaterwayQuotationColumns(defiles, WaterwayType.DEFILE);

    return (
        <>
            <div className="description">
                <img src={dragageImg} alt="dragage" />
                <h1>Commission des seuils</h1>
            </div>
            <Tabs
                items={[
                    {
                        label: "Passes",
                        children: (
                            <WaterwayList
                                waterways={fairways}
                                columns={fairwayColumns}
                                isLoading={isLoading}
                                className={WaterwayType.FAIRWAY}
                            />
                        ),
                        key: ThresholdCommissionTabKeys.FAIRWAYS,
                    },
                    {
                        label: "Souilles",
                        children: (
                            <WaterwayList
                                waterways={defiles}
                                columns={defileColumns}
                                isLoading={isLoading}
                                className={WaterwayType.DEFILE}
                            />
                        ),
                        key: ThresholdCommissionTabKeys.DEFILES,
                    },
                    {
                        label: "Couches",
                        children: <LayerTreeWrapper WMSLayer={WMSLayer} />,
                        key: ThresholdCommissionTabKeys.LAYERS,
                    },
                ]}
                defaultActiveKey={ThresholdCommissionTabKeys.FAIRWAYS}
                className="threshold-commission-tabs"
            />
        </>
    );
};
