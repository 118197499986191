import React, { useContext, useState } from "react";

import { ECBottomPanelPlugin } from "../EnvironmentalCharacteristics/ECBottomPanelPlugin/ECBottomPanelPlugin";
import { InfoPanelPlugin } from "../Shared/components/InfoPanelPlugin/InfoPanelPlugin";
import { OLContextProvider } from "qwc2/contexts/OLContext";
import { LayersMenuPlugin } from "../Shared/components/LayersMenu/LayersMenuPlugin";
import ModuleTopBar from "../Shared/components/ModuleTopBar";

import QWC2 from "../Shared/QWC2/QWC2";
import { MapModal } from "../Shared/components/MapModal/MapModal";
import { LayoutContext, LayoutContextProvider } from "../Shared/contexts/LayoutContextProvider";
import { ButtonsPlugin } from "../Shared/components/ButtonsPlugin/ButtonsPlugin";
import Map from "../Shared/components/Map";

interface IStudiesModuleLayout {
    onStudySelected: (studyId?: number) => void;
    selectedStudyId?: number;
}

export default function StudiesModuleLayout({ onStudySelected, selectedStudyId }: IStudiesModuleLayout) {
    const { onMapModal } = useContext(LayoutContext);

    return (
        <div className="wrapper plugin-container flex column">
            <ModuleTopBar />
            <div className="plugin-container flex row grow">
                <LayersMenuPlugin enableDataPanel onStudySelected={onStudySelected} selectedStudyId={selectedStudyId} />
                <div className="plugin-container flex column grow overflow">
                    <div className="plugin-container grow wrapper flex flex-end">
                        <Map />
                        <ButtonsPlugin toolbox info />
                        {onMapModal && <MapModal {...onMapModal} />}
                        <InfoPanelPlugin />
                    </div>
                    <ECBottomPanelPlugin customProjectId={selectedStudyId} />
                </div>
            </div>
        </div>
    );
}

export const StudiesModule = () => {
    const [selectedStudyId, setSelectedStudyId] = useState<number>();
    const [themeId, setThemeId] = useState("scan/gpmb_carac_milieu");

    const onStudySelected = (studyId?: number) => {
        setSelectedStudyId(studyId);
        setThemeId(studyId !== undefined ? "scan/gpmb_etudes" : "scan/gpmb_carac_milieu");
    };

    return (
        <QWC2 theme={themeId}>
            <OLContextProvider>
                <LayoutContextProvider>
                    <StudiesModuleLayout onStudySelected={onStudySelected} selectedStudyId={selectedStudyId} />
                </LayoutContextProvider>
            </OLContextProvider>
        </QWC2>
    );
};
