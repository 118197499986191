import React from "react";
import { IQWC2Layer } from "../../Interfaces/IQWC2Layer";
import { LayerMenuRow } from "./components/LayerMenuRow/LayerMenuRow";
import { LayerTree } from "./LayerTree";
import classNames from "classnames";

interface ILayerTreeWrapper {
    className?: string;
    WMSLayer?: IQWC2Layer;
    LeafRenderer?: (props: any) => JSX.Element;
}

export const LayerTreeWrapper = ({ WMSLayer, className, LeafRenderer = LayerMenuRow }: ILayerTreeWrapper) => {
    return WMSLayer ? (
        <div className={classNames("layer-tree-wrapper", className)}>
            <LayerTree layerTree={WMSLayer} LeafRenderer={LeafRenderer} rootLayerUuid={WMSLayer.uuid} />
        </div>
    ) : null;
};
