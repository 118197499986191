import React from "react";
import ColorStatus, { Color } from "../../Shared/components/ColorStatus/ColorStatus";

export default function QuotationIndicator({
    quotation,
    target,
    warning = true,
}: {
    quotation: number;
    target: number;
    warning?: boolean;
}) {
    let color = Color.RED;
    if (warning && target + 0.2 >= quotation && quotation > target) {
        color = Color.ORANGE;
    } else if (quotation > target) {
        color = Color.GREEN;
    }
    return <ColorStatus color={color} />;
}
