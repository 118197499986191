import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { IQWC2Layer } from "../../Interfaces/IQWC2Layer";
import fetchWMS, { WMSRequest } from "../../WMSApiService";
import "./LayerMetadata.less";

interface ILayerMetadata {
    layer: IQWC2Layer;
    rootLayer: IQWC2Layer;
    parentLayerTitle?: string;
}

export const LayerMetadata: React.FC<ILayerMetadata> = ({ layer, rootLayer, parentLayerTitle }: ILayerMetadata) => {
    const [metadata, setMetadata] = useState(null);
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        const fetchMetadata = async () => {
            if (!rootLayer) {
                return;
            }
            try {
                setisLoading(true);
                const response = await fetchWMS({
                    baseUrl: rootLayer.url,
                    request: {
                        name: WMSRequest.DESCRIBE_LAYER,
                        options: {
                            LAYERS: layer.name,
                        },
                    },
                });

                setMetadata(response);
            } catch (err) {
                console.error("An error occured while fetching metadata for " + layer);
            } finally {
                setisLoading(false);
            }
        };

        if (rootLayer && !metadata && !isLoading) {
            fetchMetadata();
        }
    }, [rootLayer]);

    return (
        <div className="layer-metadata-wrapper">
            {isLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
            ) : (
                <>
                    <section>
                        <h1>Informations générales</h1>
                        <div className="row">
                            <div className="th">Identifiant</div>
                            <div className="td line-break">{layer.name}</div>
                            <div className="th">Parent</div>
                            <div className="td">{parentLayerTitle}</div>
                            <div className="th">Langue</div>
                            <div className="td">FRA</div>
                        </div>
                        <div className="row">
                            <div className="th">Type</div>
                            <div className="td">Dataset</div>
                            <div className="th">Titre</div>
                            <div className="td">{layer.title}</div>
                        </div>
                        <div className="row">
                            <div className="th">Résumé</div>
                            <div className="td">Ensemble des données issues du run quotidien de Gironde XL.</div>
                        </div>
                        <div className="row">
                            <div className="th">Mots-clés</div>
                            <div className="td">Eaux intérieures, Océans</div>
                        </div>
                    </section>
                    <section>
                        <h1>Licence</h1>
                        <div className="row">
                            <div className="th">Propriétaire</div>
                            <div className="td">GPMB</div>
                            <div className="th">Licence</div>
                            <div className="td">Open Data Commons Attribution License</div>
                        </div>
                    </section>
                    <section>
                        <h1>Système de référence spaciale</h1>
                        <div className="row">
                            <div className="th">authid</div>
                            <div className="td">EPSG:4326</div>
                            <div className="th">srid</div>
                            <div className="td">4326</div>
                            <div className="th">srsid</div>
                            <div className="td">3452</div>
                        </div>
                        <div className="row">
                            <div className="th">authid</div>
                            <div className="td">EPSG:4326</div>
                            <div className="th">proj4</div>
                            <div className="td">+proj=longlat +datum=WGS84 +no_defs</div>
                        </div>
                        <div className="row">
                            <div className="th">description</div>
                            <div className="td">WGS 84</div>
                            <div className="th">acronyme ellipsoid</div>
                            <div className="td">EPSG:7030</div>
                            <div className="th">projection acronyme</div>
                            <div className="td">longlat</div>
                        </div>
                        <div className="row">
                            <div className="th">WKT</div>
                            <div className="td line-break">
                                GEOGCRS[&quot;WGS 84&quot;,ENSEMBLE[&quot;World Geodetic System 1984
                                ensemble&quot;,MEMBER[&quot;World Geodetic System 1984
                                (Transit)&quot;],MEMBER[&quot;World Geodetic System 1984
                                (G730)&quot;],MEMBER[&quot;World Geodetic System 1984 (G873)&quot;],MEMBER[&quot;World
                                Geodetic System 1984 (G1150)&quot;],MEMBER[&quot;World Geodetic System 1984
                                (G1674)&quot;],MEMBER[&quot;World Geodetic System 1984 (G1762)&quot;],MEMBER[&quot;World
                                Geodetic System 1984 (G2139)&quot;],ELLIPSOID[&quot;WGS
                                84&quot;,6378137,298.257223563,LENGTHUNIT[&quot;metre&quot;,1]],ENSEMBLEACCURACY[2.0]],PRIMEM[&quot;Greenwich&quot;,0,ANGLEUNIT[&quot;degree&quot;,0.0174532925199433]],CS[ellipsoidal,2],AXIS[&quot;geodetic
                                latitude
                                (Lat)&quot;,north,ORDER[1],ANGLEUNIT[&quot;degree&quot;,0.0174532925199433]],AXIS[&quot;geodetic
                                longitude
                                (Lon)&quot;,east,ORDER[2],ANGLEUNIT[&quot;degree&quot;,0.0174532925199433]],USAGE[SCOPE[&quot;Horizontal
                                component of 3D
                                system.&quot;],AREA[&quot;World.&quot;],BBOX[-90,-180,90,180]],ID[&quot;EPSG&quot;,4326]]
                            </div>
                        </div>
                    </section>
                    <section>
                        <h1>Extent</h1>
                        <img src="/assets/img/metadata/extent-localization.png" alt="Carte de l'extent" />
                        <div className="row">
                            <div className="th">Début de période</div>
                            <div className="td">2018-04-24 18:00:00</div>
                        </div>
                        <div className="row">
                            <div className="th">Fin de période</div>
                            <div className="td">2022-10-01 18:00:00</div>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};
