import React, { useState } from "react";
import "./LegendGradient.less";
import { ILegendData, ELegendType } from "../Legends";

export interface ILegendGradient extends ILegendData {
    type: ELegendType.GRADIENT;
    symbols: {
        icon: string;
        title: string;
    }[];
    title: string;
}

export const LegendGradient = ({ symbols, title }: ILegendGradient) => {
    const [isHover, setIsHover] = useState(false);

    const filteredSymbols = symbols.filter(({ title }) => !!title);

    const legendValues = filteredSymbols.map(({ title }) => {
        return title.split(" - ").map((val: string) => {
            return parseFloat(val.trim().replace(",", "."));
        });
    });

    const canDisplayRightTooltip = (index: number) => {
        return (
            legendValues[index][1] !== undefined &&
            !Number.isNaN(legendValues[index][1]) &&
            (index === filteredSymbols.length - 1 ||
                (legendValues[index + 1] && Number.isNaN(legendValues[index + 1][0])))
        );
    };

    return (
        <div className="legend-gradient">
            <span className="legend-title">{title}</span>
            <span className="legend-separator"></span>
            <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className="legend-gradient-inner"
            >
                {filteredSymbols.map(({ icon, title }, index) => (
                    <div key={title}>
                        {isHover && !Number.isNaN(legendValues[index][0]) && (
                            <span className="legend-tooltip left">
                                {legendValues[index][0]}
                                <span className="legend-tooltip-arrow" />
                            </span>
                        )}
                        {isHover && canDisplayRightTooltip(index) && (
                            <span className="legend-tooltip right">
                                {legendValues[index][1]}
                                <span className="legend-tooltip-arrow" />
                            </span>
                        )}
                        {isHover && Number.isNaN(legendValues[index][0]) && (
                            <span className="legend-tooltip center">
                                {title}
                                <span className="legend-tooltip-arrow" />
                            </span>
                        )}
                        <img src={`data:image/png;base64, ${icon}`} alt="legend icon" />
                    </div>
                ))}
            </div>
        </div>
    );
};
