import React, { useState } from "react";
import { BottomPanel } from "../../Shared/components/BottomPanel/BottomPanel";
import { Legends } from "../../Shared/components/Legends/Legends";
import { Meteo } from "../../Shared/components/Meteo/Meteo";
import { Timeline } from "../../Shared/components/Timeline/Timeline";

enum Garonne2050TabKeys {
    LEGENDS = "legends",
    METEO = "meteo",
}

export const Garonne2050MeteoBottomPanelPlugin = () => {
    const [isBottomPanelOpen, setIsBottomPanelOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(Garonne2050TabKeys.LEGENDS);
    const handleClickTab = (tabId: string) => {
        setIsBottomPanelOpen && setIsBottomPanelOpen(true);
        setSelectedTab(tabId as Garonne2050TabKeys);
    };
    const isTabOpen = (tabId: Garonne2050TabKeys) => {
        return selectedTab === tabId && isBottomPanelOpen;
    };
    const getCustomTimelineInitDate = () => {
        const localDateString = new Date().toLocaleString("fr-FR");
        const [date] = localDateString.split(" ");
        const [day, month] = date.split("/");
        return `2050-${month}-${day}`;
    };

    return (
        <>
            <Timeline customInitDate={getCustomTimelineInitDate()} />
            <BottomPanel
                items={[
                    {
                        label: "Légendes",
                        children: <Legends isOpen={isTabOpen(Garonne2050TabKeys.LEGENDS)} />,
                        key: Garonne2050TabKeys.LEGENDS,
                    },
                    {
                        label: "Météo",
                        children: <Meteo isOpen={isTabOpen(Garonne2050TabKeys.METEO)} />,
                        key: Garonne2050TabKeys.METEO,
                    },
                ]}
                isOpen={isBottomPanelOpen}
                onClose={() => setIsBottomPanelOpen(false)}
                onOpen={handleClickTab}
            />
        </>
    );
};
