import Keycloak from "keycloak-js";

export enum KeycloakClientId {
    STAGING = "qwc2-giros-app-staging",
    PROD = "qwc2-giros-app-prd",
}

// Quick fix, this should be done with env variables
export const getKeycloakClientId = () =>
    window.location.host === "giros-fleuve.jumeaux-numeriques.fr" ? KeycloakClientId.PROD : KeycloakClientId.STAGING;

const keycloak = new Keycloak({
    url: "https://sso-fleuve.jumeaux-numeriques.fr/",
    realm: "gpmb",
    clientId: getKeycloakClientId(),
});

export default keycloak;
