import React from "react";
import { Button, Table } from "antd";
import Column from "antd/lib/table/Column";
import { arrayToCsv } from "../../Shared/utils/csv";
import { downloadBlob } from "../../Shared/utils/download";
import { INavigabilityAlert, INavigabilityAlertLog } from "../NavigabilityAlertLogButton/NavigabilityAlertLogButton";
import "./NavigabilityAlertLogTable.less";

interface INavigabilityAlertLogTable {
    alertLog: INavigabilityAlertLog;
}

const alertGroupTitleByCollectionKey = {
    d1: "Alertes J+1",
    d30: "Alertes J+30",
    d100: "Alertes J+100",
};

export const NavigabilityAlertLogTable = ({ alertLog }: INavigabilityAlertLogTable) => {
    const csvExport = () => {
        const csvHeaders = [
            "notice",
            "poste_a_quai",
            "cote_seuil_alerte",
            "date_debut_depassement",
            "date_fin_depassement",
            "cote_maximale_prevue",
            "date_cote_maximale_prevue",
        ];
        const csvContent = Object.keys(alertLog).reduce(
            (acc, key) =>
                acc.concat(
                    alertLog[key as keyof typeof alertLog].map(
                        ({
                            poste_a_quai,
                            cote_seuil_alerte,
                            date_debut_depassement,
                            date_fin_depassement,
                            cote_maximale_prevue,
                            date_cote_maximale_prevue,
                        }) => [
                            key,
                            `P. ${poste_a_quai}`,
                            String(cote_seuil_alerte),
                            date_debut_depassement,
                            date_fin_depassement,
                            String(cote_maximale_prevue),
                            date_cote_maximale_prevue,
                        ],
                    ),
                ),
            [] as string[][],
        );
        const csvString = arrayToCsv([csvHeaders, ...csvContent]);
        downloadBlob(csvString, "submersion-alert-log.csv", "text/csv;charset=utf-8;");
    };

    return (
        <>
            <div className="navigability-alert-log-buttons-wrapper">
                <Button type="primary" onClick={csvExport}>
                    Export CSV
                </Button>
            </div>
            {Object.entries(alertLog).map(([key, log]: [string, INavigabilityAlert[]]) => (
                <React.Fragment key={key}>
                    <h1>{alertGroupTitleByCollectionKey[key as keyof typeof alertLog]}</h1>
                    <Table dataSource={log} pagination={false} size="small">
                        <Column
                            title="Poste à quai"
                            dataIndex="poste_a_quai"
                            render={(poste_a_quai) => {
                                return `P. ${poste_a_quai}`;
                            }}
                        />
                        <Column
                            title="Cote seuil"
                            dataIndex="cote_seuil_alerte"
                            render={(cote) => {
                                return Math.round(cote * 100) / 100;
                            }}
                        />
                        <Column
                            title="Date de début"
                            dataIndex="date_debut_depassement"
                            render={(timestamp) => {
                                return new Date(timestamp).toLocaleDateString();
                            }}
                        />
                        <Column
                            title="Date de fin"
                            dataIndex="date_fin_depassement"
                            render={(timestamp) => {
                                return new Date(timestamp).toLocaleDateString();
                            }}
                        />
                        <Column
                            title="Cote maximale"
                            dataIndex="cote_maximale_prevue"
                            render={(cote) => {
                                return Math.round(cote * 100) / 100;
                            }}
                        />
                        <Column
                            title="Date cote maximale"
                            dataIndex="date_cote_maximale_prevue"
                            render={(timestamp) => {
                                return new Date(timestamp).toLocaleDateString();
                            }}
                        />
                    </Table>
                </React.Fragment>
            ))}
        </>
    );
};
