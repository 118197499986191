import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Tabs, Input, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import { IPlanningTimeRange } from "../types";
import { CreateTimeRangeWaterway } from "./CreateTimeRangeContent/CreateTimeRangeWaterway";
import { EProgrammedState } from "../../../../Dredging/AnnualStrategy/types";
import moment, { Moment } from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import "./CreateTimeRangePopup.less";
import CreateTimeRangeCommercialCommitment from "./CreateTimeRangeContent/CreateTimeRangeCommercialCommitment";

const { Search } = Input;

interface ICreateTimeRangePopup {
    from: Date;
    left: number;
    onClose: () => void;
    onCreate: (timerange: Partial<IPlanningTimeRange>) => void;
    isCommercialCommitment?: boolean;
}

export const CreateTimeRangePopup = ({
    left,
    from,
    onClose,
    onCreate,
    isCommercialCommitment = false,
}: ICreateTimeRangePopup) => {
    const [search, setSearch] = useState("");
    const [timerangeDates, setTimerangeDates] = useState<{ from: Moment; to: Moment }>({
        from: moment(from),
        to: moment(from),
    });

    const handleCreate = (timerange: Partial<IPlanningTimeRange>) => {
        onClose();
        onCreate({ from: timerangeDates.from.toISOString(), to: timerangeDates.to.toISOString(), ...timerange });
    };

    const searchbar = <Search placeholder="Rechercher" onKeyUp={(event: any) => setSearch(event.target.value)} />;

    const disabledDateFrom: RangePickerProps["disabledDate"] = (current) => {
        return current && current.toDate().getTime() > timerangeDates.to.toDate().getTime();
    };

    const disabledDateTo: RangePickerProps["disabledDate"] = (current) => {
        return current && current.toDate().getTime() < timerangeDates.from.toDate().getTime();
    };

    const timerangeSelect = (
        <div className="select-timerange">
            <div>
                <label>Début :</label>
                <DatePicker
                    allowClear={false}
                    disabledDate={disabledDateFrom}
                    value={moment(timerangeDates.from)}
                    onChange={(date) => date && setTimerangeDates((oldTimerange) => ({ ...oldTimerange, from: date }))}
                    locale={locale}
                />
            </div>
            <div>
                <label>Fin :</label>
                <DatePicker
                    allowClear={false}
                    disabledDate={disabledDateTo}
                    value={moment(timerangeDates.to)}
                    onChange={(date) => date && setTimerangeDates((oldTimerange) => ({ ...oldTimerange, to: date }))}
                    locale={locale}
                />
            </div>
        </div>
    );

    return (
        <div className="create-time-range-popup" style={{ left }}>
            <div className="create-time-range-popup-header">
                <h1>Ajouter{isCommercialCommitment && " un engagement commercial"}</h1>
                <button type="button" onClick={onClose}>
                    <CloseOutlined />
                </button>
            </div>
            {isCommercialCommitment ? (
                <CreateTimeRangeCommercialCommitment onCreate={handleCreate} timerangeSelect={timerangeSelect} />
            ) : (
                <Tabs
                    items={[
                        {
                            label: "Une passe / souille",
                            children: (
                                <CreateTimeRangeWaterway
                                    search={search}
                                    searchbar={searchbar}
                                    onCreate={handleCreate}
                                    timerangeSelect={timerangeSelect}
                                />
                            ),
                            key: "waterways",
                        },
                        {
                            label: "Une indisponibilité",
                            children: (
                                <div className="create-timerange">
                                    {timerangeSelect}
                                    {searchbar}
                                    {["Maintenance", "Location", "Autre raison"]
                                        .filter((item) => !search || item.toLowerCase().includes(search.toLowerCase()))
                                        .map((item, index) => (
                                            <button
                                                key={index}
                                                className="selectable-item large"
                                                onClick={() => {
                                                    handleCreate({
                                                        activity: EProgrammedState.UNAVAILABLE,
                                                        text: item,
                                                        tooltip: item,
                                                    });
                                                }}
                                            >
                                                {item}
                                            </button>
                                        ))}
                                </div>
                            ),
                            key: "unavailability",
                        },
                    ]}
                />
            )}
        </div>
    );
};
