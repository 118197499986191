import axios from "axios";
import { addSearchResults } from "qwc2/actions/search";
import LocaleUtils from "qwc2/utils/LocaleUtils";
import { ISearchResultGroup } from "./types";

const nominatimSearchResults = (obj: any[], requestId: string) => {
    const results: ISearchResultGroup[] = [];
    const groups: { [group: string]: ISearchResultGroup } = {};

    (obj || []).map((entry, index) => {
        if (index === 0) {
            groups.places = {
                id: "nominatimgroup0",
                title: "Lieux",
                items: [] as any[],
            };
            results.push(groups.places);
        }
        const text = entry.display_name.split(", ").slice(0, 3).join(", ");
        const label = text;
        const address = [];

        if (entry.address.town) {
            address.push(entry.address.town);
        }
        if (entry.address.city) {
            address.push(entry.address.city);
        }
        if (entry.address.state) {
            address.push(entry.address.state);
        }
        if (entry.address.country) {
            address.push(entry.address.country);
        }

        const b = entry.boundingbox.map((coord: string) => parseFloat(coord));
        const bbox = [b[2], b[0], b[3], b[1]];

        groups.places.items.push({
            id: entry.place_id,
            text: text,
            label: label,
            bbox: bbox,
            geometry: entry.geojson,
            x: 0.5 * (bbox[0] + bbox[2]),
            y: 0.5 * (bbox[1] + bbox[3]),
            crs: "EPSG:4326",
            provider: "nominatim",
        });
    });
    return addSearchResults({ data: results, provider: "nominatim", reqId: requestId }, true);
};

export const nominatimSearch = (
    text: string,
    requestId: string,
    searchOptions: any,
    dispatch: (action: any) => void,
    cfg = {},
) => {
    axios
        .get("//nominatim.openstreetmap.org/search", {
            params: {
                q: text,
                addressdetails: 1,
                polygon_geojson: 1,
                limit: 15,
                format: "json",
                "accept-language": LocaleUtils.lang(),
                ...((cfg as any).params || {}),
            },
        })
        .then((response) => {
            return dispatch(nominatimSearchResults(response.data as any[], requestId));
        });
};
