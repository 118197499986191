import React from "react";
import "./LegendIcons.less";
import { ILegendData, ELegendType } from "../Legends";

export interface ILegendIcon extends ILegendData {
    type: ELegendType.ICON;
    icon: string;
    title: string;
}

export const LegendIcons = ({ icon, title }: ILegendIcon) => {
    return (
        <div className="legend-icons">
            <div className="icon-wrapper">
                <img src={`data:image/png;base64, ${icon}`} alt="legend icon" />
            </div>
            <span className="legend-title">{title}</span>
            <span className="legend-separator" />
        </div>
    );
};
