import { Alert, Button, Form, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EPermission } from "../../../Shared/Enums/EPermissions";
import UserContext, { IUserContext } from "../../../UserContext";
import { ITideGauge } from "../useTideGauge";
import { ZhRefSettingsEditableCell } from "./ZhRefSettingsEditableCell/ZhRefSettingsEditableCell";
import "./ZhRefSettingsForm.less";

interface IZHRefSettingsForm {
    tideGauges: ITideGauge[];
    updateTideGauges: (newTideGauges: ITideGauge[]) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
type CustomColumnType = ColumnTypes[number] & { dataIndex?: string; editable?: boolean; gid?: number };

export const ZHRefSettingsForm = ({ tideGauges, updateTideGauges }: IZHRefSettingsForm) => {
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<ITideGauge[]>([]);
    const [recordEdit, setRecordEdit] = useState<ITideGauge | null>(null);
    const [submitStatus, setSubmitStatus] = useState<"success" | "error" | null>(null);
    const { user } = useContext(UserContext) as IUserContext;
    const canEdit = user?.permissions.includes(EPermission.EDIT_MODULE_DRAGAGE_SEUILS);

    const validateRecord = () => {
        if (!recordEdit) {
            return;
        }
        const newDataSource = [...dataSource];
        const indexToUpdate = newDataSource.findIndex(({ gid }) => gid === recordEdit.gid);
        newDataSource.splice(indexToUpdate, 1, recordEdit);
        setDataSource(newDataSource);
        setRecordEdit(null);
    };

    const handleEdit = (dataIndex: keyof ITideGauge, value: number) => {
        setRecordEdit((oldRecord) => {
            if (!oldRecord) {
                return null;
            }
            const newRecord = { ...oldRecord, [dataIndex]: value, mare_date_: new Date().toISOString().split("T")[0] };
            const mare_diff_ = Math.round((newRecord.level_water_ign69 - newRecord.level_water_etiage) * 100) / 100;
            return {
                ...newRecord,
                mare_diff_: isNaN(mare_diff_) ? null : mare_diff_,
            };
        });
    };

    useEffect(() => {
        const initForm = () => {
            form.resetFields();
            setDataSource(tideGauges);
        };

        initForm();
    }, [tideGauges, form]);

    const columns: CustomColumnType[] = [
        {
            title: "Stations",
            dataIndex: "mare_desig",
            key: "mare_desig",
        },
        {
            title: "PK",
            dataIndex: "mare_pk",
            key: "mare_pk",
            align: "center",
        },
        {
            title: "Ref. Etiage (m)",
            dataIndex: "level_water_etiage",
            key: "level_water_etiage",
            editable: true,
            align: "center",
        },
        {
            title: "Ref. IGN 69 (m)",
            dataIndex: "level_water_ign69",
            key: "level_water_ign69",
            editable: true,
            align: "center",
        },
        {
            title: "ZH/ref retenu",
            dataIndex: "mare_diff_",
            key: "mare_diff_",
            align: "center",
        },
        {
            title: "Date de modification",
            dataIndex: "mare_date_",
            key: "mare_date_",
        },
        ...(canEdit
            ? [
                  {
                      title: "Action",
                      key: "actions",
                      render: (_: any, record: CustomColumnType) => {
                          if (recordEdit && record.gid === recordEdit.gid) {
                              const isDisabled =
                                  isNaN(recordEdit.level_water_ign69) || isNaN(recordEdit.level_water_etiage);
                              return (
                                  <>
                                      <Button
                                          className="form-button"
                                          disabled={isDisabled}
                                          type="link"
                                          onClick={() => validateRecord()}
                                      >
                                          Valider
                                      </Button>
                                      <span className="separator" />
                                      <Button className="form-button" type="link" onClick={() => setRecordEdit(null)}>
                                          Annuler
                                      </Button>
                                  </>
                              );
                          }

                          return (
                              <Button
                                  className="form-button"
                                  disabled={Boolean(recordEdit)}
                                  type="link"
                                  onClick={() => setRecordEdit({ ...record } as ITideGauge)}
                              >
                                  Editer
                              </Button>
                          );
                      },
                  },
              ]
            : []),
    ];

    const addColumnHandlers = (cols: CustomColumnType[]): any => {
        return cols.map((col) => ({
            ...col,
            onCell: (record: Partial<ITideGauge>) => ({
                isEditing: record.gid === recordEdit?.gid,
                record: record.gid === recordEdit?.gid ? recordEdit : record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                handleEdit,
            }),
        }));
    };

    const onFinish = async () => {
        try {
            await updateTideGauges(dataSource);
            setSubmitStatus("success");
        } catch (err) {
            console.error(err);
            setSubmitStatus("error");
        } finally {
            setTimeout(() => setSubmitStatus(null), 5000);
        }
    };

    const canSave = JSON.stringify(dataSource) !== JSON.stringify(tideGauges) && recordEdit === null;

    return (
        <Form onFinish={onFinish} form={form} className="zhref-settings-modale">
            <div className="zhref-settings-header">
                <Button disabled={!canSave} className="save-button" type="primary" htmlType="submit">
                    Sauvegarder
                </Button>
            </div>
            {submitStatus === "success" && (
                <Alert type="success" message="Le paramétrage a bien été sauvegardé." closable />
            )}
            {submitStatus === "error" && (
                <Alert type="error" message="Une erreur est survenue lors de la sauvegarde du paramétrage." closable />
            )}
            <Table
                components={{
                    body: {
                        cell: ZhRefSettingsEditableCell,
                    },
                }}
                pagination={false}
                dataSource={dataSource}
                columns={addColumnHandlers(columns)}
            />
        </Form>
    );
};
