import React, { useMemo } from "react";
import { ColumnsType } from "antd/lib/table";
import { WaterwayType } from "../Shared/useWaterways";
import WaterwayCell from "../Shared/components/WaterwayList/WaterwayCell";
import { IBbox } from "../interfaces/IBbox";
import TimeStampCell from "./TimeStampCell/TimeStampCell";
import { InfoCircleOutlined } from "@ant-design/icons";

const numberFormat = new Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 });

export interface FairwayNavigabilityData {
    key: number;
    name: string;
    type: WaterwayType.FAIRWAY;
    timestamp: string;
    ordre: number;
    bounds: IBbox["bounds"];
    resistance: number;
    squat: number;
    bathymetrie: number;
    first: boolean;
}
const getFirstFairwayInMovementCellStyle = ({ first }: FairwayNavigabilityData, index: number | undefined) =>
    first && index && index > 0 ? { style: { borderTop: "3px solid rgba(0,0,0,.16)" } } : { style: {} };

export default function useFairwayNavigabilityColumns() {
    return useMemo(
        () =>
            [
                {
                    title: "Nom",
                    dataIndex: "name",
                    className: "first-col border-right",
                    onCell: getFirstFairwayInMovementCellStyle,
                    render: (name, { bounds }: FairwayNavigabilityData) => <WaterwayCell name={name} bounds={bounds} />,
                },
                {
                    title: "Passage prévu",
                    dataIndex: "timestamp",
                    className: "border-right-half timepicker",
                    onCell: getFirstFairwayInMovementCellStyle,
                    render: (timestamp) => <TimeStampCell timestamp={timestamp} />,
                },
                {
                    title: "Turbidité (g/l)",
                    dataIndex: "turbidite",
                    className: "border-right-half",
                    onCell: getFirstFairwayInMovementCellStyle,
                    render: (turbidite) => numberFormat.format(turbidite),
                },
                {
                    title: "Bathymétrie (m)",
                    dataIndex: "bathymetrie",
                    className: "border-right-half",
                    onCell: getFirstFairwayInMovementCellStyle,
                    render: (bathymetrie) => numberFormat.format(bathymetrie),
                },
                {
                    title: "Courant (m/s)",
                    dataIndex: "swell",
                    className: "border-right-half",
                    onCell: getFirstFairwayInMovementCellStyle,
                    render: (swell) => numberFormat.format(swell),
                },
                {
                    title: (
                        <>
                            Résistance
                            <a
                                target="_blank"
                                href="https://giros-fleuve.jumeaux-numeriques.fr/documentation/modules/navigabilite/index.html#resistance"
                                rel="noreferrer"
                                className="documentation-link"
                            >
                                <InfoCircleOutlined />
                            </a>
                        </>
                    ),
                    dataIndex: "resistance",
                    className: "border-right-half",
                    onCell: getFirstFairwayInMovementCellStyle,
                    render: (resistance) => numberFormat.format(resistance),
                },
                {
                    title: (
                        <>
                            Squat
                            <a
                                target="_blank"
                                href="https://giros-fleuve.jumeaux-numeriques.fr/documentation/modules/navigabilite/index.html#squat"
                                rel="noreferrer"
                                className="documentation-link"
                            >
                                <InfoCircleOutlined />
                            </a>
                        </>
                    ),
                    dataIndex: "squat",
                    className: "border-right-half",
                    onCell: getFirstFairwayInMovementCellStyle,
                    render: (squat) => numberFormat.format(squat),
                },
            ] as ColumnsType<FairwayNavigabilityData>,
        [],
    );
}
