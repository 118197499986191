import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./MapModal.less";
import classNames from "classnames";

export interface IMapModal {
    title: string | React.ReactNode;
    content: React.ReactNode;
    onClose: () => void;
    className?: string;
    fullscreen?: boolean;
}

export const MapModal = ({ content, onClose, title, className, fullscreen }: IMapModal) => (
    <div className={classNames("map-modal", className, { fullscreen })}>
        <div className="map-modal-header">
            <h1>{title}</h1>
            <Button type="text" onClick={onClose} className="close-button">
                <CloseOutlined />
            </Button>
        </div>
        <div className="map-modal-content">{content}</div>
    </div>
);
