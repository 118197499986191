import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import TopBarButton from "./TopBarButton/TopBarButton";

export default function HelpButton({ to }: { to: string }) {
    return (
        <TopBarButton tooltip="Aide/Documentation" className="help-button" href={to}>
            <QuestionCircleOutlined />
        </TopBarButton>
    );
}
