import React from "react";
import { useRouteError } from "react-router";

export const ErrorPage = () => {
    const error: any = useRouteError();
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 30 }}>
            <h1>Une erreur est survenue</h1>
            {process.env.NODE_ENV === "development" && <p>{error.message}</p>}
            <a href="/">Retourner à la page d&apos;accueil</a>
        </div>
    );
};
