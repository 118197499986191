import { Button, List, Modal } from "antd";
import React, { useState } from "react";

const credits = [
    {
        title: "OpenStreetMap",
        text: (
            <p>
                Fond de carte par <a href="https://www.openstreetmap.org">OpenStreetMap</a>, sous licence{" "}
                <a href="http://creativecommons.org/licenses/by-sa/2.0/fr/">CC BY-SA</a>
            </p>
        ),
    },

    {
        title: "IGN Photographies aériennes",
        text: (
            <p>
                © <a href="https://www.ign.fr/">IGN</a>, <a href="http://www.planetobserver.com/">Planet Observer</a>
            </p>
        ),
    },

    {
        title: "IGN SATELLITE - SPOT 6-7 - 2021",
        text: (
            <p>
                © <a href="https://www.ign.fr/">IGN</a> Financeurs de la télémesure SPOT 6-7 : CIRAD, CNES, CNRS, IGN,
                IRD, INRAE
            </p>
        ),
    },

    {
        title: "IGN SATELLITE - PLEIADES - 2022",
        text: (
            <p>
                © <a href="https://www.ign.fr/">IGN</a>
            </p>
        ),
    },
];

export default function InfoCredits() {
    const [showCredits, setShowCredits] = useState(false);
    const closeModal = () => setShowCredits(false);
    return (
        <>
            <Button type="text" onClick={() => setShowCredits(true)}>
                Crédits
            </Button>
            <Modal
                open={showCredits}
                onOk={closeModal}
                onCancel={closeModal}
                title="Crédits fond carte"
                footer={
                    <Button type="primary" onClick={closeModal}>
                        Fermer
                    </Button>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={credits}
                    renderItem={(credit) => (
                        <List.Item>
                            <List.Item.Meta title={credit.title} description={credit.text} />
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
}
