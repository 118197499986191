import React, { useContext, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { changeMeasurementState } from "qwc2/actions/measurement";
import { useDispatch, useSelector } from "react-redux";
import OlOverlay from "ol/Overlay";
import { OLContext } from "qwc2/contexts/OLContext";
import "./useMarkers.less";
import { MarkerIcon } from "./Icons/MarkerIcon";

const getMarkerElement = (markerTitle: string, showMarkersTitle: boolean): HTMLElement => {
    const markerElem = document.createElement("div");
    markerElem.className = "giros-marker";
    if (showMarkersTitle) {
        markerElem.setAttribute("data-before", markerTitle);
    }
    markerElem.innerHTML = renderToStaticMarkup(<MarkerIcon />);

    return markerElem;
};

interface UseMarkersParams {
    showMarkers: boolean;
    maxMarkers?: number;
    showMarkersTitle?: boolean;
    continiousAdd?: boolean;
}

export interface IMarker {
    id: number;
    label: string;
    overlay: OlOverlay | null;
    coords: number[];
}

export const useMarkers = ({
    showMarkers,
    maxMarkers = 6,
    showMarkersTitle = true,
    continiousAdd = false,
}: UseMarkersParams) => {
    const dispatch = useDispatch();
    const { measureState } = useSelector((state: any) => ({
        measureState: state.measurement,
    }));
    const { olMap }: { olMap: any } = useContext(OLContext);
    const [markers, setMarkers] = useState<Array<IMarker>>([]);
    const [isSettingMarker, setIsSettingMarker] = useState(false);

    // Show or hide the markers
    useEffect(() => {
        if (!olMap) {
            return;
        }
        markers.forEach(({ overlay }) => {
            showMarkers ? (olMap as any).addOverlay(overlay) : (olMap as any).removeOverlay(overlay);
        });
    }, [showMarkers]);

    // Set a new marker
    useEffect(() => {
        if (!isSettingMarker) {
            return;
        }

        if (olMap && measureState.coordinates && measureState.coordinates.length === 2) {
            const lastMarkerId = markers
                .map(({ id }) => id)
                .sort()
                .pop();
            const nextMarkerId = lastMarkerId ? lastMarkerId + 1 : 1;
            const label = `Repère ${nextMarkerId}`;
            const newMarker = {
                id: nextMarkerId,
                label,
                coords: measureState.coordinates,
                overlay: new OlOverlay({
                    element: getMarkerElement(label, showMarkersTitle),
                    position: measureState.coordinates,
                }),
            };
            (olMap as any).addOverlay(newMarker.overlay);

            setMarkers((oldMarkers) => [...oldMarkers, newMarker]);
            if (maxMarkers && markers.length === maxMarkers) {
                setTimeout(() => deleteMarker(0), 0);
            }
            if (!continiousAdd) {
                setTimeout(() => stopMarkerSelection(), 0);
            }
        }
    }, [measureState, isSettingMarker]);

    const addMarker = () => {
        if (markers.length >= maxMarkers) {
            return;
        }
        dispatch(changeMeasurementState({ geomType: "Point" }));
        setIsSettingMarker(true);
    };

    const stopMarkerSelection = () => {
        dispatch(changeMeasurementState({ geomType: null }));
        setIsSettingMarker(false);
    };

    const deleteMarker = (index: number) => {
        const { overlay } = markers[index];
        (olMap as any).removeOverlay(overlay);
        setMarkers((oldMarkers) => {
            const newMarker = [...oldMarkers];
            newMarker.splice(index, 1);

            return newMarker;
        });
    };

    return {
        markers,
        addMarker,
        deleteMarker,
        isSettingMarker,
        stopMarkerSelection,
    };
};
