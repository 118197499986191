import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLayerProperty } from "qwc2/actions/layers";
import "./Timeline.less";
import { IQWC2Layer } from "../../Interfaces/IQWC2Layer";
import { Button, DatePicker, DatePickerProps, TimePicker } from "antd";
import { Spin } from "antd";
import {
    CalendarOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import moment, { Moment } from "moment-timezone";
import { getDateFromTimeStamp } from "../../utils/date";
import { fetchSLF } from "../../utils/fetch";
import useCurrentModule from "../../useCurrentModule";

// Typescript error with this component
const ECDatePicker = DatePicker as unknown as React.FC<DatePickerProps>;

interface ITimeline {
    onClose?: () => void;
    customInitDate?: string;
    customProjectId?: number;
}

export const Timeline = ({ onClose, customInitDate, customProjectId }: ITimeline) => {
    const [currentTimestampIndex, setCurrentTimestampIndex] = useState<number | null>(null);
    const [timeStamps, setTimeStamps] = useState<string[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { layers } = useSelector((state: any) => ({
        layers: state.layers.flat,
    }));
    const [currentModule] = useCurrentModule();
    const projectId = customProjectId !== undefined ? customProjectId : currentModule?.defaultProjectId;

    useEffect(() => {
        const fetchTimestamps = async () => {
            setIsLoading(true);
            try {
                const { time_steps }: { time_steps: string[] } = await fetchSLF(`slf/time_steps/${projectId}`);
                const timeStamps = time_steps
                    .sort()
                    .map((step) => moment.utc(step).tz("Europe/Paris").format("YYYY-MM-DDTHH:mm:SS"));
                setTimeStamps(timeStamps);
                setCurrentTimestampIndex(initCurrentTimestampIndex(timeStamps, customInitDate));
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };
        if (projectId !== undefined) {
            fetchTimestamps();
        }

        // Remove the TIME param when the timeline is closed
        return () => {
            const WMSLayer = layers.find(({ type }: IQWC2Layer) => type === "wms");
            dispatch(changeLayerProperty(WMSLayer?.uuid, "dimensionValues", { TIME: undefined }));
        };
    }, [projectId, customInitDate]);

    useEffect(() => {
        if (currentTimestampIndex === null || !timeStamps) {
            return;
        }
        const WMSLayer = layers.find(({ type }: IQWC2Layer) => type === "wms");
        dispatch(
            changeLayerProperty(WMSLayer.uuid, "dimensionValues", {
                TIME: moment.tz(timeStamps[currentTimestampIndex], "Europe/Paris").utc().format("YYYY-MM-DDTHH:mm:SS"),
            }),
        );
    }, [currentTimestampIndex]);

    const initCurrentTimestampIndex = (time_steps: string[], customInitDate?: string): number => {
        let initDate = customInitDate;
        if (!initDate) {
            const localDateString = new Date().toLocaleString("fr-FR");
            const [date, time] = localDateString.split(" ");
            const [day, month, year] = date.split("/");
            const [hours] = time.split(":");
            initDate = `${year}-${month}-${day}T${hours}`;
        }

        const matchingTimestamp = time_steps.findIndex((ts) => ts.includes(initDate as string));

        return matchingTimestamp === -1 ? time_steps.length - 1 : matchingTimestamp;
    };

    const onSelectDate = (newValue: Moment | null) => {
        const selectedDate = newValue?.format("YYYY-MM-DD");
        if (!selectedDate || !timeStamps) {
            return;
        }
        const tsToSelectIndex = timeStamps.findIndex((ts) => {
            const date = getDateFromTimeStamp(ts);

            return date === selectedDate;
        });
        if (tsToSelectIndex !== -1) {
            setCurrentTimestampIndex(tsToSelectIndex);
        }
    };

    const getPrevDate = (tsList: string[]): string | null => {
        if (tsList.length === 0 || currentTimestampIndex === null || !timeStamps) {
            return null;
        }
        const currentDate = getDateFromTimeStamp(timeStamps[currentTimestampIndex]);
        const curTs = tsList.pop();
        const date = getDateFromTimeStamp(curTs);
        if (curTs && currentDate !== date) {
            return curTs;
        }
        return getPrevDate(tsList);
    };

    const getNextDate = (tsList: string[]): string | null => {
        if (tsList.length === 0 || currentTimestampIndex === null || !timeStamps) {
            return null;
        }
        const currentDate = getDateFromTimeStamp(timeStamps[currentTimestampIndex]);
        const curTs = tsList.shift();
        const date = getDateFromTimeStamp(curTs);
        if (curTs && currentDate !== date) {
            return curTs;
        }
        return getNextDate(tsList);
    };

    const getExactDate = (to: "prev" | "next"): string | undefined => {
        if (!timeStamps || currentTimestampIndex === null) {
            return;
        }
        const curMoment = moment(timeStamps[currentTimestampIndex]).utc(true);
        const dateToSearch = to === "prev" ? curMoment.add(-1, "day") : curMoment.add(1, "day");
        const tsToSearch = dateToSearch.toISOString().split(".")[0];

        return timeStamps.find((ts) => ts === tsToSearch);
    };

    const onChangeDate = (to: "prev" | "next") => {
        if (!timeStamps || currentTimestampIndex === null) {
            return;
        }
        if (to === "prev") {
            const prevDate = getExactDate("prev") || getPrevDate([...timeStamps.slice(0, currentTimestampIndex)]);
            if (prevDate) {
                setCurrentTimestampIndex(timeStamps?.findIndex((ts) => ts === prevDate));
            }
        } else {
            const nextDate = getExactDate("next") || getNextDate([...timeStamps.slice(currentTimestampIndex)]);
            if (nextDate) {
                setCurrentTimestampIndex(timeStamps?.findIndex((ts) => ts === nextDate));
            }
        }
    };

    const onSelectTime = (newValue: Moment | null) => {
        if (!timeStamps || currentTimestampIndex === null) {
            return;
        }
        const splittedCurrent = timeStamps[currentTimestampIndex].split("T");
        const currentDateHours = `${splittedCurrent[0]}T${moment.utc(newValue).format("HH")}`;

        const tsToSelectIndex = timeStamps.findIndex((ts) => {
            const dateHours = ts.split(":")[0];

            return dateHours === currentDateHours;
        });
        if (tsToSelectIndex !== -1) {
            setCurrentTimestampIndex(tsToSelectIndex);
        }
    };

    const onChangeTime = (to: "prev" | "next") => {
        if (currentTimestampIndex === null) {
            return;
        }
        setCurrentTimestampIndex(to === "prev" ? currentTimestampIndex - 1 : currentTimestampIndex + 1);
    };

    const isDateDisabled = (date: Moment) => {
        const formattedDate = date.format("YYYY-MM-DD");
        const dateAvailable = timeStamps?.find((ts) => formattedDate === getDateFromTimeStamp(ts));

        return !Boolean(dateAvailable);
    };

    const isTimeDisabled = () => {
        const hoursOfDay = [...Array(24)].map((_, i) => i);
        if (!timeStamps || currentTimestampIndex === null) {
            return {
                disabledHours() {
                    return hoursOfDay;
                },
            };
        }
        const currentDate = getDateFromTimeStamp(timeStamps[currentTimestampIndex]);
        const availableHours = timeStamps
            ?.filter((ts) => currentDate && ts.includes(currentDate))
            .map((ts) => parseInt(ts.split("T")[1].split(":")[0]));

        return {
            disabledHours() {
                return hoursOfDay.filter((hour) => !availableHours.includes(hour));
            },
        };
    };

    return (
        <div className="timeline-wrapper">
            {isLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
            ) : timeStamps && currentTimestampIndex !== null ? (
                <div>
                    <CalendarOutlined />
                    <Button
                        type="text"
                        onClick={() => onChangeDate("prev")}
                        className="picker-button previous-date"
                        disabled={!Boolean(getPrevDate([...timeStamps.slice(0, currentTimestampIndex)]))}
                    >
                        <CaretLeftOutlined />
                    </Button>
                    <ECDatePicker
                        placeholder="Date"
                        onChange={onSelectDate}
                        value={moment(getDateFromTimeStamp(timeStamps[currentTimestampIndex]), "YYYY-MM-DD")}
                        allowClear={false}
                        showToday={false}
                        disabledDate={isDateDisabled}
                    />
                    <Button
                        type="text"
                        onClick={() => onChangeDate("next")}
                        className="picker-button next-date"
                        disabled={!Boolean(getNextDate([...timeStamps.slice(currentTimestampIndex)]))}
                    >
                        <CaretRightOutlined />
                    </Button>
                    <hr className="separator" />
                    <ClockCircleOutlined />
                    <Button
                        type="text"
                        onClick={() => onChangeTime("prev")}
                        className="picker-button"
                        disabled={currentTimestampIndex <= 0}
                    >
                        <CaretLeftOutlined />
                    </Button>
                    <TimePicker
                        placeholder="Heure"
                        onChange={onSelectTime}
                        value={moment(timeStamps[currentTimestampIndex].split("T")[1], "HH:mm:ss")}
                        allowClear={false}
                        showNow={false}
                        format="HH:00"
                        disabledTime={isTimeDisabled}
                        suffixIcon={moment.tz(timeStamps[currentTimestampIndex], "Europe/Paris").format("z")}
                    />
                    <Button
                        type="text"
                        onClick={() => onChangeTime("next")}
                        className="picker-button"
                        disabled={currentTimestampIndex >= timeStamps.length - 1}
                    >
                        <CaretRightOutlined />
                    </Button>
                </div>
            ) : null}
            {onClose && (
                <Button className="close-button" type="text" onClick={onClose}>
                    <CloseOutlined />
                </Button>
            )}
        </div>
    );
};
