import { LineOutlined } from "@ant-design/icons";
import { Tabs, TabsProps, Button } from "antd";
import React from "react";
import classnames from "classnames";
import useOLResize from "../../useOLResize";
import "./BottomPanel.less";

interface IBottomPanel extends TabsProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: (tabId: string) => void;
    style?: React.CSSProperties;
}

export const BottomPanel: React.FC<IBottomPanel> = ({
    items,
    isOpen,
    onClose,
    onOpen,
    style,
    ...tabProps
}: IBottomPanel) => {
    const resizeOLMap = useOLResize(200);
    return (
        <div className={classnames("bottom-panel", { opened: isOpen, closed: !isOpen })} style={style}>
            <Button
                type="text"
                className="close-button"
                onClick={() => {
                    onClose();
                    resizeOLMap();
                }}
            >
                <LineOutlined />
            </Button>
            <Tabs
                items={items}
                onTabClick={(tabId) => {
                    onOpen(tabId);
                    resizeOLMap();
                }}
                {...tabProps}
            />
        </div>
    );
};
