import React, { ReactNode } from "react";
import "./LegendNode.less";
import { ELegendType } from "../Legends";

export interface ILegendNode {
    type: ELegendType.NODE;
    node: ReactNode;
}

export default function LegendNode({ node }: ILegendNode) {
    return <span className="legend-node">{node}</span>;
}
