import React, { useEffect, useState } from "react";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ButtonSwitch } from "../ButtonSwitch/ButtonSwitch";
import { useMarkers } from "../../useMarkers";
import { IWeatherForecast, fetchOpenMeteo } from "./fetchOpenMeteo";
import { MeteoCard } from "./components/MeteoCard";
import { useSelector } from "react-redux";
import "./Meteo.less";

interface IMeteo {
    isOpen: boolean;
}

export const Meteo = ({ isOpen }: IMeteo) => {
    const [currentMarkerIndex, setCurrentMakerIndex] = useState(0);
    const [forecasts, setForecasts] = useState<Record<number, IWeatherForecast[]>>({});
    const [isLoading, setIsLoading] = useState(false);
    const { markers, addMarker, deleteMarker, isSettingMarker } = useMarkers({ showMarkers: isOpen });
    const mapCRS = useSelector((state: any) => state.map.projection);

    useEffect(() => {
        const updateForecasts = async () => {
            try {
                setIsLoading(true);
                await Promise.all(
                    markers.map(async (marker) => {
                        if (!forecasts[marker.id]) {
                            const weatherForecast = await fetchOpenMeteo(marker, mapCRS);
                            weatherForecast &&
                                setForecasts((oldForecasts) => ({
                                    ...oldForecasts,
                                    [marker.id]: weatherForecast,
                                }));
                        }
                    }),
                );
                Object.keys(forecasts).forEach((key) => {
                    const associatedMarker = markers.find(({ id }) => id === parseInt(key));
                    if (!associatedMarker) {
                        delete forecasts[parseInt(key)];
                    }
                });
            } catch (err) {
                console.error(`An error occured while fetching open meteo API ${err}`);
            } finally {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            return;
        }
        updateForecasts();
        setCurrentMakerIndex(markers.length - 1);
    }, [markers, mapCRS]);

    const onCloseTab = (index: number) => {
        deleteMarker(index);
    };

    const getCurrentMarkerId = () => {
        return markers[currentMarkerIndex] && markers[currentMarkerIndex].id;
    };

    return (
        <div className="meteo-wrapper">
            {markers.length === 0 && (
                <div className="meteo-no-marker-selected">
                    <div>Afin de visualiser les graphiques, veuillez placer des repères sur la carte.</div>
                    <Button
                        className={classNames("add-marker-button", { active: isSettingMarker })}
                        type="primary"
                        onClick={addMarker}
                    >
                        <EnvironmentOutlined style={{ fontSize: 18 }} />
                        Ajouter un repère
                    </Button>
                </div>
            )}
            {markers.length > 0 && (
                <>
                    <div className="header">
                        <ButtonSwitch
                            buttons={markers.map(({ label }) => ({ label }))}
                            value={currentMarkerIndex}
                            onChange={(newValue) => setCurrentMakerIndex(newValue)}
                            onClose={onCloseTab}
                            className="marker-switch"
                            buttonClassName="marker-button"
                        />
                        <Button
                            className={classNames("add-marker-button", { active: isSettingMarker })}
                            type="primary"
                            onClick={addMarker}
                        >
                            <EnvironmentOutlined style={{ fontSize: 18 }} />
                            Ajouter un repère
                        </Button>
                    </div>
                    <div className="meteo-inner-wrapper">
                        {isLoading ? (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
                        ) : forecasts[getCurrentMarkerId()] ? (
                            forecasts[getCurrentMarkerId()].map((weatherForecast) => (
                                <MeteoCard key={weatherForecast.time} {...weatherForecast} />
                            ))
                        ) : null}
                    </div>
                </>
            )}
        </div>
    );
};
