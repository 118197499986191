import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { CommentOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { WaterwayQuotationsData } from "../../Shared/useWaterways";
import { LayoutContext } from "../../Shared/contexts/LayoutContextProvider";
import { NavigabilityFeedbacks } from "../NavigabilityFeedbacks/NavigabilityFeedbacks";
import { ITerminal } from "../useTerminals";
import "./NavigabilityFeedbackButton.less";

interface INavigabilityFeedbackButton {
    fairways: WaterwayQuotationsData[];
    terminals: ITerminal[];
    isWaterwaysLoading: boolean;
}

export const NavigabilityFeedbackButton = ({
    fairways,
    terminals,
    isWaterwaysLoading,
}: INavigabilityFeedbackButton) => {
    const [showNavigabilityFeedbacks, setShowNavigabilityFeedbacks] = useState(false);
    const { setOnMapModal } = useContext(LayoutContext);

    useEffect(() => {
        if (!setOnMapModal) {
            return;
        }
        showNavigabilityFeedbacks
            ? setOnMapModal({
                  title: (
                      <span style={{ fontWeight: 400 }}>
                          <b>Commentaires</b> - Retours d&rsquo;expérience
                      </span>
                  ),
                  content: <NavigabilityFeedbacks fairways={fairways} terminals={terminals} />,
                  onClose: () => setShowNavigabilityFeedbacks(false),
                  fullscreen: true,
              })
            : setOnMapModal(null);
    }, [showNavigabilityFeedbacks, setOnMapModal]);

    const toggleShowModal = () => {
        setShowNavigabilityFeedbacks((show) => !show);
    };

    return (
        <Tooltip title="Commenter la navigabilité">
            <button
                className={classNames("navigability-feedback-button", { active: showNavigabilityFeedbacks })}
                onClick={toggleShowModal}
                disabled={isWaterwaysLoading}
            >
                <CommentOutlined />
            </button>
        </Tooltip>
    );
};
