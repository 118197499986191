import React, { useMemo } from "react";
import { ColumnsType } from "antd/lib/table";
import { WaterwayType } from "../Shared/useWaterways";
import WaterwayCell from "../Shared/components/WaterwayList/WaterwayCell";
import { IBbox } from "../interfaces/IBbox";
import ColorStatus, { Color } from "../Shared/components/ColorStatus/ColorStatus";
import TimeStampCell from "./TimeStampCell/TimeStampCell";
import { InfoCircleOutlined } from "@ant-design/icons";

const numberFormat = new Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 });

export interface TerminalNavigabilityData {
    key: number;
    name: string;
    type: WaterwayType.TERMINAL;
    timestamp: string;
    ordre: number;
    bounds: IBbox["bounds"];
    tenueAmarres: number;
    submersion: number;
    bathymetrie: number;
}

function useTerminalNavigabilityColumns() {
    return useMemo(
        () =>
            [
                {
                    title: "Nom",
                    dataIndex: "name",
                    className: "first-col border-right",
                    render: (name, { bounds }: TerminalNavigabilityData) => (
                        <WaterwayCell name={name} bounds={bounds} zoomOffset={-2} />
                    ),
                },
                {
                    title: "Passage prévu",
                    dataIndex: "timestamp",
                    className: "border-right-half timepicker",
                    render: (timestamp) => <TimeStampCell timestamp={timestamp} />,
                },
                {
                    title: "Turbidité (g/l)",
                    dataIndex: "turbidite",
                    className: "border-right-half",
                    render: (turbidite) => numberFormat.format(turbidite),
                },
                {
                    title: "Bathymétrie (m)",
                    dataIndex: "bathymetrie",
                    className: "border-right-half",
                    render: (bathymetrie) => numberFormat.format(bathymetrie),
                },
                { title: "Courant (m/s)", dataIndex: "swell", render: (swell) => numberFormat.format(swell) },
                {
                    title: (
                        <>
                            Tenue des amarres
                            <a
                                target="_blank"
                                href="https://giros-fleuve.jumeaux-numeriques.fr/documentation/modules/navigabilite/index.html#tenue-des-amarres"
                                rel="noreferrer"
                                className="documentation-link"
                            >
                                <InfoCircleOutlined />
                            </a>
                        </>
                    ),
                    dataIndex: "tenueAmarres",
                    className: "border-right-half",
                    render: (tenueAmarres) => numberFormat.format(tenueAmarres),
                },
                {
                    title: (
                        <>
                            Submersion
                            <a
                                target="_blank"
                                href="https://giros-fleuve.jumeaux-numeriques.fr/documentation/modules/navigabilite/index.html#submersion"
                                rel="noreferrer"
                                className="documentation-link"
                            >
                                <InfoCircleOutlined />
                            </a>
                        </>
                    ),
                    dataIndex: "submersion",
                    className: "border-right-half quotation-col",
                    render: (submersion) => <ColorStatus color={submersion <= 7 ? Color.GREEN : Color.RED} />,
                },
                {
                    title: (
                        <>
                            Squat
                            <a
                                target="_blank"
                                href="https://giros-fleuve.jumeaux-numeriques.fr/documentation/modules/navigabilite/index.html#squat"
                                rel="noreferrer"
                                className="documentation-link"
                            >
                                <InfoCircleOutlined />
                            </a>
                        </>
                    ),
                    dataIndex: "squat",
                    className: "border-right-half",
                    render: (squat) => numberFormat.format(squat),
                },
            ] as ColumnsType<TerminalNavigabilityData>,
        [],
    );
}

export default useTerminalNavigabilityColumns;
