import React, { useEffect, useMemo, useRef, useState } from "react";
import { ButtonSwitch } from "../ButtonSwitch/ButtonSwitch";
import { PlanningItem } from "./PlanningItem/PlanningItem";
import {
    EPlanningLineType,
    EPlanningTheme,
    IPlanning,
    IPlanningLegend,
    IPlanningTimeRange,
    IPrefectoralDecree,
    IPrefectoralDecreeTimeRange,
} from "./types";
import classNames from "classnames";
import { FoldablePlanningSection } from "./FoldablePlanningSection/FoldablePlanningSection";
import { InfoCircleOutlined, LockFilled, PlusCircleOutlined } from "@ant-design/icons";
import { CreateTimeRangePopup } from "./CreateTimeRangePopup/CreateTimeRangePopup";
import {
    getPrefectoralDecreeAdditionalInformation,
    showPrefectoralDecreeTooltip,
    onCreateTimeRange,
    onDeleteTimeRange,
    onResizeTimeRange,
    toggleLineLocked,
    onCreatePrefectoralDecree,
} from "./planning.utils";
import { CreatePrefectoralDecree } from "./CreatePrefectoralDecree/CreatePrefectoralDecree";
import "./Planning.less";
import { Tooltip } from "antd";

interface IPlanningProps {
    legend: Array<IPlanningLegend>;
    timespans: Array<{ label: string; value: number; columnGap: number }>;
    value: IPlanning;
    onChange?: (value: IPlanning) => void;
    showCursor?: boolean;
    showTimespanButtons?: boolean;
    showDate?: boolean;
    header?: React.ReactNode;
    defaultTimespanIndex?: number;
    foldableSections?: boolean;
    theme?: EPlanningTheme;
    blockWidth?: number;
    scrollLeft?: number;
}

const DAY_TO_MS = 1000 * 60 * 60 * 24;
const PLANNING_HEADER_WIDTH = 185;
const monthShortNames = ["jan", "fév", "mar", "avr", "mai", "jui", "jul", "aoû", "sep", "oct", "nov", "déc"];

export const Planning = ({
    legend,
    timespans,
    showCursor,
    value,
    header,
    onChange,
    showTimespanButtons,
    defaultTimespanIndex = 0,
    theme = EPlanningTheme.DEFAULT,
    blockWidth,
    foldableSections,
    showDate,
    scrollLeft,
}: IPlanningProps) => {
    const [timeSpanIndex, setTimeSpanIndex] = useState(defaultTimespanIndex);
    const [oneDayWidth, setOneDayWidth] = useState(blockWidth || 0);
    const [isResizing, setIsResizing] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState<
        [number, number, number] | [number, number, number, IPrefectoralDecree] | null
    >(null);
    const gridRef = useRef<HTMLDivElement | null>(null);

    const today = Math.floor(new Date().getTime() / DAY_TO_MS) * DAY_TO_MS;
    const average = Math.floor(timespans[timeSpanIndex].value / 2);

    useEffect(() => {
        if (!gridRef.current || blockWidth !== undefined) {
            return;
        }

        const onResize = () =>
            gridRef.current && setOneDayWidth(gridRef.current.offsetWidth / timespans[timeSpanIndex].value);
        window.addEventListener("resize", onResize);

        setOneDayWidth(gridRef.current.offsetWidth / timespans[timeSpanIndex].value);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [timeSpanIndex, gridRef, blockWidth, timespans]);

    const handlePlanningEdition = (planningEdition: () => IPlanning) => {
        if (!onChange) {
            return;
        }
        const newValue = planningEdition();
        onChange(newValue);
    };

    const isCreatePopupDisplayed = (sectionIndex: number, lineIndex: number, timeRangeIndex: number) => {
        return (
            showCreatePopup &&
            showCreatePopup[0] === sectionIndex &&
            showCreatePopup[1] === lineIndex &&
            showCreatePopup[2] === timeRangeIndex
        );
    };

    const getOnClickCb = (lineType: EPlanningLineType, sectionIndex: number, lineIndex: number, itemId: string) => {
        if (lineType === EPlanningLineType.PREFECTURAL_DECREE) {
            const itemIndex = value.sections[sectionIndex].sectionData[lineIndex].linePlanning.findIndex(
                ({ id }) => id === itemId,
            );
            const item = value.sections[sectionIndex].sectionData[lineIndex].linePlanning[
                itemIndex
            ] as IPrefectoralDecreeTimeRange;
            const prefectoralDecree = value.prefectoralDecrees.find(
                ({ id }) => id === item.prefectoralDecreeId,
            ) as IPrefectoralDecree;
            setShowCreatePopup([sectionIndex, lineIndex, itemIndex, prefectoralDecree]);
        }
    };

    const { calendar, formattedValue } = useMemo(() => {
        // "calendar" is the data used to display the grids behind the planning,
        // depending on the timespan, a column can represent a day
        // or a group of days (columnGap)
        const calendar: Array<Date> = [];
        for (let i = -average; i < (timespans[timeSpanIndex].value % 2 === 0 ? average : average + 1); i++) {
            calendar.push(new Date(today + DAY_TO_MS * i));
        }
        // "formattedValue" is the data used to display the planning blocks.
        // This adds a size property that will enable to know long lasts an activity (in days)
        const formattedValue = value.sections.map(({ sectionLabel, sectionData }) => ({
            sectionLabel,
            sectionData: sectionData.map(({ lineLabel, linePlanning, ...lineRest }) => ({
                ...lineRest,
                lineLabel,
                linePlanning: calendar.reduce(
                    (acc: Array<IPlanningTimeRange & { size: number; position: number }>, curDate, index) => {
                        const curDateTs = new Date(curDate).getTime();
                        const currentActivty = linePlanning.find(({ from, to }) => {
                            const fromTs = new Date(from).getTime();
                            const toTs = new Date(to).getTime();

                            return fromTs <= curDateTs && curDateTs <= toTs;
                        });
                        if (!currentActivty) {
                            return acc;
                        }
                        const lastActivity = acc[acc.length - 1];
                        if (lastActivity && lastActivity.id === currentActivty.id) {
                            lastActivity.size++;
                        } else {
                            acc.push({ ...currentActivty, size: 1, position: index });
                        }

                        return acc;
                    },
                    [],
                ),
            })),
        }));

        return {
            calendar: calendar.filter((_val, index) => index % timespans[timeSpanIndex].columnGap === 0),
            formattedValue,
        };
    }, [value, timeSpanIndex, today, average, timespans]);

    const showStickyColumn = scrollLeft !== undefined && scrollLeft > PLANNING_HEADER_WIDTH;

    return (
        <div className={classNames("planning-wrapper", theme)}>
            {header}
            <div
                className="planning"
                style={{ width: blockWidth && blockWidth * calendar.length + PLANNING_HEADER_WIDTH }}
            >
                <div className="grid" ref={gridRef}>
                    {calendar.map((date, index) => (
                        <div
                            key={index}
                            className={classNames("grid-column", {
                                weekend: [0, 6].includes(date.getDay()),
                            })}
                            style={{ width: oneDayWidth * timespans[timeSpanIndex].columnGap }}
                        >
                            <div className="column-background" />
                            {showDate && (
                                <div className="column-date">
                                    {timespans[timeSpanIndex].columnGap > 1
                                        ? `${date.getDate()} ${monthShortNames[date.getMonth()]}`
                                        : date.getDate()}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="schedule">
                    {formattedValue.map(
                        ({ sectionLabel, sectionData }, sectionIndex) =>
                            sectionData.length > 0 && (
                                <FoldablePlanningSection
                                    key={sectionLabel}
                                    label={sectionLabel}
                                    isFoldable={Boolean(foldableSections)}
                                >
                                    {sectionData.map(
                                        ({ lineLabel, linePlanning, type: lineType, editable, locked }, lineIndex) => {
                                            const isLineEditable = Boolean(onChange) && editable && !locked;
                                            return (
                                                <div
                                                    key={lineLabel}
                                                    style={{
                                                        width: oneDayWidth * timespans[timeSpanIndex].value + 185,
                                                    }}
                                                >
                                                    <h2>
                                                        {lineLabel}
                                                        {showPrefectoralDecreeTooltip(
                                                            lineType,
                                                            linePlanning as unknown as IPrefectoralDecreeTimeRange[],
                                                        ) && (
                                                            <Tooltip
                                                                title={getPrefectoralDecreeAdditionalInformation(
                                                                    linePlanning as unknown as IPrefectoralDecreeTimeRange[],
                                                                )}
                                                            >
                                                                <InfoCircleOutlined />
                                                            </Tooltip>
                                                        )}
                                                    </h2>
                                                    {Boolean(onChange) && (
                                                        <button
                                                            type="button"
                                                            disabled={!editable}
                                                            onClick={() =>
                                                                handlePlanningEdition(() =>
                                                                    toggleLineLocked(value, sectionIndex, lineIndex),
                                                                )
                                                            }
                                                            className={classNames({ locked: locked || !editable })}
                                                        >
                                                            <LockFilled />
                                                        </button>
                                                    )}
                                                    <div>
                                                        {isLineEditable &&
                                                            !isResizing &&
                                                            calendar.map((date, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div
                                                                        className={classNames(
                                                                            "create-time-range-button",
                                                                            {
                                                                                selected: isCreatePopupDisplayed(
                                                                                    sectionIndex,
                                                                                    lineIndex,
                                                                                    index,
                                                                                ),
                                                                            },
                                                                        )}
                                                                        style={{
                                                                            width: oneDayWidth,
                                                                            left: index * oneDayWidth,
                                                                        }}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setShowCreatePopup([
                                                                                    sectionIndex,
                                                                                    lineIndex,
                                                                                    index,
                                                                                ])
                                                                            }
                                                                        >
                                                                            <PlusCircleOutlined />
                                                                        </button>
                                                                    </div>
                                                                    {isCreatePopupDisplayed(
                                                                        sectionIndex,
                                                                        lineIndex,
                                                                        index,
                                                                    ) &&
                                                                        (lineType ===
                                                                        EPlanningLineType.PREFECTURAL_DECREE ? (
                                                                            <CreatePrefectoralDecree
                                                                                from={calendar[index]}
                                                                                onClose={() => setShowCreatePopup(null)}
                                                                                prefectoralDecree={
                                                                                    (showCreatePopup &&
                                                                                        showCreatePopup[3]) ||
                                                                                    undefined
                                                                                }
                                                                                onCreate={(
                                                                                    prefectoralDecree: IPrefectoralDecree,
                                                                                    isUpdate: boolean,
                                                                                ) =>
                                                                                    handlePlanningEdition(() =>
                                                                                        onCreatePrefectoralDecree(
                                                                                            value,
                                                                                            sectionIndex,
                                                                                            lineIndex,
                                                                                            prefectoralDecree,
                                                                                            isUpdate,
                                                                                        ),
                                                                                    )
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <CreateTimeRangePopup
                                                                                left={index * oneDayWidth}
                                                                                from={calendar[index]}
                                                                                isCommercialCommitment={
                                                                                    lineType ===
                                                                                    EPlanningLineType.COMMERCIAL_COMMITMENTS
                                                                                }
                                                                                onClose={() => setShowCreatePopup(null)}
                                                                                onCreate={(
                                                                                    timerange: Partial<IPlanningTimeRange>,
                                                                                ) =>
                                                                                    handlePlanningEdition(() =>
                                                                                        onCreateTimeRange(
                                                                                            value,
                                                                                            sectionIndex,
                                                                                            lineIndex,
                                                                                            timerange,
                                                                                        ),
                                                                                    )
                                                                                }
                                                                            />
                                                                        ))}
                                                                </React.Fragment>
                                                            ))}
                                                        {linePlanning.map((planningItem, itemIndex) => (
                                                            <PlanningItem
                                                                key={itemIndex}
                                                                blockWidth={oneDayWidth}
                                                                legend={legend}
                                                                editable={isLineEditable}
                                                                setIsResizing={setIsResizing}
                                                                {...planningItem}
                                                                {...(lineType !== EPlanningLineType.PREFECTURAL_DECREE
                                                                    ? {
                                                                          onDelete: (itemId: string) =>
                                                                              handlePlanningEdition(() =>
                                                                                  onDeleteTimeRange(
                                                                                      value,
                                                                                      sectionIndex,
                                                                                      lineIndex,
                                                                                      itemId,
                                                                                  ),
                                                                              ),
                                                                          onResize: (
                                                                              itemId: string,
                                                                              keyToModify: "from" | "to",
                                                                              blocks: number,
                                                                          ) =>
                                                                              handlePlanningEdition(() =>
                                                                                  onResizeTimeRange(
                                                                                      value,
                                                                                      sectionIndex,
                                                                                      lineIndex,
                                                                                      itemId,
                                                                                      keyToModify,
                                                                                      blocks,
                                                                                  ),
                                                                              ),
                                                                      }
                                                                    : {
                                                                          onClick: (itemId: string) =>
                                                                              getOnClickCb(
                                                                                  lineType,
                                                                                  sectionIndex,
                                                                                  lineIndex,
                                                                                  itemId,
                                                                              ),
                                                                      })}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                </FoldablePlanningSection>
                            ),
                    )}
                    {showCursor && (
                        <div
                            className="cursor"
                            style={{
                                marginLeft: oneDayWidth * average,
                            }}
                        >
                            <div className="cursor-inner">{new Date(today).toLocaleDateString()}</div>
                        </div>
                    )}
                </div>
                {showStickyColumn && (
                    <div className="schedule-sticky-column">
                        {formattedValue.map(
                            ({ sectionLabel, sectionData }) =>
                                sectionData.length > 0 && (
                                    <section key={sectionLabel}>
                                        <h1>{sectionLabel}</h1>
                                        {sectionData.map(({ lineLabel, type: lineType, linePlanning }) => (
                                            <h2 key={lineLabel}>
                                                {lineLabel}
                                                {showPrefectoralDecreeTooltip(
                                                    lineType,
                                                    linePlanning as unknown as IPrefectoralDecreeTimeRange[],
                                                ) && (
                                                    <Tooltip
                                                        title={getPrefectoralDecreeAdditionalInformation(
                                                            linePlanning as unknown as IPrefectoralDecreeTimeRange[],
                                                        )}
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                )}
                                            </h2>
                                        ))}
                                    </section>
                                ),
                        )}
                    </div>
                )}
            </div>
            {showTimespanButtons && (
                <ButtonSwitch
                    className="planning-timespan-buttons"
                    value={timeSpanIndex}
                    buttons={timespans}
                    onChange={(nextTimeSpanIndex) => setTimeSpanIndex(nextTimeSpanIndex)}
                />
            )}
        </div>
    );
};
