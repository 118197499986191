import MiscUtils from "qwc2/utils/MiscUtils";

export enum FetchMethods {
    GET = "GET",
    POST = "POST",
    DELETE = "DELETE",
}

export enum EVigieSipEndpoints {
    EXPORT_DEMANDES_FACTURATION = "export_demandes_facturation",
    NAVIRES_A_QUAI = "navires_a_quai",
}

export const getBaseHeaders = () => ({
    "X-CSRF-TOKEN": MiscUtils.getCsrfToken(),
    "Content-Type": "application/json",
    accept: "application/json",
});

interface IFetchSLFOptions {
    method?: FetchMethods;
    content?: any;
    headers?: Record<string, string>;
}

async function request(input: string, init: RequestInit | undefined) {
    const response = await fetch(input, init);

    if (!response?.ok) {
        throw new Error(String(response.status));
    }

    return response?.json();
}

export async function fetchSLF(path: string, options?: IFetchSLFOptions) {
    const headers = {
        ...(options?.headers && { ...options.headers }),
        ...getBaseHeaders(),
    };
    const body =
        options?.method === FetchMethods.POST && options?.content != null ? JSON.stringify(options?.content) : null;
    return request(`${process.env.REACT_APP_SLF_API_URL}/${path}`, {
        method: options?.method ? options.method : FetchMethods.GET,
        headers,
        body,
    });
}

export async function fetchGitLab(path: string, method: FetchMethods = FetchMethods.GET, content?: any) {
    return request(`${process.env.REACT_APP_GITLAB_API_URL}/${path}`, {
        method: method,
        headers: {
            ...getBaseHeaders(),
            "qwc2-token": `${process.env.REACT_APP_QWC2_TOKEN}`,
        },
        body: method === FetchMethods.POST && content != null ? JSON.stringify(content) : null,
    });
}

export async function fetchVigieSip(endpoint: EVigieSipEndpoints) {
    const response = await fetch(`${process.env.REACT_APP_VIGIESIP_BASE_URL}/${endpoint}`);

    return response.json();
}

export async function fetchSimulation(content: {
    start_date: string;
    end_date: string;
    model_name: string;
    project_id: number;
    sources: string[];
}) {
    const body = JSON.stringify(content);
    const contentLength = body.length.toString();
    return request(`${process.env.REACT_APP_CATALOG_API_URL}/simulation/create`, {
        method: FetchMethods.POST,
        headers: {
            ...getBaseHeaders(),
            "Content-Length": contentLength,
            "x-simulation-token": `${process.env.REACT_APP_QWC2_TOKEN}`,
        },
        body,
    });
}

export async function fetchQWC2Theme() {
    return request("themes.json", { method: FetchMethods.GET, headers: getBaseHeaders() });
}
