import React, { useEffect, useState } from "react";
import { Button, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./LayersMenuDataPanel.less";
import DataExistingStudySelectForm from "./components/DataExistingStudySelectForm";
import DataNewStudyCreateForm from "./components/DataNewStudyCreateForm";
import DataStudyEditSteps from "./components/DataStudyEditSteps";
import DataSelectedStudyName from "./components/DataSelectedStudyName";

interface ILayersMenuDataPanel {
    onClose: () => void;
    onStudySelected?: (studyId?: number) => void;
}

export default function LayersMenuDataPanel({ onClose, onStudySelected }: ILayersMenuDataPanel) {
    const [newStudy, setNewStudy] = useState<IStudy>();
    const [selectedStudy, setSelectedStudy] = useState<IStudy>();
    const study = newStudy ?? selectedStudy;

    useEffect(() => {
        onStudySelected && onStudySelected(selectedStudy?.id);
    }, [onStudySelected, selectedStudy]);

    return (
        <section className="layers-menu-data-panel extra-left-panel">
            <header>
                <h3>Gestion des études</h3>
                <Button type="text" className="close-button" onClick={onClose}>
                    <CloseOutlined />
                </Button>
            </header>
            {!study && (
                <>
                    <DataExistingStudySelectForm onStudySelected={setSelectedStudy} />
                    <Divider plain orientation="left">
                        ou
                    </Divider>
                    <DataNewStudyCreateForm onStudyCreated={setNewStudy} />
                </>
            )}
            {selectedStudy && (
                <DataSelectedStudyName selectedStudy={selectedStudy} onCancel={() => setSelectedStudy(undefined)} />
            )}
            <DataStudyEditSteps study={newStudy} onCancel={() => setNewStudy(undefined)} />
        </section>
    );
}

export interface IStudy {
    id: number;
    name: string;
    path: string;
    namespace_id: number;
    fromModelId: number;
    fromModelName: string;
    fromModelPath: string;
    web_url: string;
    default_branch: string;
}
