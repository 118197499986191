import { useCallback, useContext } from "react";
import { OLContext } from "qwc2/contexts/OLContext";

export default function useOLResize(transitionDuration = 0) {
    const { olMap }: { olMap: any } = useContext(OLContext);
    const resizeOLMap = useCallback(() => {
        setTimeout(() => {
            olMap?.updateSize();
        }, transitionDuration);
    }, [transitionDuration, olMap]);
    return resizeOLMap;
}
