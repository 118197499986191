import React, { useState } from "react";
import { IQWC2Layer } from "../../Interfaces/IQWC2Layer";
import { Collapse } from "antd";
import "./LayerTree.less";

const { Panel } = Collapse;

interface ILayerTree {
    layerTree: IQWC2Layer;
    rootLayerUuid: string;
    LeafRenderer: React.FC<{ layer: IQWC2Layer; rootLayerUuid: string; layerPath: number[] }>;
    level?: number;
    layerPath?: number[];
}

export const LayerTree: React.FC<ILayerTree> = ({
    layerTree,
    LeafRenderer,
    level = 0,
    rootLayerUuid,
    layerPath = [],
}: ILayerTree) => {
    const [activeKeys, setActiveKeys] = useState<string[]>(
        (layerTree?.sublayers || []).filter(({ expanded }) => !!expanded).map(({ name }) => name),
    );
    const handleChange = (newActiveKeys: string | string[]) => {
        setActiveKeys(newActiveKeys as string[]);
    };
    return (
        <Collapse key={layerTree.name} activeKey={activeKeys} onChange={handleChange}>
            {layerTree.sublayers.map((subLayer: IQWC2Layer, index) => {
                const curLayerPath = [...layerPath, index];
                if (subLayer.sublayers) {
                    return (
                        <Panel
                            header={
                                <div className="panel-header">
                                    <h1>{subLayer.title}</h1>
                                </div>
                            }
                            key={subLayer.name}
                            style={{ marginLeft: 10 * level }}
                        >
                            <LayerTree
                                layerTree={subLayer}
                                LeafRenderer={LeafRenderer}
                                level={level + 1}
                                rootLayerUuid={rootLayerUuid}
                                layerPath={curLayerPath}
                            />
                        </Panel>
                    );
                } else {
                    return (
                        <LeafRenderer
                            key={subLayer.name}
                            layer={subLayer}
                            rootLayerUuid={rootLayerUuid}
                            layerPath={curLayerPath}
                        />
                    );
                }
            })}
        </Collapse>
    );
};
