import React, { useCallback, useEffect, useState } from "react";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import TopBarButton from "../../Shared/components/ModuleTopBar/TopBarButton/TopBarButton";
import { MenuModal } from "../../Shared/components/MenuModal/MenuModal";
import { fetchSLF } from "../../Shared/utils/fetch";
import "./NavigabilityAlertLogButton.less";
import { NavigabilityAlertLogTable } from "../NavigabilityAlertLogTable/NavigabilityAlertLogTable";

export interface INavigabilityAlert {
    id: number;
    poste_a_quai: string;
    cote_seuil_alerte: number;
    date_debut_depassement: string;
    date_fin_depassement: string;
    cote_maximale_prevue: number;
    date_cote_maximale_prevue: string;
}
export interface INavigabilityAlertLog {
    d1: INavigabilityAlert[];
    d30: INavigabilityAlert[];
    d100: INavigabilityAlert[];
}

const DAY_TO_MS = 1000 * 60 * 60 * 24;

export const NavigabilityAlertLogButton = () => {
    const [showAlertLog, setShowAlertLog] = useState(false);
    const [alertLog, setAlertLog] = useState<INavigabilityAlertLog>({ d1: [], d30: [], d100: [] });
    const [loading, setLoading] = useState(false);

    const fetchAlerts = useCallback(async () => {
        setLoading(true);
        try {
            const alerts: INavigabilityAlert[] = await fetchSLF("navigabilite/alerts");
            const nowTs = new Date().getTime();
            const alertLog = alerts.reduce(
                (log, curAlert) => {
                    const deltaDays = Math.floor(
                        (new Date(curAlert.date_cote_maximale_prevue).getTime() - nowTs) / DAY_TO_MS,
                    );
                    if (deltaDays <= 30) {
                        log.d1.push(curAlert);
                    } else if (deltaDays >= 30 && deltaDays <= 100) {
                        log.d30.push(curAlert);
                    } else {
                        log.d100.push(curAlert);
                    }

                    return log;
                },
                { d1: [], d30: [], d100: [] } as INavigabilityAlertLog,
            );

            Object.values(alertLog).forEach((log) =>
                log.sort((a: INavigabilityAlert, b: INavigabilityAlert) => {
                    return new Date(b.date_debut_depassement).getTime() - new Date(a.date_debut_depassement).getTime();
                }),
            );
            setAlertLog(alertLog);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    return (
        <div className="navigability-alert-log-wrapper">
            <TopBarButton
                tooltip="Journal des alertes pour le risque de submersion"
                className="alert-log-button"
                active={showAlertLog}
                onClick={() => setShowAlertLog((show) => !show)}
                disabled={loading}
            >
                {loading ? <LoadingOutlined /> : <WarningOutlined />}
            </TopBarButton>
            {showAlertLog && (
                <MenuModal
                    onClose={() => setShowAlertLog(false)}
                    title="Journal des alertes pour le risque de submersion"
                    icon={<WarningOutlined />}
                >
                    <NavigabilityAlertLogTable alertLog={alertLog} />
                </MenuModal>
            )}
        </div>
    );
};
