import { useCallback, useEffect, useState } from "react";
import { FetchMethods, fetchSLF } from "../../Shared/utils/fetch";

export interface ITideGauge {
    gid: number;
    mare_id: number;
    mare_pk: number;
    mare_x: number;
    mare_y: number;
    mare_desig: string;
    mare_diff_: number | null;
    mare_date_: string;
    mare_offse: number;
    level_water_etiage: number;
    level_water_ign69: number;
    mare_ordre: number;
}

export default function useTideGauge() {
    const [tideGauges, setTideGauges] = useState<ITideGauge[]>([]);
    const [initialized, setInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTideGauges = useCallback(async () => {
        try {
            setIsLoading(true);
            const data = await fetchSLF(`dragage/maregraphes/level_water`);
            setTideGauges(data);
            setInitialized(true);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!isLoading && !initialized) {
            fetchTideGauges();
        }
    }, [isLoading, initialized, fetchTideGauges]);

    const updateTideGauges = useCallback(
        async (newTideGauges: ITideGauge[]) => {
            try {
                setIsLoading(true);
                await fetchSLF(`dragage/maregraphes/level_water_pk`, {
                    headers: {
                        "qwc2-token": process.env.REACT_APP_QWC2_TOKEN || "",
                    },
                    method: FetchMethods.POST,
                    content: {
                        dataset: newTideGauges.map(
                            ({ mare_desig, mare_pk, level_water_etiage, level_water_ign69 }) => ({
                                name: mare_desig,
                                pk: mare_pk,
                                level_water_etiage,
                                level_water_ign69,
                            }),
                        ),
                    },
                });
                await fetchTideGauges();
            } catch (err) {
                throw new Error(String(err));
            } finally {
                setIsLoading(false);
            }
        },
        [fetchTideGauges],
    );

    return { tideGauges, isLoading, updateTideGauges };
}
