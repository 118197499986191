import React, { useEffect, useState } from "react";
import TopBarButton from "../../Shared/components/ModuleTopBar/TopBarButton/TopBarButton";
import { ZHRefSettingsForm } from "./ZhRefSettingsForm/ZhRefSettingsForm";
import { ControlOutlined } from "@ant-design/icons";
import { ITideGauge } from "./useTideGauge";
import { IMapModal } from "../../Shared/components/MapModal/MapModal";

interface IZhRefSettingsButton {
    disabled: boolean;
    tideGauges: ITideGauge[];
    updateTideGauges: (newTideGauges: ITideGauge[]) => Promise<void>;
    displayModal: ((newValue: IMapModal | null) => void) | null;
}

export const ZhRefSettingsButton = ({ disabled, tideGauges, updateTideGauges, displayModal }: IZhRefSettingsButton) => {
    const [showZHREFSettings, setShowZHREFSettings] = useState(false);

    useEffect(() => {
        if (!displayModal) {
            return;
        }
        showZHREFSettings
            ? displayModal({
                  title: "Mise à jour des référentiels ZH, niveaux d'eau",
                  content: <ZHRefSettingsForm tideGauges={tideGauges} updateTideGauges={updateTideGauges} />,
                  onClose: () => setShowZHREFSettings(false),
                  className: "zhref-settings-modal-wrapper",
              })
            : displayModal(null);
    }, [showZHREFSettings, tideGauges]);

    return (
        <TopBarButton
            tooltip="Mise à jour des référentiels ZH"
            className="zhref-settings-button"
            active={showZHREFSettings}
            onClick={() => setShowZHREFSettings((show) => !show)}
            disabled={disabled}
        >
            <ControlOutlined />
        </TopBarButton>
    );
};
