import React from "react";

export const WindDirectionArrowIcon = ({ style }: any) => {
    return (
        <svg style={style} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0606 17.9989C9.9114 17.9989 9.76834 17.9396 9.66285 17.8341C9.55737 17.7287 9.49811 17.5856 9.49811 17.4364V8.99962H1.06097C0.933036 8.99945 0.808984 8.95569 0.709276 8.87554C0.609568 8.79539 0.540161 8.68365 0.512507 8.55875C0.484852 8.43385 0.500602 8.30326 0.557158 8.18851C0.613714 8.07377 0.707696 7.98173 0.823601 7.92758L17.6979 0.0532286C17.8026 0.00412365 17.9199 -0.0115169 18.0338 0.00843697C18.1477 0.0283909 18.2527 0.0829693 18.3345 0.164739C18.4162 0.246509 18.4708 0.351496 18.4908 0.465402C18.5107 0.579308 18.4951 0.696595 18.446 0.801292L10.5713 17.6749C10.5261 17.772 10.4542 17.8541 10.3639 17.9117C10.2736 17.9693 10.1688 17.9999 10.0617 18L10.0606 17.9989Z"
                fill="white"
            />
        </svg>
    );
};
