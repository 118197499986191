import { useCallback, useEffect, useRef, useState } from "react";
import {
    EPlanningLineType,
    IPlanning,
    IPlanningData,
    IPlanningTimeRange,
} from "../../Shared/components/Planning/types";
import { FetchMethods, fetchSLF } from "../../Shared/utils/fetch";
import { IUser } from "../../UserContext";
import { EDraftStatus } from "./types";

const emptyPlanning: IPlanning = {
    prefectoralDecrees: [],
    sections: [
        {
            sectionLabel: "DAM/DAS",
            sectionData: [
                {
                    lineLabel: "Arrêtés préfectoraux",
                    linePlanning: [],
                    type: EPlanningLineType.PREFECTURAL_DECREE,
                    locked: true,
                    editable: true,
                },
                {
                    lineLabel: "Anita Conti",
                    linePlanning: [],
                    type: EPlanningLineType.DEFAULT,
                    editable: true,
                },
                {
                    lineLabel: "Autre drague",
                    linePlanning: [],
                    type: EPlanningLineType.DEFAULT,
                    editable: true,
                },
            ],
        },
        {
            sectionLabel: "DIE",
            sectionData: [
                {
                    lineLabel: "Arrêtés préfectoraux",
                    linePlanning: [],
                    type: EPlanningLineType.PREFECTURAL_DECREE,
                    locked: true,
                    editable: true,
                },
                { lineLabel: "Ostrea", linePlanning: [], type: EPlanningLineType.DEFAULT, editable: true },
                { lineLabel: "Autre drague", linePlanning: [], type: EPlanningLineType.DEFAULT, editable: true },
            ],
        },
        {
            sectionLabel: "Engagements Commerciaux",
            sectionData: [
                {
                    lineLabel: "Autres engagements commerciaux",
                    type: EPlanningLineType.COMMERCIAL_COMMITMENTS,
                    linePlanning: [],
                    editable: true,
                },
            ],
        },
    ],
};

const getPlanningData = async (): Promise<{ id: number; configuration: [IPlanning] }> => {
    try {
        const planning = await fetchSLF("dragage/annual_strategy/programme");
        return planning;
    } catch (err) {
        if (String(err).includes("404")) {
            return { id: 0, configuration: [emptyPlanning] };
        }
        throw new Error(`An error occured while trying to fetch the planning ${err}`);
    }
};

const savePlanning = (planning: IPlanning) => {
    return fetchSLF("dragage/annual_strategy/programme", {
        method: FetchMethods.POST,
        content: { dataset: [planning] },
    });
};

const getDraftPlanning = async (
    user: IUser,
): Promise<{ id?: number; id_programme: number | null; configuration: [IPlanning] | null }> => {
    try {
        const draftPlanning = await fetchSLF("dragage/annual_strategy/draft", {
            headers: {
                "id-user": user.email,
            },
        });
        return draftPlanning;
    } catch (err) {
        if (String(err).includes("404")) {
            return { id_programme: null, configuration: null };
        }
        throw new Error(`An error occured while trying to fetch the draft planning ${err}`);
    }
};

const saveDraftPlanning = (draftPlanning: IPlanning, currentPlanningId: number, user: IUser) => {
    return fetchSLF("dragage/annual_strategy/draft", {
        method: FetchMethods.POST,
        headers: {
            "id-user": user.email,
        },
        content: {
            id_programme: currentPlanningId,
            dataset: [draftPlanning],
        },
    });
};

const deleteDraftPlanning = (user: IUser) => {
    return fetchSLF("dragage/annual_strategy/draft", {
        method: FetchMethods.DELETE,
        headers: {
            "id-user": user.email,
        },
    });
};

interface IUsePlanning {
    isOpen: boolean;
    commercialCommitments: IPlanningTimeRange[] | null;
    user: IUser;
}

const addNonEditableDataToPlanning = (planning: IPlanning, commercialCommitments: IPlanningTimeRange[]): IPlanning => {
    const [DAMDAS, DIE, CC] = planning.sections;
    return {
        ...planning,
        sections: [
            DAMDAS,
            DIE,
            getCommercialCommitmentPlanningData(CC, commercialCommitments),
            {
                sectionLabel: "Informations",
                sectionData: [
                    {
                        lineLabel: "Alertes engraissement",
                        linePlanning: [],
                        type: EPlanningLineType.ALERT,
                        editable: false,
                    },
                ],
            },
        ],
    };
};

const getCommercialCommitmentPlanningData = (CC: IPlanningData, commercialCommitments: IPlanningTimeRange[]) => {
    const nonEditableCCData = {
        lineLabel: "Navires attendus VIGIEsip",
        type: EPlanningLineType.COMMERCIAL_COMMITMENTS,
        linePlanning: commercialCommitments,
        editable: false,
    };
    if (CC != null) {
        return { ...CC, sectionData: [nonEditableCCData, ...CC.sectionData] };
    }
    const [, , emptyCC] = emptyPlanning.sections;
    return { ...emptyCC, sectionData: [nonEditableCCData, ...emptyCC.sectionData] };
};

const getCommercialCommitmentSavableData = (CC: IPlanningData) => ({
    ...CC,
    sectionData: CC.sectionData.filter((data) => data.editable),
});

export const usePlanning = ({ isOpen, commercialCommitments, user }: IUsePlanning) => {
    const [currentPlanningId, setCurrentPlanningId] = useState<number>(0);
    const [planning, setPlanning] = useState<IPlanning>(emptyPlanning);
    const [planningDraft, setPlanningDraft] = useState<IPlanning | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showMismatchVersion, setShowMismatchVersion] = useState(false);
    const [draftStatus, setDraftStatus] = useState<EDraftStatus | null>(null);
    const savingDraftTimeout = useRef<null | ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            try {
                const { id_programme, configuration: draftPlanning } = await getDraftPlanning(user);
                if (draftPlanning) {
                    const draftPlanningWithNonEditableData = addNonEditableDataToPlanning(
                        draftPlanning[0],
                        commercialCommitments as IPlanningTimeRange[],
                    );
                    setPlanningDraft(draftPlanningWithNonEditableData);
                }

                const { id, configuration: planning } = await getPlanningData();
                const planningWithNonEditableData = addNonEditableDataToPlanning(
                    planning[0],
                    commercialCommitments as IPlanningTimeRange[],
                );
                setPlanning(planningWithNonEditableData);
                setCurrentPlanningId(id);
                if (id_programme !== null && id !== id_programme) {
                    setShowMismatchVersion(true);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        if (isOpen && commercialCommitments !== null) {
            init();
        }
    }, [isOpen, commercialCommitments, user]);

    const onEdit = useCallback(
        async (newValue: IPlanning) => {
            if (!currentPlanningId) {
                return;
            }
            setPlanningDraft(newValue);
            const [DAMDAS, DIE, CC] = newValue.sections;
            if (savingDraftTimeout.current) {
                clearTimeout(savingDraftTimeout.current);
            }
            savingDraftTimeout.current = setTimeout(async () => {
                setDraftStatus(EDraftStatus.SAVING);
                try {
                    await saveDraftPlanning(
                        {
                            ...newValue,
                            sections: [DAMDAS, DIE, getCommercialCommitmentSavableData(CC)],
                        },
                        currentPlanningId,
                        user,
                    );
                    setDraftStatus(EDraftStatus.SAVED);
                } catch (err) {
                    console.error(`An error occured while trying to save the draft planning, ${err}`);
                    setDraftStatus(EDraftStatus.ERROR);
                }
            }, 500);
        },
        [user, currentPlanningId],
    );

    const onDiscard = useCallback(async () => {
        try {
            await deleteDraftPlanning(user);
            setPlanningDraft(null);
            if (showMismatchVersion) {
                setShowMismatchVersion(false);
            }
        } catch (err) {
            console.error(`An error occured while trying to delete the draft planning, ${err}`);
        }
    }, [user, showMismatchVersion]);

    const onSave = useCallback(async () => {
        if (!planningDraft) {
            return;
        }
        try {
            const newPlanning = { ...planningDraft };
            const [DAMDAS, DIE, CC] = newPlanning.sections;
            const { id_programme } = await savePlanning({
                ...newPlanning,
                sections: [DAMDAS, DIE, getCommercialCommitmentSavableData(CC)],
            });
            setPlanning(newPlanning);
            setCurrentPlanningId(id_programme);
            await onDiscard();
        } catch (err) {
            console.error(`An error occured while trying to save the planning, ${err}`);
        }
    }, [planningDraft, onDiscard]);

    return {
        planning,
        planningDraft,
        isLoading,
        draftStatus,
        showMismatchVersion,
        setShowMismatchVersion,
        onEdit,
        onDiscard,
        onSave,
    };
};
