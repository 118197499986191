import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IBbox } from "../interfaces/IBbox";
import { FetchMethods, fetchSLF } from "./utils/fetch";
import { changeZoomLevel } from "qwc2/actions/map";

export enum WaterwayType {
    FAIRWAY = "passes",
    DEFILE = "souilles",
    TERMINAL = "poste à quai",
}

export interface WaterwayQuotationsData {
    key: number;
    name: string;
    type: WaterwayType;
    ordre: number;
    quotationModel: number;
    quotationCorrected: number;
    quotation1d: number;
    quotation30d: number;
    quotation100d: number;
    targetQuotation: number;
    dredgingQuotation: number;
    bounds: IBbox["bounds"];
    pk: string;
    pkCorrected: string;
    zhRef: string | null;
    zone?: string;
}

interface WaterwayDTO {
    type: string;
    secteur: string;
    ordre: number;
    x_min: number;
    x_max: number;
    y_min: number;
    y_max: number;
    quotation_model: number;
    quotation_model_corrected: number;
    quotation_1d: number;
    quotation_30d: number;
    quotation_100d: number;
    quotation_target: number;
    quotation_dredging: number;
    zh_ref: string | null;
}

interface FairwayDTO extends WaterwayDTO {
    gid: number;
    type: "PASSE";
    pas_nom: string;
    pas_chenal: string;
    pas_pk: string;
    pas_pk_corrected: string;
    pas_pk_d: number;
    pas_pk_f: number;
    pas_long: number;
    pas_code: string;
    pas_comm: string;
    typ_chenal: string;
    delta: number;
    zone_num: string;
}

interface DefileDTO extends WaterwayDTO {
    id: number;
    type: "SOUILLE";
    poste_nom: string;
    so_pk: string | null;
    so_pk_corrected: string | null;
    sous_sect: string;
    nature: null;
}

export default function useWaterways() {
    const [isFairwaysLoading, setIsFairwaysLoading] = useState(false);
    const [isDefilesLoading, setIsDefilesLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [fairways, setFairways] = useState<WaterwayQuotationsData[]>([]);
    const [defiles, setDefiles] = useState<WaterwayQuotationsData[]>([]);

    const { currentZoom } = useSelector((state: any) => ({
        currentZoom: state.map.zoom,
    }));
    const dispatch = useDispatch();
    const fetchFairways = useCallback(async () => {
        setIsFairwaysLoading(true);
        try {
            const response: FairwayDTO[] = await fetchSLF(`dragage/passes/quotations`);
            setFairways(
                response
                    .filter(({ pas_nom }) => pas_nom)
                    .map(
                        ({
                            gid,
                            ordre,
                            pas_nom,
                            pas_pk,
                            pas_pk_corrected,
                            x_min,
                            x_max,
                            y_min,
                            y_max,
                            quotation_model,
                            quotation_model_corrected,
                            quotation_1d,
                            quotation_30d,
                            quotation_100d,
                            quotation_target,
                            quotation_dredging,
                            zh_ref,
                            zone_num,
                        }) =>
                            ({
                                name: pas_nom,
                                ordre,
                                type: WaterwayType.FAIRWAY,
                                key: gid,
                                bounds: [x_min, y_min, x_max, y_max],
                                quotationModel: quotation_model,
                                quotationCorrected: quotation_model_corrected,
                                quotation1d: quotation_1d,
                                quotation30d: quotation_30d,
                                quotation100d: quotation_100d,
                                targetQuotation: quotation_target,
                                dredgingQuotation: quotation_dredging,
                                pk: pas_pk,
                                pkCorrected: pas_pk_corrected,
                                zhRef: zh_ref,
                                zone: zone_num,
                            } as WaterwayQuotationsData),
                    )
                    .sort((a, b) => a.ordre - b.ordre),
            );
        } catch (err) {
            console.error(err);
        } finally {
            setIsFairwaysLoading(false);
        }
    }, []);
    const fetchDefiles = useCallback(async () => {
        setIsDefilesLoading(true);
        try {
            const response: DefileDTO[] = await fetchSLF(`dragage/souilles/quotations`);
            setDefiles(
                response
                    .filter(({ poste_nom }) => poste_nom)
                    .map(
                        ({
                            id,
                            poste_nom,
                            so_pk,
                            so_pk_corrected,
                            ordre,
                            x_min,
                            x_max,
                            y_min,
                            y_max,
                            quotation_model,
                            quotation_model_corrected,
                            quotation_1d,
                            quotation_30d,
                            quotation_100d,
                            quotation_target,
                            quotation_dredging,
                            zh_ref,
                        }) =>
                            ({
                                name: poste_nom,
                                ordre,
                                type: WaterwayType.DEFILE,
                                key: id,
                                bounds: [x_min, y_min, x_max, y_max],
                                quotationModel: quotation_model,
                                quotationCorrected: quotation_model_corrected,
                                quotation1d: quotation_1d,
                                quotation30d: quotation_30d,
                                quotation100d: quotation_100d,
                                targetQuotation: quotation_target,
                                dredgingQuotation: quotation_dredging,
                                pk: so_pk,
                                pkCorrected: so_pk_corrected,
                                zhRef: zh_ref,
                            } as WaterwayQuotationsData),
                    )
                    .sort((a, b) => a.ordre - b.ordre),
            );
        } catch (err) {
            console.error(err);
        } finally {
            setIsDefilesLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchDefiles();
        fetchFairways();
    }, [fetchDefiles, fetchFairways]);

    const updateWaterways = async (type: WaterwayType, newWaterways: WaterwayQuotationsData[]) => {
        try {
            setIsUpdateLoading(true);
            const dataset = newWaterways.map(
                ({ ordre, quotationCorrected, pkCorrected, targetQuotation, dredgingQuotation }) => ({
                    ordre,
                    quotation: String(quotationCorrected),
                    pk: pkCorrected,
                    quotation_target: String(targetQuotation),
                    quotation_dredging: String(dredgingQuotation),
                }),
            );
            await fetchSLF(`dragage/passes_souilles/quotations_pk`, {
                headers: {
                    "qwc2-token": process.env.REACT_APP_QWC2_TOKEN || "",
                },
                method: FetchMethods.POST,
                content: {
                    layer: type.toLowerCase(),
                    dataset,
                },
            });
            if (type === WaterwayType.FAIRWAY) {
                await fetchFairways();
            } else {
                await fetchDefiles();
            }
        } catch (err) {
            throw new Error("Update failed");
        } finally {
            setIsUpdateLoading(false);

            dispatch(changeZoomLevel(currentZoom - 1));
        }
    };

    const isLoading = isFairwaysLoading || isDefilesLoading || isUpdateLoading;

    return { fairways, defiles, isLoading, updateWaterways };
}
