import React, { useState } from "react";
import { BottomPanel } from "../../../Shared/components/BottomPanel/BottomPanel";
import { ELegendType, Legends } from "../../../Shared/components/Legends/Legends";
import { Timeline } from "../../../Shared/components/Timeline/Timeline";
import ColorStatus, { Color } from "../../../Shared/components/ColorStatus/ColorStatus";
import { Tooltip } from "antd";

enum ThresholdCommissionTabKeys {
    LEGENDS = "legends",
}

const ThresholdCommissionLegendGroups = {
    "Indicateur niveau d'engraissement": [
        {
            type: ELegendType.NODE,
            node: (
                <Tooltip title="Cote du mois supérieure à celle d'exploitation">
                    <ColorStatus color={Color.GREEN} /> Non atteinte
                </Tooltip>
            ),
        },
        {
            type: ELegendType.NODE,
            node: (
                <Tooltip title="Cote presque atteinte (différence < 0.2m avec celle d'exploitation)">
                    <ColorStatus color={Color.ORANGE} /> Alerte
                </Tooltip>
            ),
        },
        {
            type: ELegendType.NODE,
            node: (
                <Tooltip title="Critique : Cote du mois inférieure à celle d'exploitation">
                    <ColorStatus color={Color.RED} /> Critique
                </Tooltip>
            ),
        },
    ],
};

export const ThresholdCommissionBottomPanelPlugin = () => {
    const [isBottomPanelOpen, setIsBottomPanelOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(ThresholdCommissionTabKeys.LEGENDS);
    const handleClickTab = (tabId: string) => {
        setIsBottomPanelOpen && setIsBottomPanelOpen(true);
        setSelectedTab(tabId as ThresholdCommissionTabKeys);
    };
    const isTabOpen = (tabId: ThresholdCommissionTabKeys) => {
        return selectedTab === tabId && isBottomPanelOpen;
    };

    return (
        <>
            <Timeline />
            <BottomPanel
                items={[
                    {
                        label: "Légendes",
                        children: (
                            <Legends
                                isOpen={isTabOpen(ThresholdCommissionTabKeys.LEGENDS)}
                                legendGroups={ThresholdCommissionLegendGroups}
                            />
                        ),
                        key: ThresholdCommissionTabKeys.LEGENDS,
                    },
                ]}
                isOpen={isBottomPanelOpen}
                onClose={() => setIsBottomPanelOpen(false)}
                onOpen={handleClickTab}
            />
        </>
    );
};
