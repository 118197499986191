import { IQWC2Layer } from "../Interfaces/IQWC2Layer";

export const findLayer = (layerName: string, layer: IQWC2Layer): IQWC2Layer | null => {
    if (layer?.name === layerName) {
        return layer;
    } else if (layer?.sublayers) {
        return layer.sublayers.reduce((acc: IQWC2Layer | null, cur) => acc || findLayer(layerName, cur), null);
    }

    return null;
};

export const getAllLayersNames = (layer: IQWC2Layer, filterProperty?: keyof IQWC2Layer): string[] => {
    if (!layer.sublayers) {
        if (filterProperty) {
            return layer[filterProperty] ? [layer.name] : [];
        }

        return [layer.name];
    }

    return layer.sublayers.reduce(
        (acc: string[], subLayer: IQWC2Layer) => acc.concat(getAllLayersNames(subLayer, filterProperty)),
        [],
    );
};

export const getLayerPath = (layerName: string, layer: IQWC2Layer, path: number[]): number[] | null => {
    if (layer?.name === layerName) {
        return path;
    } else if (layer?.sublayers) {
        return layer.sublayers.reduce(
            (acc: number[] | null, cur, index) => (acc !== null ? acc : getLayerPath(layerName, cur, [...path, index])),
            null,
        );
    }

    return null;
};

export const getLayerFromPath = (path: number[], layer: IQWC2Layer): IQWC2Layer | null => {
    if (!layer.sublayers) {
        return null;
    }
    const currentIndex = path.shift();
    if (currentIndex !== undefined && path.length === 0) {
        return layer.sublayers[currentIndex];
    }

    return layer.sublayers.reduce((acc: IQWC2Layer | null, cur) => acc || getLayerFromPath(path, cur), null);
};
