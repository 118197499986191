import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Line, LineConfig } from "@ant-design/plots";
import { EnvironmentOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import classNames from "classnames";
import { useChartData } from "./useChartData";
import { ButtonSwitch } from "../../../../Shared/components/ButtonSwitch/ButtonSwitch";
import { IQWC2Layer } from "../../../../Shared/Interfaces/IQWC2Layer";
import { findLayer } from "../../../../Shared/utils/layer";
import "./ECCharts.less";
import { useMarkers } from "../../../../Shared/useMarkers";

const MemoizedLine = ({ data, ...props }: LineConfig) => useMemo(() => <Line data={data} {...props} />, [data]);

interface ECChartTab {
    label: string;
    layers: string[];
    value: string[];
}

const tabs: ECChartTab[] = [
    // { label: "Bathymétrie", layers: ["bathymetrie"], value: [] },
    // { label: "Direction et intensité des courants", layers: ["courant_surface", "courant_fond"], value: [] },
    // { label: "Intensité des courants", layers: ["courant_polygone_surface", "courant_polygone_fond"], value: [] },
    { label: "Salinité", layers: ["salinite_surface", "salinite_fond"], value: [] },
    { label: "Taux d'oxygène dissous", layers: ["o2_dissous_surface", "o2_dissous_fond"], value: [] },
    { label: "Température", layers: ["temperature_surface", "temperature_fond"], value: [] },
    // { label: "Turbidité", layers: ["turbidite_surface", "turbidite_fond"], value: [] },
    { label: "Charge ammoniacale", layers: ["charge_ammoniacale_surface", "charge_ammoniacale_fond"], value: [] },
    { label: "Charge organique", layers: ["charge_organique_surface", "charge_organique_fond"], value: [] },
    // { label: "Bouchon vaseux", layers: ["bouchon_vaseux"], value: [] },
];

const monthsFr = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];

const ONE_DAY_MS = 1000 * 60 * 60 * 24;

interface IECCharts {
    isOpen: boolean;
}

export const ECCharts = ({ isOpen }: IECCharts) => {
    const { layers } = useSelector((state: any) => ({
        layers: state.layers.flat,
    }));
    const [currentLayerIndex, setCurrentLayerIndex] = useState(0);
    const [enabledTabs, setEnabledTabs] = useState<ECChartTab[]>(tabs);

    useEffect(() => {
        const WMSLayer = layers.find(({ type }: IQWC2Layer) => type === "wms");
        if (!WMSLayer) {
            return;
        }
        const newActiveTabs = tabs
            .map(({ layers, label }) => {
                const value = layers.reduce((acc: string[], layerName: string) => {
                    const currentLayer = findLayer(layerName, WMSLayer);
                    if (currentLayer && currentLayer.visibility) {
                        acc.push(layerName);
                    }

                    return acc;
                }, []);

                return {
                    layers,
                    value,
                    label,
                };
            })
            .filter((tab) => tab.value.length > 0);
        if (JSON.stringify(newActiveTabs) !== JSON.stringify(enabledTabs)) {
            setEnabledTabs(newActiveTabs);
            if (currentLayerIndex >= newActiveTabs.length) {
                setCurrentLayerIndex(newActiveTabs.length === 0 ? 0 : newActiveTabs.length - 1);
            }
        }
    }, [layers]);

    const { markers, addMarker, deleteMarker, isSettingMarker, stopMarkerSelection } = useMarkers({
        showMarkers: isOpen,
        maxMarkers: 1,
        showMarkersTitle: false,
        continiousAdd: true,
    });

    const coords = markers[0] && markers[0].coords;

    const { chartData, isLoading } = useChartData({
        layer: enabledTabs[currentLayerIndex],
        coords,
        isOpen,
    });

    const getColor = ({ layer }: any) => (layer.includes("fond") ? "#60A9FF" : "#1DE9B6");

    const getAnnotations = (): any[] => {
        const today = new Date().setHours(0, 0, 0, 0);
        const forecast10Days = today + ONE_DAY_MS * 10;
        return [
            {
                type: "region",
                start: [today.toString(), "min"],
                end: ({ values }: any) => {
                    if (values.includes(forecast10Days.toString())) {
                        return [forecast10Days.toString(), "max"];
                    }
                    return [values[values.length - 1], "max"];
                },
                style: {
                    fill: "#FFD600",
                    fillOpacity: 0.2,
                },
            },
            {
                type: "region",
                start: [forecast10Days.toString(), "min"],
                end: ({ values }: any) => {
                    return [values[values.length - 1], "max"];
                },
                style: {
                    fill: "#FF9900",
                    fillOpacity: 0.2,
                },
            },
        ];
    };

    const getCustomTooltip = (timestamp: string, data: { name: string; value: string; color: string }[]) => {
        const date = new Date(parseInt(timestamp));
        const parsedTimestamp = parseInt(timestamp);
        const today = new Date().setHours(0, 0, 0, 0);
        const forecast10Days = today + ONE_DAY_MS * 10;

        let additionalInfo = null;

        if (today < parsedTimestamp && forecast10Days >= parsedTimestamp) {
            additionalInfo = (
                <div className="tooltip-info forecast">
                    <div className="line-color" style={{ backgroundColor: "#FFD600" }} />
                    <i>Prévisions à 10 jours</i>
                </div>
            );
        } else if (today < parsedTimestamp && forecast10Days < parsedTimestamp) {
            additionalInfo = (
                <div className="tooltip-info forecast">
                    <div className="line-color" style={{ backgroundColor: "#FF9900" }} />
                    <i>Tendance</i>
                </div>
            );
        }

        return (
            <div className="chart-tooltip">
                <h1>{`${date.toLocaleDateString("fr")} - ${date.getHours()}h`}</h1>
                {additionalInfo}
                {data.map(({ name, value, color }) => (
                    <div key={name} className="tooltip-info">
                        <div className="line-color round" style={{ backgroundColor: color }} />
                        <span>{name} : </span>
                        <b>{value}</b>
                    </div>
                ))}
            </div>
        );
    };

    const noTabEnabledOrMarker = enabledTabs.length === 0 || markers.length === 0;
    const canDisplayChart = markers.length === 1 && enabledTabs.length > 0;

    return (
        <div className={classNames("chart-wrapper", { hidden: !isOpen })}>
            {noTabEnabledOrMarker && (
                <div className="chart-no-layer-selected">
                    <div>
                        Sélectionner une ou plusieurs données dans la rubrique{" "}
                        <b>
                            Données physico-chimiques (Salinité, Taux d&apos;oxygène dissous, Température, Charge
                            ammonicale et Charge organique)
                        </b>{" "}
                        dans le menu des couches pour commencer, puis ajouter un repère sur la carte.
                    </div>
                    {markers.length === 1 ? (
                        <Button
                            className="add-marker-button"
                            type="primary"
                            onClick={() => {
                                stopMarkerSelection();
                                deleteMarker(0);
                            }}
                        >
                            Supprimer le repère
                        </Button>
                    ) : (
                        <Button
                            className={classNames("add-marker-button", { active: isSettingMarker })}
                            type="primary"
                            onClick={addMarker}
                        >
                            <EnvironmentOutlined style={{ fontSize: 18 }} />
                            Ajouter un repère
                        </Button>
                    )}
                </div>
            )}
            {canDisplayChart && (
                <>
                    <div className="header">
                        <ButtonSwitch
                            buttons={enabledTabs}
                            value={currentLayerIndex}
                            onChange={(newValue) => setCurrentLayerIndex(newValue)}
                            className="layer-switch"
                            buttonClassName="layer-button"
                        />
                        <Button
                            className="add-marker-button"
                            type="primary"
                            onClick={() => {
                                stopMarkerSelection();
                                deleteMarker(0);
                            }}
                        >
                            Supprimer le repère
                        </Button>
                    </div>
                    <div className="chart-inner-wrapper">
                        {isLoading ? (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
                        ) : chartData ? (
                            <MemoizedLine
                                data={chartData}
                                xField="timestamp"
                                yField="value"
                                seriesField="layer"
                                color={getColor}
                                tooltip={{
                                    customContent: getCustomTooltip,
                                }}
                                xAxis={{
                                    label: {
                                        formatter(timestamp) {
                                            const monthNumber = new Date(parseInt(timestamp)).getMonth();
                                            return monthsFr[monthNumber];
                                        },
                                    },
                                    tickMethod: ({ values }) => {
                                        return (values as string[]).reduce((acc: number[], cur, index, array) => {
                                            const prev = array[index - 1];
                                            if (!prev) {
                                                return [index];
                                            }
                                            const curMonth = new Date(parseInt(cur)).getMonth();
                                            const prevMonth = new Date(parseInt(prev)).getMonth();
                                            if (curMonth !== prevMonth) {
                                                acc.push(index);
                                            }

                                            return acc;
                                        }, []);
                                    },
                                }}
                                annotations={getAnnotations()}
                            />
                        ) : null}
                    </div>
                </>
            )}
        </div>
    );
};
