import React, { useContext } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import "./App.less";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import Home from "./Home";
import UserContext, { IUserContext, UserProvider } from "./UserContext";
import moduleList from "./moduleList";
import { Page404 } from "./Page404";
import { ErrorPage } from "./ErrorPage";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/fr_FR";
import { QWC2ThemeProvider } from "./QWC2ThemeContext";

interface IGuardedRoute {
    path: string;
    children: any;
}

const GuardedRoute = ({ path: modulePath, children }: IGuardedRoute) => {
    const { user } = useContext(UserContext) as IUserContext;
    const hasAccess = Boolean(user?.modules.find(({ path }) => path.split("/")[1] === modulePath));
    return hasAccess ? children : <Navigate to="/" />;
};

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
            errorElement: <ErrorPage />,
        },
        {
            path: "/",
            children: moduleList
                .filter(({ component }) => Boolean(component))
                .map(({ path: modulePath, component, permissions, children }) => {
                    const path = modulePath.split("/")[1];

                    return {
                        path,
                        element: !permissions ? component : <GuardedRoute path={path}>{component}</GuardedRoute>,
                        errorElement: <ErrorPage />,
                        children,
                    };
                }),
        },
        {
            path: "*",
            element: <Page404 />,
        },
    ]);

    return (
        <ReactKeycloakProvider authClient={keycloak}>
            <ConfigProvider locale={locale}>
                <div className="giros-360-app">
                    <UserProvider>
                        <QWC2ThemeProvider>
                            <RouterProvider router={router} />
                        </QWC2ThemeProvider>
                    </UserProvider>
                </div>
            </ConfigProvider>
        </ReactKeycloakProvider>
    );
}

export default App;
