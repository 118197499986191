import React, { useEffect, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { AlertSettingModale } from "./AlertSettingsModale/AlertSettingsModale";
import { WaterwayQuotationsData, WaterwayType } from "../../Shared/useWaterways";
import TopBarButton from "../../Shared/components/ModuleTopBar/TopBarButton/TopBarButton";
import { IMapModal } from "../../Shared/components/MapModal/MapModal";

interface IAlertSettingsButton {
    fairways: WaterwayQuotationsData[];
    defiles: WaterwayQuotationsData[];
    isWaterwaysLoading: boolean;
    updateWaterways: (type: WaterwayType, newWaterways: WaterwayQuotationsData[]) => Promise<void>;
    displayModal: ((newValue: IMapModal | null) => void) | null;
}

export const AlertSettingsButton = ({
    fairways,
    defiles,
    isWaterwaysLoading,
    updateWaterways,
    displayModal,
}: IAlertSettingsButton) => {
    const [showAlertSettings, setShowAlertSettings] = useState(false);

    useEffect(() => {
        if (!displayModal) {
            return;
        }
        showAlertSettings
            ? displayModal({
                  title: "Paramétrage des alertes",
                  content: (
                      <AlertSettingModale fairways={fairways} defiles={defiles} updateWaterways={updateWaterways} />
                  ),
                  onClose: closeAlertSettings,
                  className: "alert-settings-modal-wrapper",
              })
            : displayModal(null);
    }, [showAlertSettings, fairways, defiles]);

    const closeAlertSettings = () => {
        setShowAlertSettings(false);
    };

    const openAlertSettings = () => {
        setShowAlertSettings(true);
    };

    const toggleAlertSettings = () => {
        showAlertSettings ? closeAlertSettings() : openAlertSettings();
    };

    return (
        <TopBarButton
            tooltip="Paramétrage des alertes"
            className="alert-settings-button"
            active={showAlertSettings}
            onClick={toggleAlertSettings}
            disabled={isWaterwaysLoading}
        >
            <SettingOutlined />
        </TopBarButton>
    );
};
