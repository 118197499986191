import React from "react";

export const WaveIcon = () => {
    return (
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.71429 4C4.19729 4 6.05929 1 6.05929 1C6.05929 1 7.92129 4 10.4043 4C12.8863 4 15.3693 1 15.3693 1C15.3693 1 17.8523 4 19.7143 4M1.71429 11C4.19729 11 6.05929 8 6.05929 8C6.05929 8 7.92129 11 10.4043 11C12.8863 11 15.3693 8 15.3693 8C15.3693 8 17.8523 11 19.7143 11"
                stroke="#F9F9FC"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
