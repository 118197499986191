import axios from "axios";
import { addSearchResults } from "qwc2/actions/search";
import { nominatimSearch } from "./nominatim";
import { bookmarksSearch } from "./bookmarks";

export const SearchProviders: {
    [key: string]: {
        label: string;
        onSearch: (
            text: string,
            requestId: string,
            searchOptions: any,
            dispatch: (action: any) => void,
            config?: any,
        ) => void;
        requiresLayer?: any;
    };
} = {
    nominatim: {
        label: "OpenStreetMap",
        onSearch: nominatimSearch,
    },
    bookmarks: {
        label: "Géosignets",
        onSearch: bookmarksSearch,
    },
};

async function parametrizedSearch(
    text: string,
    requestId: string,
    searchOptions: any,
    dispatch: (action: any) => void,
    cfg: any,
) {
    try {
        const response = await axios.get("?param=" + cfg.param + "&searchtext=" + encodeURIComponent(text));
        dispatch(addSearchResults({ data: response.data, provider: cfg.key, reqId: requestId }));
    } catch (err) {
        console.error(err);
        dispatch(addSearchResults({ data: [], provider: cfg.key, reqId: requestId }));
    }
}

export function searchProviderFactory(cfg: { key: string; label: string; layerName: string }) {
    if (!cfg.key) {
        return null;
    }
    if (cfg.key in SearchProviders) {
        return {
            label: cfg.label || cfg.key,
            onSearch: SearchProviders[cfg.key].onSearch,
            requiresLayer: cfg.layerName || SearchProviders[cfg.key].requiresLayer,
        };
    }
    return {
        label: cfg.label || cfg.key,
        onSearch: (text: string, requestId: string, searchOptions: any, dispatch: (action: any) => void) =>
            parametrizedSearch(text, requestId, searchOptions, dispatch, cfg),
        requiresLayer: cfg.layerName,
    };
}
