import React, { useContext } from "react";
import { always } from "ol/events/condition";
import { PlusSquareOutlined } from "@ant-design/icons";
import DragPan from "ol/interaction/DragPan";
import DragZoom from "ol/interaction/DragZoom";
import MapButton from "../MapButton";
import { OLContext } from "qwc2/contexts/OLContext";
import useZoomToExtent from "../../../useZoomToExtent";
import { IBbox } from "../../../../interfaces/IBbox";

export default function ZoneZoomButton() {
    const zoomToExtent = useZoomToExtent();

    const { olMap }: { olMap: any } = useContext(OLContext);

    const handleClick = () => {
        // deactivate dragpan on map mouse drag
        olMap.getInteractions().forEach((interaction: any) => {
            if (interaction instanceof DragPan) {
                interaction.setActive(false);
            }
        });
        const zoomBbox: IBbox = { bounds: [0, 0, 0, 0] };
        // create a dragzoom that will trigger on map mouse drag
        const dragZoomInteraction = new DragZoom({ condition: always });
        olMap.addInteraction(dragZoomInteraction);
        dragZoomInteraction.once("boxstart", ({ coordinate: [startX, startY] }) => {
            zoomBbox.bounds = [startX, startY, 0, 0];
        });
        dragZoomInteraction.once("boxend", ({ coordinate: [endX, endY] }) => {
            olMap.removeInteraction(dragZoomInteraction);
            olMap.getInteractions().forEach((interaction: any) => {
                if (interaction instanceof DragPan) {
                    interaction.setActive(true);
                }
            });
            const [startX, startY] = zoomBbox.bounds;
            zoomBbox.bounds = [startX, startY, endX, endY];
            zoomToExtent(zoomBbox);
        });
    };

    return (
        <MapButton onClick={handleClick} tooltip="Zoom sur zone">
            <PlusSquareOutlined />
        </MapButton>
    );
}
