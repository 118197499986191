import React from "react";
import { Descriptions } from "antd";
import "./FeatureInfo.less";

export interface IFeatureInfo {
    feature?: string;
    properties?: any;
}

export const FeatureInfo = ({ feature, properties }: IFeatureInfo) => {
    return (
        <Descriptions column={2} className="feature-info" bordered size="small">
            <Descriptions.Item label="Feature ID">{feature}</Descriptions.Item>
            {Object.keys(properties).map((propertyKey) => (
                <Descriptions.Item key={propertyKey} label={propertyKey}>
                    {properties[propertyKey]}
                </Descriptions.Item>
            ))}
        </Descriptions>
    );
};
