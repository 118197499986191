/**
 * Copyright 2015 GeoSolutions Sas
 * Copyright 2016-2021 Sourcepole AG
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import ReducerIndex from '../reducers/index';
import localeReducer from '../reducers/locale';
ReducerIndex.register("locale", localeReducer);

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export function loadLocale(defaultLangData, defaultLang = "") {
    return dispatch => {
        dispatch({
            type: CHANGE_LOCALE,
            locale: defaultLangData.locale,
            messages: defaultLangData.messages
        });
    }
}
