import React from "react";

export default function CompassIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 0C4.9247 0 0 4.9247 0 11C0 17.0754 4.9247 22.0001 11 22.0001C17.0754 22.0001 22.0001 17.0754 22.0001 11C22.0001 4.9247 17.0754 0 11 0ZM11 19.8001C6.1478 19.8001 2.20001 15.8524 2.20001 11C2.20001 6.14771 6.14771 2.20001 11 2.20001C15.8524 2.20001 19.8001 6.14771 19.8001 11C19.8001 15.8524 15.8524 19.8001 11 19.8001Z"
                fill="white"
            />
            <path
                d="M5.5 16.5L13.4445 13.4445L16.5 5.5L8.55557 8.55557L5.5 16.5ZM11 9.57001C11.7898 9.57001 12.4301 10.2103 12.4301 10.9999C12.4301 11.7896 11.7898 12.4299 11 12.4299C10.2102 12.4299 9.56993 11.7896 9.56993 10.9999C9.56993 10.2103 10.2102 9.57001 11 9.57001Z"
                fill="white"
            />
        </svg>
    );
}
