import { useEffect, useMemo, useState } from "react";
import { getTerminalsGraph } from "./terminalsGraph";
import useCurrentModule from "../Shared/useCurrentModule";
import { INavigabilityShipSelectForm } from "./NavigabilityShipSelect/NavigabilityShipSelect";
import { fetchSLF } from "../Shared/utils/fetch";
import moment from "moment-timezone";

const getShipSpeedInKmH = (speed: number) => Math.round(speed * 1.852);

export interface IFairwaysPassingTimes {
    caracteristiques_navire: {
        vitesse: number;
        coef_bloc: number;
        longueur: number;
        largeur: number;
        tirant_eau: number;
    };
    heures_de_passage: Array<{
        nom_passe: string;
        date: string;
        timestamp: string;
    }>;
}

export interface ITerminalsArrivalTimes {
    caracteristiques_navire: {
        vitesse: number;
        coef_bloc: number;
        longueur: number;
        largeur: number;
        tirant_eau: number;
    };
    heures_de_passage: Array<{
        num_poste_a_quai: number;
        date: string;
        timestamp: string;
    }>;
}

const START_OF_ESTUARY = 1000;

export const useEstimatedPassingTime = (userSelection: INavigabilityShipSelectForm | null) => {
    const [SLFTimestamps, setSLFTimestamps] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentModule] = useCurrentModule();
    const projectId = currentModule?.defaultProjectId;

    useEffect(() => {
        const fetchSLFTimestamps = async () => {
            setIsLoading(true);
            try {
                const { time_steps } = await fetchSLF(`slf/time_steps/${projectId}`);
                setSLFTimestamps(time_steps);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };
        if (projectId !== undefined) {
            fetchSLFTimestamps();
        }
    }, [projectId]);

    return useMemo(() => {
        if (!userSelection || isLoading || SLFTimestamps.length === 0) {
            return {
                fairwaysWithPassingTime: [],
                terminalsWithArrivalTime: [],
            };
        }
        const getSLFTimestamp = (date: string): string | undefined => {
            const roundedHour = Math.round(new Date(date).getTime() / 1000 / 60 / 60) * 1000 * 60 * 60;
            const slfFormat = moment.tz(roundedHour, "Europe/Paris").utc().format("YYYY-MM-DDTHH:mm:SS");
            return SLFTimestamps.find((date) => date === slfFormat);
        };
        const { selectedMouvement, shipSpeed, blocCoef } = userSelection;
        const shipSpeedToKmH = getShipSpeedInKmH(shipSpeed);

        const mouvementsWithFairwaysItinery = [selectedMouvement].map((mouvement) => {
            const { from, to, direction } = mouvement;
            const fairways = getTerminalsGraph().getFairwayItinery(from, to, direction);
            return {
                ...mouvement,
                fairways,
            };
        });

        const mouvementsWithFairwaysItineryAndEstimatedTime = mouvementsWithFairwaysItinery
            .map(({ arrivalTimestamp, departureTimestamp, fairways, tirant_eau, longueur, largeur, type }) => {
                if (!fairways || (!departureTimestamp && !arrivalTimestamp)) {
                    return;
                }

                if (departureTimestamp) {
                    let currentTimestamp = new Date(departureTimestamp).getTime();
                    const fairwaysWithTs = [];
                    for (let i = 0; i < fairways.length; i++) {
                        const currentFairway: {
                            fairway: string;
                            length: number;
                        } = fairways[i];
                        fairwaysWithTs.push({
                            nom_passe: currentFairway.fairway,
                            date: new Date(currentTimestamp).toISOString(),
                        });
                        const deltaInMs = (currentFairway.length / shipSpeedToKmH) * 60 * 60 * 1000;
                        currentTimestamp += deltaInMs;
                    }

                    return {
                        caracteristiques_navire: {
                            vitesse: shipSpeed,
                            coef_bloc: blocCoef,
                            longueur,
                            largeur,
                            tirant_eau,
                        },
                        heures_de_passage: fairwaysWithTs,
                    } as IFairwaysPassingTimes;
                } else if (arrivalTimestamp) {
                    let currentTimestamp = new Date(arrivalTimestamp).getTime();
                    const fairwaysWithTs = [];
                    for (let i = fairways.length - 1; i > -1; i--) {
                        const currentFairway = fairways[i];
                        fairwaysWithTs.push({
                            nom_passe: currentFairway.fairway,
                            date: new Date(currentTimestamp).toISOString(),
                        });
                        const deltaInMs = (currentFairway.length / shipSpeedToKmH) * 60 * 60 * 1000;
                        currentTimestamp -= deltaInMs;
                    }

                    return {
                        caracteristiques_navire: {
                            vitesse: shipSpeed,
                            coef_bloc: blocCoef,
                            longueur,
                            largeur,
                            tirant_eau,
                        },
                        heures_de_passage: fairwaysWithTs,
                    } as IFairwaysPassingTimes;
                }
            })
            .filter((fairwaysWithPassingTimes) => !!fairwaysWithPassingTimes) as IFairwaysPassingTimes[];

        const fairwaysWithPassingTime = mouvementsWithFairwaysItineryAndEstimatedTime.map(
            ({ caracteristiques_navire, heures_de_passage }) => {
                return {
                    caracteristiques_navire,
                    heures_de_passage: heures_de_passage
                        .sort((fairwayA, fairwayB) => {
                            const ts1 = new Date(fairwayA.date as string).getTime();
                            const ts2 = new Date(fairwayB.date as string).getTime();
                            if (ts1 > ts2) {
                                return 1;
                            }
                            return -1;
                        })
                        .map(({ date, nom_passe }) => {
                            return { nom_passe, date: getSLFTimestamp(date), timestamp: date };
                        })
                        .filter(({ nom_passe, date }) => !nom_passe.includes("NO_NAME") && date),
                };
            },
        ) as IFairwaysPassingTimes[];

        const terminalsWithArrivalTime = [selectedMouvement].map(
            ({ from, to, arrivalTimestamp, departureTimestamp, tirant_eau, longueur, largeur }) => {
                const terminalsWithTs = [];
                if (from && departureTimestamp && from !== START_OF_ESTUARY) {
                    terminalsWithTs.push({
                        num_poste_a_quai: from,
                        date: getSLFTimestamp(departureTimestamp),
                        timestamp: departureTimestamp,
                    });
                }
                if (to && arrivalTimestamp && to !== START_OF_ESTUARY) {
                    terminalsWithTs.push({
                        num_poste_a_quai: to,
                        date: getSLFTimestamp(arrivalTimestamp),
                        timestamp: arrivalTimestamp,
                    });
                }
                return {
                    caracteristiques_navire: {
                        vitesse: shipSpeed,
                        coef_bloc: blocCoef,
                        longueur,
                        largeur,
                        tirant_eau,
                    },
                    heures_de_passage: terminalsWithTs,
                } as ITerminalsArrivalTimes;
            },
        );

        return {
            fairwaysWithPassingTime,
            terminalsWithArrivalTime,
        };
    }, [userSelection, isLoading, SLFTimestamps]);
};
