import React from "react";
import TextArea from "antd/lib/input/TextArea";
import { Input, Form, Button } from "antd";
import { EProgrammedState } from "../../../../../Dredging/AnnualStrategy/types";
import { IPlanningTimeRange } from "../../types";

export default function CreateTimeRangeCommercialCommitment({
    onCreate,
    timerangeSelect,
}: {
    onCreate: (timerange: Partial<IPlanningTimeRange>) => void;
    timerangeSelect: React.ReactNode;
}) {
    return (
        <Form
            className="create-timerange"
            name="commercial-commitment-form"
            layout="vertical"
            onFinish={({ name, comments }) => {
                name &&
                    onCreate({
                        activity: EProgrammedState.COMMERCIAL_COMMITMENTS,
                        text: name,
                        tooltip: comments,
                    });
            }}
        >
            {timerangeSelect}
            <Form.Item name="name" label="libellé" required>
                <Input />
            </Form.Item>
            <Form.Item name="comments" label="commentaires">
                <TextArea />
            </Form.Item>
            <Button type="default" htmlType="submit">
                Créer
            </Button>
        </Form>
    );
}
