import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { IPlanningLegend, IPlanningTimeRange } from "../types";
import { CloseOutlined } from "@ant-design/icons";
import "./PlanningItem.less";

interface IPlanningItemProps extends IPlanningTimeRange {
    size: number;
    position: number;
    legend: Array<IPlanningLegend>;
    blockWidth: number;
    editable?: boolean;
    onResize?: (itemId: string, keyToModify: "from" | "to", blocks: number) => void;
    onDelete?: (itemId: string) => void;
    onClick?: (itemId: string) => void;
    setIsResizing: (isResizing: boolean) => void;
}

export const PlanningItem = ({
    id,
    activity,
    text,
    tooltip,
    size,
    position,
    legend,
    blockWidth,
    editable,
    onResize,
    onDelete,
    onClick,
    setIsResizing,
}: IPlanningItemProps) => {
    const planningItemRef = useRef<HTMLDivElement | null>(null);
    const [showResizables, setShowResizables] = useState(false);
    const [resize, setResize] = useState<null | { direction: "from" | "to"; blocks: number }>(null);
    const [bounds, setBounds] = useState<null | { left: number; right: number }>(null);

    useEffect(() => {
        if (!planningItemRef.current) {
            return;
        }
        const { left, right } = planningItemRef.current.getBoundingClientRect();
        setBounds({ left, right });
    }, [position, size]);

    useEffect(() => {
        const handleResize = (event: MouseEvent) => {
            if (!bounds || !onResize || !resize) {
                return;
            }
            setIsResizing(true);
            const mouseXPos = event.clientX;
            const blocks =
                resize.direction === "from"
                    ? Math.floor((mouseXPos - bounds.left) / blockWidth)
                    : Math.floor((mouseXPos - bounds.right) / blockWidth);
            setResize(
                (_resize) =>
                    _resize && {
                        ..._resize,
                        blocks,
                    },
            );
        };
        const handleResizeStop = () => {
            if (!onResize || !resize) {
                return;
            }
            onResize(id, resize.direction, resize.blocks);
            setResize(null);
            setIsResizing(false);
        };
        if (resize) {
            document.addEventListener("mousemove", handleResize);
            document.addEventListener("mouseup", handleResizeStop);
        }

        return () => {
            document.removeEventListener("mousemove", handleResize);
            document.removeEventListener("mouseup", handleResizeStop);
        };
    }, [blockWidth, resize, id, onResize, setIsResizing, bounds]);

    const getColor = (activity: string) => {
        return legend.find(({ value }) => activity === value)?.color || "transparent";
    };

    const getBorderColor = (activity: string) => {
        return legend.find(({ value }) => activity === value)?.borderColor || "transparent";
    };

    const getWidth = () => {
        if (resize?.direction === "to") {
            return blockWidth * (size + resize.blocks > 1 ? size + resize.blocks : 1);
        } else if (resize?.direction === "from") {
            return blockWidth * (size - resize.blocks > 1 ? size - resize.blocks : 1);
        }

        return blockWidth * size;
    };

    const getLeft = () => {
        if (resize?.direction === "from") {
            return blockWidth * (position + resize.blocks);
        }

        return blockWidth * position;
    };

    return (
        <div
            ref={planningItemRef}
            className={classNames("planning-item", activity, { preview: Boolean(resize), editable })}
            style={{
                backgroundColor: getColor(activity),
                width: getWidth(),
                left: getLeft(),
                borderColor: getBorderColor(activity),
            }}
            onClick={() => editable && onClick && onClick(id)}
            onMouseEnter={() => editable && onResize && setShowResizables(true)}
            onMouseLeave={() => setShowResizables(false)}
        >
            {(showResizables || Boolean(resize)) && (
                <>
                    <span
                        className={classNames("resizable", "left", { resizing: Boolean(resize) })}
                        onMouseDown={() => setResize({ direction: "from", blocks: 0 })}
                    />
                    <span
                        className={classNames("resizable", "right", { resizing: Boolean(resize) })}
                        onMouseDown={() => setResize({ direction: "to", blocks: 0 })}
                    />
                </>
            )}
            {!resize && tooltip && <div className="planning-item-tooltip">{tooltip}</div>}
            {blockWidth * size >= 40 && <p>{text}</p>}
            {showResizables && (
                <button onClick={() => onDelete && onDelete(id)}>
                    <CloseOutlined />
                </button>
            )}
        </div>
    );
};
