import React from "react";

export const DistanceIcon = () => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.5 9V10.6667M4 9V11.5M9 9V11.5M14 9V11.5M11.5 9V10.6667M1.5 1.5V4.83333M1.5 3.16667H16.5M16.5 1.5V4.83333M15.5625 9C16.08 9 16.5 9.42667 16.5 9.9525V14.7142C16.5 15.24 16.08 15.6667 15.5625 15.6667H2.33333C2.11232 15.6667 1.90036 15.5789 1.74408 15.4226C1.5878 15.2663 1.5 15.0543 1.5 14.8333V9.9525C1.5 9.42667 1.92 9 2.4375 9H15.5625Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
