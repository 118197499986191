import React from "react";

import garonneImg from "./Home/images/IMG_garonne@2x.png";
import dragageImg from "./Home/images/IMG_draguage.png";
import caracteristiqueImg from "./Home/images/IMG_caracteristique@2x.png";
import navigabilitéImg from "./Home/images/Rectangle 797@2x.png";
import etudesImg from "./Home/images/IMG_etude.png";
import { CompassIcon, CraneDiggingIcon, CylinderIcon, WavesIcon } from "./Shared/Icons";
import { IModule } from "./Shared/Interfaces/IModule";
import { EPermission } from "./Shared/Enums/EPermissions";
import { EModuleID } from "./Shared/Enums/EModuleID";
import { Garonne2050Module } from "./Garonne2050";
import { DredgingModule } from "./Dredging";
import { EnvironmentalCharacteristicsModule } from "./EnvironmentalCharacteristics";
import { NavigabilityModule } from "./Navigability";
import { StudiesModule } from "./Studies";

export default [
    {
        title: "Caractéristiques du milieu",
        id: EModuleID.CARAC_MILIEU,
        description: "Bilan, prévisions et tendance des paramètres liés à la vie aquatique",
        img: caracteristiqueImg,
        icon: <WavesIcon />,
        path: "/caracteristiques-du-milieu",
        permissions: [EPermission.VIEW_CARAC_MILEU],
        component: <EnvironmentalCharacteristicsModule />,
        documentation: `${process.env.REACT_APP_DOCUMENTATION_URL}/modules/caracteristiques_des_milieux/index.html`,
        defaultProjectId: 552,
    },
    {
        title: "Dragage",
        id: EModuleID.DRAGGAGE_COMMISSION_SEUILS,
        description: "Aide à la décision de dragage",
        img: dragageImg,
        icon: <CraneDiggingIcon />,
        path: "/dragage",
        permissions: [EPermission.VIEW_DRAGAGE],
        component: <DredgingModule />,
        documentation: `${process.env.REACT_APP_DOCUMENTATION_URL}/modules/dragage/index.html`,
        defaultProjectId: 552,
    },
    {
        title: "Navigabilité",
        id: EModuleID.NAVIGABILITE,
        description: "Aide à la navigation, au suivi des amarres et à l’exploitation des terminaux",
        img: navigabilitéImg,
        icon: <CompassIcon />,
        path: "/navigabilite",
        permissions: [EPermission.VIEW_NAVIGABILITE],
        component: <NavigabilityModule />,
        documentation: `${process.env.REACT_APP_DOCUMENTATION_URL}/modules/navigabilite/index.html`,
        defaultProjectId: 552,
    },
    {
        title: "Études simplifiées",
        id: EModuleID.ETUDES,
        description: "Visualiser et explorer l'ensemble des informations relatives à l'environnement.",
        img: etudesImg,
        icon: <CylinderIcon />,
        path: "/etudes",
        permissions: [EPermission.VIEW_ETUDES],
        component: <StudiesModule />,
        documentation: `${process.env.REACT_APP_DOCUMENTATION_URL}/modules/etudes/index.html`,
        children: [{ path: ":studyId", element: null }],
        defaultProjectId: 552,
    },
    {
        title: "Garonne 2050",
        id: EModuleID.GARONNE_2050,
        description: `Améliorer la résilience face aux impacts du changement climatique`,
        img: garonneImg,
        icon: <CylinderIcon />,
        path: "/garonne-2050",
        permissions: [EPermission.VIEW_GARONNE_2050],
        component: <Garonne2050Module />,
        documentation: `${process.env.REACT_APP_DOCUMENTATION_URL}/modules/garonne_2050/index.html`,
        defaultProjectId: 608,
    },
] as IModule[];
