import { Alert, Button, Form, FormInstance, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EPermission } from "../../../Shared/Enums/EPermissions";
import { capitalize } from "../../../Shared/utils/string";
import UserContext, { IUserContext } from "../../../UserContext";
import { AlertSettingsEditableCell } from "../../ThresholdCommission/AlertSettingsModale/AlertSettingsEditableCell/AlertSettingsEditableCell";
import { WaterwayQuotationsData, WaterwayType } from "../../../Shared/useWaterways";
import "./AlertSettingsForm.less";

const numberFormat = new Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 });
const quotationRenderer = (quotation: number) => (quotation != null ? numberFormat.format(quotation) : "");

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface IAlertSettingsForm {
    type: WaterwayType;
    data: WaterwayQuotationsData[];
    onUpdate: (type: WaterwayType, newFairways: WaterwayQuotationsData[]) => Promise<void>;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
type CustomColumnType = ColumnTypes[number] & { editable?: boolean; dataIndex?: string; children?: CustomColumnType[] };

export const AlertSettingsForm = ({ type, data, onUpdate }: IAlertSettingsForm) => {
    const [form] = Form.useForm();
    const { user } = useContext(UserContext) as IUserContext;
    const [dataSource, setDataSource] = useState<WaterwayQuotationsData[]>([]);
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [touched, setTouched] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<"success" | "error" | null>(null);
    const currentDate = new Date().toLocaleDateString("fr");
    const currentMonth = new Date().toLocaleDateString("fr", { year: "numeric", month: "long" });
    const canEditAlerts = user?.permissions.includes(EPermission.EDIT_MODULE_DRAGAGE_SEUILS);

    useEffect(() => {
        form.resetFields();
        setErrors({});
        setTouched(false);
        setDataSource(data);
    }, [data, form]);

    const onEdit = (fieldName: string, data: WaterwayQuotationsData) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => data.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...data,
        });
        setDataSource(newData);
        setTouched(true);
        setErrors((oldErrors) => {
            const newErrors = { ...oldErrors };
            delete newErrors[fieldName];

            return newErrors;
        });
    };

    const onError = (fieldName: string) => {
        setErrors((oldErrors) => ({
            ...oldErrors,
            [fieldName]: true,
        }));
    };

    const addColumnHandlers = (cols: CustomColumnType[]): any => {
        return cols.map((col) => {
            if (!col.editable) {
                return {
                    ...col,
                    children: col.children ? addColumnHandlers(col.children) : undefined,
                };
            }
            return {
                ...col,
                children: col.children ? addColumnHandlers(col.children) : undefined,
                onCell: (record: Partial<WaterwayQuotationsData>) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    handleSave: onEdit,
                    handleError: onError,
                }),
            };
        });
    };

    const alertColumns: CustomColumnType[] = [
        {
            title: type === WaterwayType.FAIRWAY ? "Passe" : "Souille",
            dataIndex: "name",
        },
        {
            title: "Modèle",
            children: [
                {
                    title: "Cote",
                    dataIndex: "quotationModel",
                    render: quotationRenderer,
                },
                {
                    title: "PK",
                    dataIndex: "pk",
                },
            ],
        },
        {
            title: "Corrigé",
            children: [
                {
                    title: "Cote",
                    dataIndex: "quotationCorrected",
                    editable: canEditAlerts,
                    render: quotationRenderer,
                },
                {
                    title: "PK",
                    dataIndex: "pkCorrected",
                    editable: canEditAlerts,
                },
            ],
        },
        {
            title: "Cote d'exploitation",
            dataIndex: "targetQuotation",
            editable: canEditAlerts,
            render: quotationRenderer,
        },
        {
            title: "Cote de dragage",
            dataIndex: "dredgingQuotation",
            editable: canEditAlerts,
            render: quotationRenderer,
        },
    ];

    const onFinish = async () => {
        try {
            await onUpdate(type, dataSource);
            setSubmitStatus("success");
        } catch (err) {
            console.error(err);
            setSubmitStatus("error");
        }
    };

    useEffect(() => {
        if (submitStatus) {
            const timeoutId = setTimeout(() => setSubmitStatus(null), 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [submitStatus]);

    return (
        <Form onFinish={onFinish} form={form} className="alert-settings-modale">
            <div className="alert-settings-header">
                <div>
                    <h1>Mois : {capitalize(currentMonth)}</h1>
                    <span>{currentDate}</span>
                </div>
                {canEditAlerts && (
                    <Button
                        disabled={!touched || Object.keys(errors).length > 0}
                        className="save-button"
                        type="primary"
                        htmlType="submit"
                    >
                        Sauvegarder
                    </Button>
                )}
            </div>
            {submitStatus === "success" && (
                <Alert type="success" message="Le paramétrage a bien été sauvegardé." closable />
            )}
            {submitStatus === "error" && (
                <Alert type="error" message="Une erreur est survenue lors de la sauvegarde du paramétrage." closable />
            )}
            <EditableContext.Provider value={form}>
                <Table
                    components={{
                        body: {
                            cell: AlertSettingsEditableCell,
                        },
                    }}
                    pagination={false}
                    bordered
                    dataSource={dataSource}
                    columns={addColumnHandlers(alertColumns) as ColumnTypes}
                    size="small"
                />
            </EditableContext.Provider>
        </Form>
    );
};
