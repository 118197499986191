import React from "react";
import { Button, Tooltip } from "antd";
import classNames from "classnames";
import "./TopBarButton.less";

export interface ITopBarButton {
    children: React.ReactNode;
    onClick?: () => void;
    href?: string;
    disabled?: boolean;
    tooltip?: string;
    active?: boolean;
    className?: string;
}

export default function TopBarButton({ children, onClick, disabled, tooltip, active, href }: ITopBarButton) {
    const buttonProps = {
        disabled,
        className: classNames(["top-bar-button", { active }]),
        onClick: onClick,
        href,
    };
    const button = href ? (
        <Button {...buttonProps} type="text" target="_blank">
            {children}
        </Button>
    ) : (
        <button {...buttonProps}>{children}</button>
    );
    return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
}
