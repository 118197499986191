import React from "react";

export const Page404 = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 30 }}>
            <h1>Page 404</h1>
            <p>La page que vous cherchez est introuvable.</p>
            <a href="/">Retourner à la page d&apos;accueil</a>
        </div>
    );
};
