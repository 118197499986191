import moment from "moment";

export const DAY_TO_MS = 1000 * 60 * 60 * 24;

export const getDateFromTimeStamp = (timestamp?: string) => {
    return timestamp && timestamp.split("T")[0];
};

// VigieSip format : 10/06/2022 11:00:00
export const getDateFromVigieSipFormat = (vigieSipDate: string) => {
    const [date] = vigieSipDate.split(" ");
    const [day, month, year] = date.split("/");

    return new Date(
        Math.floor(new Date().setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day)) / DAY_TO_MS) * DAY_TO_MS,
    ).toISOString();
};

export const getDateAndTimeFromVigieSipFormat = (vigieSipDate: string) => {
    const [calendarDate, time] = vigieSipDate.split(" ");
    const [date, month, year] = calendarDate.split("/");
    const [hour, minute, second] = time.split(":");

    return moment()
        .set({
            year: parseInt(year),
            month: parseInt(month) - 1,
            date: parseInt(date),
            hour: parseInt(hour),
            minute: parseInt(minute),
            second: parseInt(second),
        })
        .tz("Europe/Paris")
        .toISOString();
};
