import React, { useContext } from "react";
import { Avatar, Button, Image } from "antd";
import classNames from "classnames";
import logoJumeaux from "../Shared/Logo/Logo_visibilite.png";
import logoPortWhite from "../Shared/Logo/Logo_port_white@2x.png";
import NaosLogo from "./images/LOGO_naos@2x.png";
import ModuleCard from "./ModuleCard";
import modules from "../moduleList";
import UserContext, { IUserContext } from "../UserContext";
import { LogInIcon, LogOutIcon } from "../Shared/Icons";
import { IModule } from "../Shared/Interfaces/IModule";
import { ExternalLink } from "../Shared/components/ExternalLink/ExternalLink";
import "./Home.less";

const Home: React.FC = () => {
    const { user, login, logout } = useContext(UserContext) as IUserContext;
    return (
        <section className="home">
            <div className="home-content">
                <header className={classNames({ user })}>
                    <nav className="auth-links">
                        {user ? (
                            <ul>
                                <li>
                                    <Button type="text" onClick={logout} data-test="log-out">
                                        Se déconnecter
                                        <LogOutIcon />
                                    </Button>
                                </li>
                            </ul>
                        ) : (
                            <ul>
                                <li>
                                    <Button type="text" onClick={login} data-test="log-in">
                                        Se connecter <LogInIcon />
                                    </Button>
                                    <div className="auth-link-description">
                                        Profiter de l&lsquo;ensemble des services
                                    </div>
                                </li>
                            </ul>
                        )}
                    </nav>
                    {user ? (
                        <div className="user">
                            <Avatar className="user-avatar" size="large">
                                {`${user.givenName.slice(0, 1)} ${user.familyName.slice(0, 1)}`}
                            </Avatar>
                            <div>
                                <h1>
                                    <strong>Bienvenue</strong>
                                    <br />
                                    {`${user.givenName} ${user.familyName}`}
                                </h1>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Image
                                rootClassName="logo"
                                src={logoJumeaux}
                                alt="Logo Jumeaux numériques"
                                preview={false}
                            />
                            <video autoPlay muted loop>
                                <source src="/assets/videos/Animation les jumeaux numériques.mp4" type="video/mp4" />
                            </video>
                            <h1>
                                <strong className="text-primary">GIROS 360 :</strong> Des acteurs de l&lsquo;eau engagés
                                collectivement autour des jumeaux numériques pour accélérer la résilience territoriale
                                de l&lsquo;estuaire de la Gironde.
                            </h1>
                        </>
                    )}
                </header>
                <main>
                    {user ? (
                        <nav className="modules">
                            <h2>Vos services</h2>
                            <div className="card-grid">
                                {user.modules.map((module: IModule) => (
                                    <ModuleCard key={module.title} module={module} userLoggedIn />
                                ))}
                            </div>
                        </nav>
                    ) : (
                        <>
                            <nav className="modules bg-light-gray">
                                <h2>Les services innovants développés</h2>
                                <div className="card-grid">
                                    <div className="text-gradient">
                                        Surveiller, être alerté, comprendre, simuler, prédire, partager
                                    </div>
                                    {modules.map((module: IModule) => (
                                        <ModuleCard key={module.title} module={module} />
                                    ))}
                                </div>
                            </nav>
                        </>
                    )}
                </main>
                <footer>
                    <div className="background">
                        <div className="wave" />
                        <div className="animation" />
                    </div>
                    <div className="nav-wrapper">
                        <nav className="left-links">
                            <ul>
                                <li>
                                    <ExternalLink href="">Contact</ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href="">Mentions Légales</ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href="">Crédits</ExternalLink>
                                </li>
                            </ul>
                            <ExternalLink href="https://www.gnu.org/licenses/gpl-3.0.en.html">
                                v{process.env.REACT_APP_VERSION}
                            </ExternalLink>
                        </nav>
                        <nav className="right-links">
                            <ul>
                                <li>
                                    <ExternalLink href="">Giros 360</ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href="">
                                        <Image
                                            src={logoPortWhite}
                                            alt="Logo port Bordeaux"
                                            preview={false}
                                            height={51}
                                        />
                                    </ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href="">
                                        <Image src={NaosLogo} alt="Logo NAOS" preview={false} height={94} />
                                    </ExternalLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </footer>
            </div>
        </section>
    );
};

export default Home;
