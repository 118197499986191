import React, { useCallback, useEffect, useState } from "react";
import { MenuModal } from "../../../Shared/components/MenuModal/MenuModal";
import { AlertLogTable } from "../../AlertLogTable/AlertLogTable";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { WaterwayType } from "../../../Shared/useWaterways";
import "./AlertLogButton.less";
import { fetchSLF } from "../../../Shared/utils/fetch";
import { IAlertDTO, IAlertLog, IDredgingAlert, QuotationSource } from "../../types/alert.types";
import TopBarButton from "../../../Shared/components/ModuleTopBar/TopBarButton/TopBarButton";

export const AlertLogButton = () => {
    const [showAlertLog, setShowAlertLog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fairwayAlertLog, setFairwayAlertLog] = useState<IAlertLog>({ d1: [], d30: [], d100: [], month: [] });
    const [defileAlertLog, setDefileAlertLog] = useState<IAlertLog>({ d1: [], d30: [], d100: [], month: [] });
    const fetchAlerts = useCallback(async () => {
        setLoading(true);
        try {
            const alerts: IAlertDTO[] = await fetchSLF("dragage/alerts");
            const [fairwayAlertLog, defileAlertLog]: [IAlertLog, IAlertLog] = alerts.reduce(
                (
                    [fairwayAlertLog, defileAlertLog],
                    { id, layer, feature_name, date, value_quotation_source, field_quotation_source, status },
                ) => {
                    const alertLog = layer === WaterwayType.FAIRWAY ? fairwayAlertLog : defileAlertLog;
                    const alert = {
                        key: id,
                        name: feature_name,
                        quotation: value_quotation_source,
                        timestamp: date,
                        status,
                    };
                    switch (field_quotation_source) {
                        case QuotationSource.D100:
                            alertLog.d100.push(alert);
                            break;
                        case QuotationSource.D30:
                            alertLog.d30.push(alert);
                            break;
                        case QuotationSource.D1:
                            alertLog.d1.push(alert);
                            break;
                        case QuotationSource.MODEL:
                        case QuotationSource.CORRECTED:
                            alertLog.month.push(alert);
                            break;
                    }
                    return [fairwayAlertLog, defileAlertLog];
                },
                [
                    { d1: [], d30: [], d100: [], month: [] },
                    { d1: [], d30: [], d100: [], month: [] },
                ] as [IAlertLog, IAlertLog],
            );
            // sort alert log by timestamp then by name
            [...Object.values(fairwayAlertLog), ...Object.values(defileAlertLog)].forEach((log) =>
                log.sort((a: IDredgingAlert, b: IDredgingAlert) => {
                    if (a.timestamp === b.timestamp) {
                        return a.name.localeCompare(b.name);
                    } else {
                        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                    }
                }),
            );
            setFairwayAlertLog(fairwayAlertLog);
            setDefileAlertLog(defileAlertLog);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);
    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    return (
        <div className="alert-log-wrapper">
            <TopBarButton
                tooltip="Journal des alertes"
                className="alert-log-button"
                active={showAlertLog}
                onClick={() => setShowAlertLog((show) => !show)}
                disabled={loading}
            >
                {loading ? <LoadingOutlined /> : <WarningOutlined />}
            </TopBarButton>
            {showAlertLog && (
                <MenuModal
                    onClose={() => setShowAlertLog(false)}
                    title="Journal des alertes"
                    icon={<WarningOutlined />}
                >
                    <Tabs
                        items={[
                            {
                                label: "Passes",
                                children: (
                                    <AlertLogTable
                                        alertLog={fairwayAlertLog}
                                        type={WaterwayType.FAIRWAY}
                                        onReset={fetchAlerts}
                                    />
                                ),
                                key: WaterwayType.FAIRWAY,
                            },
                            {
                                label: "Souilles",
                                children: (
                                    <AlertLogTable
                                        alertLog={defileAlertLog}
                                        type={WaterwayType.DEFILE}
                                        onReset={fetchAlerts}
                                    />
                                ),
                                key: WaterwayType.DEFILE,
                            },
                        ]}
                        defaultActiveKey={WaterwayType.FAIRWAY}
                    />
                </MenuModal>
            )}
        </div>
    );
};
