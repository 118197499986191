import React from "react";
import { Button } from "antd";
import { GitlabOutlined } from "@ant-design/icons";
import { IStudy } from "../LayersMenuDataPanel";
import useFILTER from "../../../useFILTER";
import "./DataSelectedStudyName.less";
import useJupyterUrl from "../useJupyterUrl";

export default function DataSelectedStudyName({
    selectedStudy,
    onCancel,
}: {
    selectedStudy: IStudy;
    onCancel: () => void;
}) {
    const { removeFilterKeys } = useFILTER();
    const jupyterUrl = useJupyterUrl(selectedStudy.id);
    const handleCancel = () => {
        removeFilterKeys(["wms"]);
        onCancel();
    };
    return (
        <div className="data-selected-study-name">
            <h4>Visualisation de l&lsquo;étude : {selectedStudy.name}</h4>
            <div>Crée à partir de : {selectedStudy.fromModelName}</div>
            <div className="links-wrapper">
                <Button type="default" href={selectedStudy.web_url} target="_blank">
                    <GitlabOutlined /> Ouvrir Gitlab
                </Button>
                <Button disabled={!jupyterUrl} type="default" href={jupyterUrl} target="_blank">
                    Ouvrir dans Jupyter Lab
                </Button>
            </div>
            <Button type="default" block onClick={handleCancel}>
                Annuler
            </Button>
        </div>
    );
}
