import React from "react";
import { Tabs } from "antd";
import { WaterwayQuotationsData, WaterwayType } from "../../../Shared/useWaterways";
import { AlertSettingsForm } from "../../AlertSettingsModale/AlertSettingsForm/AlertSettingsForm";

interface IAlertSettingModale {
    fairways: WaterwayQuotationsData[];
    defiles: WaterwayQuotationsData[];
    updateWaterways: (type: WaterwayType, newFairways: WaterwayQuotationsData[]) => Promise<void>;
}

enum AlertSettingsTabs {
    FAIRWAYS = "fairways",
    DEFILES = "defiles",
}

export const AlertSettingModale = ({ fairways, defiles, updateWaterways }: IAlertSettingModale) => {
    return (
        <Tabs
            items={[
                {
                    label: "Passes",
                    children: (
                        <AlertSettingsForm data={fairways} onUpdate={updateWaterways} type={WaterwayType.FAIRWAY} />
                    ),
                    key: AlertSettingsTabs.FAIRWAYS,
                },
                {
                    label: "Souilles",
                    children: (
                        <AlertSettingsForm data={defiles} onUpdate={updateWaterways} type={WaterwayType.DEFILE} />
                    ),
                    key: AlertSettingsTabs.DEFILES,
                },
            ]}
            defaultActiveKey={AlertSettingsTabs.FAIRWAYS}
        />
    );
};
