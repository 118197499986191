import React from "react";
import { AutoComplete, Button, DatePicker, Form, Input, Select } from "antd";
import { difficultyMap, EDifficultyOptions, INavigabilityFeedbacks } from "../../useNavigabilityFeedbacks";
import moment from "moment";

const { Option } = Select;

interface INavigabilityFeedbacksCell {
    children: React.ReactNode;
    dataIndex: keyof INavigabilityFeedbacks;
    record: INavigabilityFeedbacks;
    handleEdit?: (dataIndex: keyof INavigabilityFeedbacks, newValue?: string) => void;
    fairwaysOptions?: { value: string }[];
    setFairwayNameFilter?: (value: string) => void;
    fairwayNameFilter?: string;
    terminalsOptions?: { value: string }[];
    setTerminalNameFilter?: (value: string) => void;
    terminalNameFilter?: string;
}

export const NavigabilityFeedbacksCell: React.FC<INavigabilityFeedbacksCell> = ({
    children,
    dataIndex,
    record,
    handleEdit,
    fairwaysOptions,
    terminalsOptions,
    setTerminalNameFilter,
    setFairwayNameFilter,
    fairwayNameFilter,
    terminalNameFilter,
    ...restProps
}) => {
    const intensityOptions = record.difficulty && difficultyMap[record.difficulty]?.intensityOptions;
    let childNode = children;

    switch (dataIndex) {
        case "timestamp":
            if (handleEdit) {
                childNode = (
                    <Form.Item
                        style={{ margin: 0 }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `La date est requise.`,
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Date*"
                            format="DD/MM/YYYY HH:mm"
                            showTime
                            value={moment(record[dataIndex])}
                            onChange={(event) =>
                                handleEdit(dataIndex, event?.toISOString() ?? new Date().toISOString())
                            }
                        />
                    </Form.Item>
                );
            } else {
                const date = new Date(record[dataIndex]);
                const [hours, minutes] = date.toISOString().split("T")[1].split(":");
                childNode = <span className="date">{`${date.toLocaleDateString("fr")} ${hours}:${minutes}`}</span>;
            }
            break;
        case "ship_name":
            if (handleEdit) {
                childNode = (
                    <Form.Item
                        style={{ margin: 0 }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `Le nom du navire est requis`,
                            },
                        ]}
                    >
                        <Input
                            placeholder="Nom*"
                            allowClear
                            value={record[dataIndex]}
                            onChange={(event) => handleEdit(dataIndex, event.target.value)}
                        />
                    </Form.Item>
                );
            } else {
                childNode = record[dataIndex];
            }
            break;
        case "fairway_name":
            if (handleEdit && fairwaysOptions && setFairwayNameFilter) {
                childNode = (
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <AutoComplete
                            placeholder="Nom"
                            allowClear
                            options={fairwaysOptions}
                            value={record[dataIndex] || fairwayNameFilter}
                            onSelect={(event: string) => handleEdit(dataIndex, event)}
                            onSearch={(event) => setFairwayNameFilter(event)}
                            onClear={() => {
                                handleEdit(dataIndex, undefined);
                                setFairwayNameFilter("");
                            }}
                        />
                    </Form.Item>
                );
            } else {
                childNode = record[dataIndex];
            }
            break;
        case "terminal_name":
            if (handleEdit && terminalsOptions && setTerminalNameFilter) {
                childNode = (
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <AutoComplete
                            placeholder="Nom"
                            allowClear
                            options={terminalsOptions}
                            value={record[dataIndex] || terminalNameFilter}
                            onSelect={(event: string) => handleEdit(dataIndex, event)}
                            onSearch={(event) => setTerminalNameFilter(event)}
                            onClear={() => {
                                handleEdit(dataIndex, undefined);
                                setTerminalNameFilter("");
                            }}
                        />
                    </Form.Item>
                );
            } else {
                childNode = record[dataIndex];
            }
            break;
        case "difficulty":
            if (handleEdit) {
                childNode = (
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <Select
                            placeholder="Sélectionner"
                            allowClear
                            value={record[dataIndex]}
                            onSelect={(event: string) => handleEdit(dataIndex, event)}
                            onClear={() => {
                                handleEdit(dataIndex, undefined);
                            }}
                        >
                            {Object.values(EDifficultyOptions).map((difficulty) => (
                                <Option key={difficulty} value={difficulty}>
                                    {difficulty}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                );
            } else {
                const difficulty = record[dataIndex];
                const color = difficulty && difficultyMap[difficulty]?.color;
                childNode = color ? (
                    <span className="custom-badge" style={{ borderColor: color, backgroundColor: `${color}33`, color }}>
                        {record[dataIndex]}
                    </span>
                ) : null;
            }
            break;
        case "intensity":
            if (handleEdit) {
                childNode = (
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <Select
                            disabled={!intensityOptions}
                            placeholder="Sélectionner"
                            allowClear
                            value={record[dataIndex]}
                            onSelect={(event: string) => handleEdit(dataIndex, event)}
                            onClear={() => {
                                handleEdit(dataIndex, undefined);
                            }}
                        >
                            {intensityOptions &&
                                intensityOptions.map((intensity) => (
                                    <Option key={intensity.value} value={intensity.value}>
                                        {intensity.label || intensity.value}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                );
            } else {
                const color =
                    intensityOptions && intensityOptions.find(({ value }) => record[dataIndex] === value)?.color;
                childNode = color ? (
                    <span className="custom-badge" style={{ borderColor: color, backgroundColor: `${color}33`, color }}>
                        {record[dataIndex]}
                    </span>
                ) : null;
            }
            break;
        case "comment":
            if (handleEdit) {
                const isValid = record.timestamp && record.fairway_name && record.ship_name;
                childNode = (
                    <div className="comment-wrapper">
                        <Form.Item style={{ margin: 0 }} name={dataIndex} className="comment-wrapper-inner">
                            <Input
                                placeholder="Votre commentaire"
                                allowClear
                                value={record[dataIndex]}
                                onChange={(event) => handleEdit(dataIndex, event.target.value)}
                            />
                        </Form.Item>
                        <Button disabled={!isValid} className="submit-button" type="link" htmlType="submit">
                            Ajouter
                        </Button>
                    </div>
                );
            } else {
                childNode = record[dataIndex];
            }
            break;
    }

    return <td {...restProps}>{childNode}</td>;
};
