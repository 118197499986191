export enum EProgrammedState {
    PROGRAMMED = "programmed",
    UNAVAILABLE = "unavailabled",
    FORBIDDEN = "forbidden",
    TO_AVOID = "to_avoid",
    COMMERCIAL_COMMITMENTS = "commercial_commitments",
}

export enum EDraftStatus {
    SAVED = "saved",
    SAVING = "saving",
    ERROR = "error",
}
