import React from "react";
import { useLegendFromMap } from "./useLegendFromMap";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import "./Legends.less";
import { ILegendGradient, LegendGradient } from "./components/LegendGradient";
import { ILegendIcon, LegendIcons } from "./components/LegendIcons";
import LegendNode, { ILegendNode } from "./components/LegendNode";

interface ILegends {
    isOpen: boolean;
    legendGroups?: Record<string, ILegendData[]>;
}

export interface ILegendData {
    type: ELegendType;
}
export enum ELegendType {
    ICON = "icon",
    GRADIENT = "gradient",
    NODE = "node",
}

export const Legends = ({ isOpen, legendGroups = {} }: ILegends) => {
    const { isLoading, legendGroups: mapLegendGroups } = useLegendFromMap({
        isOpen,
    });
    legendGroups = { ...legendGroups, ...(mapLegendGroups ?? {}) };

    return (
        <div className="legend-wrapper">
            {isLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
            ) : legendGroups ? (
                Object.keys(legendGroups)
                    .sort()
                    .map((groupTitle) => {
                        const legendData = legendGroups[groupTitle];
                        const legendNodes = legendData.filter(
                            ({ type }: ILegendData) => type === ELegendType.NODE,
                        ) as ILegendNode[];
                        const legendGradients = legendData.filter(
                            ({ type }: ILegendData) => type === ELegendType.GRADIENT,
                        ) as ILegendGradient[];
                        const legendIcons = legendData.filter(
                            ({ type }: ILegendData) => type === ELegendType.ICON,
                        ) as ILegendIcon[];
                        return (
                            <div className="legend-group" key={groupTitle}>
                                <div className="legend-group-header">
                                    <h1>{groupTitle}</h1>
                                    <span className="line" />
                                </div>
                                {legendNodes.length > 0 && (
                                    <div className="legend-nodes-wrapper">
                                        {legendNodes.map((lData: ILegendNode, index) => (
                                            <LegendNode key={index} {...lData} />
                                        ))}
                                    </div>
                                )}
                                {legendGradients.map((lData: ILegendGradient) => (
                                    <LegendGradient key={lData.title} {...lData} />
                                ))}
                                <div className="legend-icons-wrapper">
                                    {legendIcons.map((lData: ILegendIcon) => (
                                        <LegendIcons key={lData.title} {...lData} />
                                    ))}
                                </div>
                            </div>
                        );
                    })
            ) : null}
        </div>
    );
};
