import React, { ReactNode, useState } from "react";
import { IMapModal } from "../components/MapModal/MapModal";

interface ILayoutContextProps {
    children: ReactNode;
}

interface ILayoutContext {
    onMapModal: IMapModal | null;
    setOnMapModal: null | ((newValue: IMapModal | null) => void);
}

export const LayoutContext = React.createContext<ILayoutContext>({
    onMapModal: null,
    setOnMapModal: null,
});

export const LayoutContextProvider = ({ children }: ILayoutContextProps) => {
    const [onMapModal, setOnMapModal] = useState<IMapModal | null>(null);

    return (
        <LayoutContext.Provider
            value={{
                onMapModal,
                setOnMapModal,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};
